<template>
  <!--begin::Modal - View Users-->
  <div
    class="modal fade"
    id="_app_api-key-manager_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 pt-0 pb-15">
          <!--begin::Heading-->
          <div class="text-center mb-9">
            <!--begin::Title-->
            <h1 class="mb-3">{{ translate("apiKeyManager") }}</h1>
            <!--end::Title-->

            <!--begin::Description-->
            <div class="text-gray-400 fw-bold fs-5">
              {{ translate("manageApiKey") }}
            </div>
            <!--end::Description-->
          </div>
          <!--end::Heading-->

          <!-- begin:: ApiKey removing confirmation -->
          <div
            v-if="isRemovingConfirmationOpened"
            class="apiKey-removing-confirmation"
          >
            <!-- begin:: ApiKey removing text -->
            <div class="p-3 text-center apiKey-removing-confirmation-text">
              <h2 v-if="apiKey" v-text="apiKey.name" class="mb-9 text-danger" />
              <h4 v-text="translate('apiKeyRemovingConfirmation')" />
            </div>
            <!-- end:: ApiKey removing text -->

            <!-- begin:: ApiKey removing actions -->
            <div class="apiKey-removing-confirmation-actions mt-9 text-center">
              <button
                ref="cancelButtonRef"
                type="reset"
                class="btn btn-light me-3"
                @click.prevent="stopApiKeyRemoving()"
              >
                <span class="indicator-label">
                  {{ translate("cancel") }}
                </span>
              </button>

              <button
                ref="submitButtonRef"
                type="button"
                id="kt_modal_new_card_submit"
                class="btn btn-danger"
                @click="confirmApiKeyRemoving"
              >
                <span class="indicator-label">
                  {{ translate("confirm") }}
                </span>
                <span class="indicator-progress">
                  {{ translate("pleaseWait") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!-- end:: ApiKey removing actions -->
          </div>
          <!-- end:: ApiKey removing confirmation -->

          <!-- begin:: ApiKey table -->
          <div v-else-if="!isFormOpened">
            <!-- begin:: ApiKey -->
            <div class="mb-15">
              <!--begin::List-->
              <div class="mh-375px scroll-y me-n7 pe-7">
                <div class="table-responsive">
                  <table
                    class="table table-rounded table-striped border gy-7 gs-7 text-center"
                  >
                    <thead>
                      <tr>
                        <th>{{ translate("description") }}</th>
                        <th>{{ translate("action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(apiKey, index) in apiKeys" :key="index">
                        <td>
                          <b>
                            <a
                              href="#"
                              data-bs-dismiss="modal"
                              class="
                                d-flex
                                align-items-center
                                fs-5
                                fw-bolder
                                text-dark text-hover-primary
                              "
                            >
                              {{ apiKey.description }}
                            </a>
                          </b>
                        </td>
                        <td class="btn-action">
                          <!--begin:: Copy button-->
                          <button
                            @click="copyToClipboard(apiKey)"
                            class="btn btn-sm btn-dark btn-icon"
                          >
                            <i class="bi bi-clipboard-data" />
                          </button>
                          <!--end:: Copy button-->

                          <!--begin:: Remove button-->
                          <button
                            @click="startApiKeyRemoving(apiKey)"
                            class="btn btn-sm btn-danger btn-icon ms-2"
                          >
                            <i class="bi bi-trash" />
                          </button>
                          <!--end:: Remove button-->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--end::List-->
            </div>
            <!-- end:: ApiKey -->

            <!--begin::Adder Notice-->
            <div class="text-center pb-5">
              <!--begin::Button-->
              <button
                @click="startApiKeyForm"
                class="btn btn-round btn-success ps-4"
              >
                <i class="bi bi-plus me-1" />
                {{ translate("createApiKey") }}
              </button>
              <!--end::Button-->
            </div>

            <div class="container">
              <div class="row justify-content-center mt-5">
                <div class="col-6 text-center">
                  <h5 class="mb-4">Descarga el archivo</h5>
                  <p class="mb-4">
                    Haz clic en el botón para descargar el PDF que explica como
                    utilizar el API Key para obtener la lista de OKRs:
                  </p>
                  <button @click="downloadFile" class="btn btn-primary btn-lg">
                    <i class="bi bi-download me-2"></i>
                    Descargar
                  </button>
                </div>
              </div>
            </div>
            <!--end::Adder Notice-->
          </div>
          <!-- begin:: ApiKey table -->

          <div v-else>
            <ApiKeyForm
              :apiKey="apiKey"
              @onSave="saveApiKey"
              @onCancel="stopApiKeyForm()"
            />
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - View Users-->
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";

import ApiKeyForm from "@/components/app/Organizations/ApiKeyForm.vue";

export default defineComponent({
  name: "_app_api-key-manager_modal",
  components: { ApiKeyForm },
  props: {
    organization: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      isWaiting: false,
      isFormOpened: false,
      isRemovingConfirmationOpened: false,
      apiKey: null,
      apiKeys: []
    };
  },
  computed: {
    getPeriod() {
      const periods = this.$store.getters.getAllApiKeys;
      if (periods) {
        return periods;
      } else {
        return [];
      }
    },

    authenticatedUser() {
      return this.$store.getters.currentUser;
    }
  },

  watch: {
    "$store.state.ObjectiveModule.isModalOpen": function() {
      this.isFormOpened = false;
    }
  },
  methods: {
    async downloadFile() {
      const fileUrl = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/descargar`; // Reemplaza este URL por el URL de tu archivo
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "apikey_instrucciones.pdf"); // Nombre del archivo que se descargará
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    loadApiKeys() {
      const organizationId = this.$route.params.id
        ? this.$route.params.id
        : null; // @todo

      if (!organizationId) {
        alert("not organization ID here");
      }

      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/api-keys/` +
        organizationId;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          this.apiKeys = data.result;
        })
        .catch(error => {
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError"),
            type: "danger"
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    },

    startApiKeyForm(apiKey) {
      this.isFormOpened = true;
      const apiData =
        !apiKey || !apiKey.id
          ? {
              id: null,
              description: "",
              api_key: "",
              organization: null
            }
          : apiKey;

      setTimeout(() => {
        this.apiKey = apiData; // Visual effect
      }, 150);
    },

    stopApiKeyForm() {
      this.apiKey = null;
      this.isFormOpened = false;
    },

    startApiKeyRemoving(apiKey) {
      this.isRemovingConfirmationOpened = true;

      setTimeout(() => {
        this.apiKey = apiKey; // Visual effect
      }, 150);
    },

    copyToClipboard(apiKey) {
      navigator.clipboard
        .writeText(apiKey.api_key)
        .then(() => {
          this.$notify({
            title: this.translate("good"),
            message: this.translate("API key copied to clipboard"),
            type: "success"
          });
        })
        .catch(err => {
          console.error("Failed to copy value: ", err);
        });
    },

    stopApiKeyRemoving() {
      this.apiKey = null;
      this.isRemovingConfirmationOpened = false;
    },

    confirmApiKeyRemoving() {
      if (!this.apiKey || !this.apiKey.id) {
        console.info("[You need apiKey id to continue]");
        return;
      }
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/api-keys/` +
        this.apiKey.id;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(() => {
          this.stopApiKeyRemoving();
          this.loadApiKeys();
          this.$notify({
            title: this.translate("good"),
            message: this.translate("apiKeyDelete"),
            type: "success"
          });
        })
        .catch(error => {
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError"),
            type: "danger"
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    },

    saveApiKey(apiKey) {
      const action = apiKey.id ? "PUT" : "POST";
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/api-keys`;
      const currentToken = JwtService.getToken();
      const organizationId = this.$route.params.id
        ? this.$route.params.id
        : null;

      if (!organizationId) {
        console.error("[You need organization id]");
        return;
      }

      apiKey.organization = organizationId;

      this.isWaiting = true;
      return fetch(url, {
        method: action,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(apiKey)
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(() => {
          this.stopApiKeyForm();
          this.loadApiKeys();
          this.$notify({
            title: this.translate("excelent"),
            message: this.translate("apiKeySafe"),
            type: "success"
          });
        })
        .catch(error => {
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError")
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    }
  },

  mounted() {
    this.loadApiKeys();
  },

  setup() {
    const { t, te } = useI18n();

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  }
});
</script>

<style>
.btn-action {
  display: flex;
  padding: 9px !important;
  margin: 0;
  justify-content: center;
  align-items: center;
}

h5 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

p {
  font-size: 1.2rem;
  color: #666;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0062cc;
  border-color: #0062cc;
}
</style>
