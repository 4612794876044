<template>
  <!--begin::Modal - New Card-->
  <div
    class="modal fade"
    id="_app_objective_invitation_form_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2>{{ translate("acceptSharedObjective") }}</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-15 my-5">
          <div v-if="objective" class="text-center">
            <h3>{{ translate("youAreAboutOfAcceptThisObjective") }}</h3>
            <h1 v-text="objective.title" class="mb-12" />
          </div>

          <!--begin::Form-->
          <Form
            id="objective_invitation_form"
            class="form"
            :validation-schema="accept"
            @submit="submit"
          >
            <div class="row mb-10">
              <div class="text-center">
                <h3>{{ translate("aboutOfAcceptThisObjective") }}</h3>
              </div>
              <div class="col-md-12 fv-row">
                <div class="row fv-row">
                  <!--  <div class="col-7">
                    <label class="required fs-6 fw-bold form-label mb-2">{{
                      translate("parentObjective")
                    }}</label>
                    <Field
                      v-model="form.parent"
                      :disabled="isWaiting"
                      :data-placeholder="'Parent objective'"
                      name="parent"
                      class="form-select form-select-solid"
                      data-control="select2"
                      data-hide-search="true"
                      as="select"
                    >
                      <option :value="null">
                        {{ translate("withoutParent") }}
                      </option>
                      <template
                        v-for="objective in objectives"
                        :key="objective.id"
                      >
                        <option :value="objective.id">
                          {{ objective.title }}
                        </option>
                      </template>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="period" />
                      </div>
                    </div>
                  </div> -->
                  <!-- end:: Parent selector -->

                  <!-- begin:: Period -->
                  <div class="col-12">
                    <label class="required fs-6 fw-bold form-label mb-2">{{
                      translate("cycle")
                    }}</label>
                    <Field
                      v-model="form.period"
                      :disabled="isWaiting"
                      name="period"
                      class="form-select form-select-solid"
                      data-control="select2"
                      data-hide-search="true"
                      data-placeholder="Period"
                      as="select"
                    >
                      <!-- Load existing periods -->
                      <option></option>
                      <template v-for="period in periods" :key="period.id">
                        <option :value="period.id">{{ period.name }}</option>
                      </template>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="period" />
                      </div>
                    </div>
                  </div>
                  <!-- end:: Period -->
                </div>
              </div>
            </div>

            <!--begin::Actions-->
            <div class="text-center mt-15">
              <button
                :disabled="isWaiting"
                ref="submitButtonRef"
                type="submit"
                id="objective_submit"
                class="btn btn-success"
              >
                <span class="indicator-label">
                  {{
                    isWaiting ? translate("pleaseWait") : translate("accept")
                  }}
                </span>
                <span class="indicator-progress">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Card-->
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as Yup from "yup";

const EMPTY_OBJECTIVE_INVITATION = {
  okr: null,
  parent: null,
  period: null,
  organization: null,
  answer: "accepted", // ('accepted' | 'rejected')
};

export default defineComponent({
  name: "_app_objective_invitation_form_modal",
  props: {
    objectiveInvitation: {
      type: Object,
      default: () => null,
    },
    waitingMessage: {
      type: String,
      default: () => "",
    },
  },
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      form: EMPTY_OBJECTIVE_INVITATION,
    };
  },
  computed: {
    frequencies() {
      return [
        { id: "single", name: this.translate("De una vez") },
        { id: "recurrent", name: this.translate("Recurrente") },
      ];
    },
    isWaiting() {
      return this.waitingMessage != "";
    },
  },
  setup() {
    const store = useStore();
    const submitButtonRef = (ref < null) | (HTMLButtonElement > null);
    const { t, te } = useI18n();
    const objectives = computed(() => store.getters.getObjectives);

    const translate = (text) => {
      return te(text) ? t(text) : text;
    };

    //Create form validation object
    const accept = Yup.object().shape({
      period: Yup.string().nullable(true)
        .required(translate("cycle") + " " + translate("isRequired"))
    });

    const periods = computed(() => store.getters.getAllCycles);

    return {
      submitButtonRef,
      translate,
      periods,
      objectives,
      accept
    };
  },
  methods: {
    setType(typeToSet) {
      this.form.type = typeToSet;
    },
    setKrType(krTypeToSet) {
      this.form.krType = krTypeToSet;
    },
    getTitleActionText() {
      return this.objectiveInvitation && this.objectiveInvitation.id
        ? "Editando"
        : "Creando";
    },
    submit() {
      this.form.organization =
        this.objectiveInvitation && this.objectiveInvitation.organizationId
          ? this.objectiveInvitation.organizationId
          : null;

      if (!this.form.organization || !this.form.period) {
        const errorMessage =
          !this.form.organization && !this.form.period
            ? this.translate("missingInformation")
            : !this.form.organization
            ? this.translate("organizationIsMissing")
            : this.translate("periodIsMissing");

        return this.$notify({
          title: this.translate("validationError"),
          message: errorMessage,
          type: "danger",
        });
      }

      this.form.organization = this.objectiveInvitation.organizationId;
      this.form.okr = this.objectiveInvitation.okrId;

      this.$emit("onSend", this.form);
    },
  },
});
</script>

<style>
.parent-box {
  border: solid 1px #f9f9f9;
  background-color: #f9f9f9;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  font-size: 15px;
}
</style>
