<template>
  <!--begin::Modal - New Card-->
  <div
    v-if="form.period"
    class="modal fade"
    id="_app_objective_modal_omi"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2>
            {{ translate("creatingOmi") }}
          </h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span id="ObjectiveFormModalOmi_modal" class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-15 my-5">
          <!--begin::Form-->

          <Form
            id="objective_form_omi"
            class="form"
            @submit="submit"
            :validation-schema="formValidate"
          >
            <!-- begin:: Objective parent -->

            <!--start::elemento-->
            <div class="row mb-10">
              <div class="col-md-12 fv-row">
                <label
                  class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
                >
                  <span class="required">{{
                    translate("objectiveTitle")
                  }}</span>
                  <i
                    class="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Specify a objective's title"
                  ></i>
                </label>

                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder=""
                  name="title"
                  v-model="form.title"
                />

                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="title" />
                  </div>
                </div>
              </div>
            </div>
            <!--end::elemento-->

            <!--start::elemento-->
            <div class="row mb-10">
              <div class="col-md-12 fv-row">
                <label
                  class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
                >
                  <span>{{ translate("description") }}</span>
                  <i
                    class="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Specify a objective's title"
                  ></i>
                </label>

                <el-form-item prop="description">
                  <el-input
                    v-model="form.description"
                    type="textarea"
                    rows="3"
                    name="description"
                    placeholder="Enter description of the objective here"
                    id="descripcion"
                  />
                </el-form-item>
              </div>
            </div>
            <!--end::elemento-->

            <h3 style="margin-bottom: 25px" class="text-center">
              {{ translate("effectMetric") }}
            </h3>

            <div class="row mb-10">
              <!-- begin:: Unit -->
              <div class="col-md-12 fv-row">
                <label class="fs-6 fw-bold form-label mb-2">
                  {{ translate("unitOfMeasurement") }}
                </label>

                <div class="agrupa">
                  <Field
                    type="text"
                    v-model="form.unit"
                    name="expirationYear"
                    class="form-control form-control-solid"
                    placeholder=""
                  />

                  <div class="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {{ translate("suggestions") }}
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <!--<li><button class="dropdown-item"></button></li>-->
                      <template v-for="u in units" :key="u.id">
                        <li>
                          <button
                            class="dropdown-item"
                            @click.prevent="setSug(u.name)"
                          >
                            {{ translate(u.name) }}
                          </button>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>

                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="expirationYear" />
                  </div>
                </div>
              </div>
              <!-- end:: Unit -->
            </div>

            <div class="row mb-10">
              <div class="col-md-12 fv-row">
                <div class="row fv-row">
                  <!-- begin:: Start value -->
                  <div class="col-6">
                    <label class="fs-6 fw-bold form-label mb-2">
                      {{ translate("startValue") }}
                    </label>
                    <Field
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="startValue"
                      data-rules="numeric:1"
                      v-model="form.startValue"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="startValue" />
                      </div>
                    </div>
                  </div>
                  <!-- end:: Start value -->

                  <!-- begin:: Target value -->
                  <div
                    :class="{
                      'col-6': form.id == null,
                      'col-4': form.id != null,
                    }"
                  >
                    <label class="fs-6 fw-bold form-label mb-2">
                      {{ translate("objectiveValue") }}
                    </label>
                    <Field
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="targetValue"
                      data-rules="numeric:1"
                      v-model="form.targetValue"
                    />

                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="targetValue" />
                      </div>
                    </div>
                  </div>
                  <!-- end:: Target value -->
                </div>
              </div>
            </div>

            <!--begin::Input group -->
            <div class="row mb-10">
              <div class="col-md-12 fv-row">
                <div class="row fv-row">
                  <!-- begin:: User -->
                  <div class="col-5">
                    <label class="required fs-6 fw-bold form-label mb-2">{{
                      translate("assigned")
                    }}</label>
                    <Field
                      v-model="form.user"
                      name="user"
                      class="form-select form-select-solid"
                      data-control="select2"
                      data-hide-search="true"
                      data-placeholder="User"
                      as="select"
                    >
                      <!-- @todo: Load users -->
                      <option></option>
                      <template v-for="member in members" :key="member.id">
                        <option :value="member.user">
                          {{ member.name }} {{ member.lastname }}
                        </option>
                      </template>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="user" />
                      </div>
                    </div>
                  </div>
                  <!-- end:: User -->

                  <!-- begin:: Period -->
                  <div class="col-4">
                    <label class="required fs-6 fw-bold form-label mb-2">{{
                      translate("cycle")
                    }}</label>

                    <Field
                      v-model="form.period"
                      name="period"
                      class="form-select form-select-solid"
                      data-control="select2"
                      data-hide-search="true"
                      data-placeholder="Period"
                      as="select"
                    >
                      <option></option>
                      <template v-for="period in periods" :key="period.id">
                        <option :value="period.id">{{ period.name }}</option>
                      </template>
                    </Field>

                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="period" />
                      </div>
                    </div>
                  </div>
                  <!-- end:: Period -->

                  <!--BEGIN: CODE-->
                  <div class="col-3">
                    <label
                      class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
                    >
                      <span>{{ translate("code") }}</span>
                    </label>

                    <Field
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="code"
                      v-model="form.code"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="code" />
                      </div>
                    </div>
                  </div>
                  <!--END: CODE-->
                </div>
              </div>
            </div>
            <!--end::Input group-->

            <!--begin::Actions-->
            <div class="text-center mt-3">
              <button
                ref="submitButtonRef"
                type="submit"
                id="objective_submit"
                class="btn btn-primary"
              >
                <span class="indicator-label">
                  {{ translate("save") }}
                </span>
                <span class="indicator-progress">
                  {{ translate("pleaseWait") }}...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Card-->
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, computed, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import * as Yup from "yup";
import JwtService from "@/core/services/JwtService";
import { sendMautic } from "@/core/plugins/mautic";

const TYPE_OPTIONS = {
  _OBJECTIVE: "objective",
  _KEY_RESULT: "key_result",
};

const KR_TYPE_OPTIONS = {
  _BEHAVIOR: "behavior",
  _RESULT: "result",
};

const EMPTY_OBJECTIVE = {
  id: null,
  omi: false,
  frequency: "",
  organization: "",
  title: "",
  description: "",
  owner: null,
  user: null,
  period: null,
  type: "",
  krType: "",
  parent: "",
  code: "",
  startValue: "",
  targetValue: "",
  currentValue: "",
  unit: "",
  createdAt: "",
  editedAt: "",
  deletedAt: "",
  subObjectives: [],
  differentiatedStart: "",
  earlyEnding: "",
};

export default defineComponent({
  name: "_app_objective_modal",
  props: {
    members: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    parent(parentValue) {
      //
      //this.form = { ...EMPTY_OBJECTIVE };
      const submitForm = document.getElementById("objective_form_omi");
      document.getElementById("descripcion").value = "";
      let input = document.querySelector("input:checked");
      if (input && input.checked) input.checked = false;
      this.form.parent = this.parent && this.parent.id ? this.parent.id : null;
      if (submitForm) {
        submitForm.reset();
      }
    },
    "$store.state.ObjectiveModule.cycle": function () {
      this.form.period = this.$store.getters.getCycle.id;
    },
  },
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      showModal: false,
      sugerencia: "",
      form: { ...EMPTY_OBJECTIVE },
      krTypeOptions: { ...KR_TYPE_OPTIONS },
      typeOptions: { ...TYPE_OPTIONS },
      objectiveTypeList: [
        {
          id: 1,
          code: "objective",
          name: "objective",
        },
        {
          id: 2,
          code: "key_result",
          name: "keyResult",
        },
      ],
      objectiveKrTypeList: [
        {
          id: 1,
          code: "behavior",
          name: "behavior",
        },
        {
          id: 2,
          code: "result",
          name: "result",
        },
      ],
      units: [
        /*{
          id: 0,
          code: "",
          name: "",
        },*/
        {
          id: 1,
          code: "percentage",
          name: this.translate("percentage"),
        },
        {
          id: 2,
          code: "units",
          name: this.translate("units"),
        },
        {
          id: 3,
          code: "clp",
          name: "CLP",
        },
        {
          id: 4,
          code: "sold_units",
          name: this.translate("soldUnits"),
        },
      ],
      idOKR: 0,
    };
  },

  computed: {
    frequencies() {
      return [
        { id: "single", name: this.translate("De una vez") },
        { id: "recurrent", name: this.translate("Recurrente") },
      ];
    },
    getPeriod() {
      const Cycle = this.$store.getters.getCycle;
      if (Cycle && Cycle.name) {
        return Cycle.id;
      } else {
        return "sin periodo";
      }
    },
  },

  setup() {
    const store = useStore();
    const submitButtonRef = (ref < null) | (HTMLButtonElement > null);
    const { t, te } = useI18n();
    const translate = (text) => {
      return te(text) ? t(text) : text;
    };

    onMounted(() => {});

    const periods = computed(() => store.getters.getAllCycles);
    const selectedCycle = computed(() => {
      return store.getters.getCycle.id;
    });

    const formValidate = Yup.object().shape({
      title: Yup.string()
        .required(translate("objectiveTitle") + " " + translate("isRequired"))
        .max(254, translate("maxTitle")),
      objectiveType: Yup.string()
        .label("objectiveType")
        .nullable()
        .when("parent", (parent, schema) => {
          if (parent != null) {
            return schema.required();
          }
          return schema;
        }),
      objectiveKrType: Yup.string()
        .label("Objective KR Type")
        .nullable()
        .when("objectiveType", (objectiveType, schema) => {
          if (objectiveType === "key_result") {
            return schema.required();
          }
          return schema;
        }),
      user: Yup.string().required().label("User").nullable(),
      period: Yup.string().required().label("Period").nullable(),
      startValue: Yup.string()
        .nullable()
        .label("Start Value")
        .test("is-decimal", "invalid decimal", (value) =>
          (value + "").match(/^-?(\d)*(\.)?([0-9]{1,2})?$/)
        ),
      targetValue: Yup.string()
        .nullable()
        .label("Target Value")
        .test("is-decimal", "invalid decimal", (value) =>
          (value + "").match(/^-?(\d)*(\.)?([0-9]{1,2})?$/)
        ),
      frequency: Yup.string()
        .label("Frecuency Value")
        .when("objectiveType", (objectiveType, schema) => {
          if (objectiveType === "key_result") {
            return schema.required();
          }
          return schema;
        }),
      expirationYear: Yup.string()
        .label("Unit")
        .nullable()
        .when("objectiveType", (objectiveType, schema) => {
          if (objectiveType === "key_result") {
            return schema.required();
          }
          return schema;
        }),
    });

    return {
      submitButtonRef,
      translate,
      selectedCycle,
      periods,
      formValidate,
    };
  },
  methods: {
    setSug(sug) {
      this.form.unit = sug;
    },
    setType(typeToSet) {
      this.form.type = typeToSet;
    },
    setKrType(krTypeToSet) {
      this.form.krType = krTypeToSet;
    },
    getTitleActionText() {
      return this.objective ? "Editando" : "Creando";
    },
    submit() {
      // Desactivar el botón de submit
      if (this.submitButtonRef) {
        this.submitButtonRef.setAttribute("disabled", "disabled");
      }

      const organizationId = this.$route.params.id;
      this.form.organization = organizationId;
      this.form.omi = this.parent ? false : true;

      this.form.startValue = this.form.startValue || "0.0";

      this.$emit("on-save", this.form);

      setTimeout(() => {
        const submitForm = document.getElementById("objective_form_omi");
        if (submitForm) {
          submitForm.reset();
        }


        this.form.description = ''
        if (this.submitButtonRef) {
          this.submitButtonRef.removeAttribute("disabled");
        }

        // Cerrar la modal
        document.getElementById("ObjectiveFormModalOmi_modal").click();
      }, 1000);
    },
  },
  mounted() {
    // Obtener la referencia al botón de submit
    this.submitButtonRef = document.getElementById("objective_submit");
  },
});
</script>

<style>
.parent-box {
  border: solid 1px #f9f9f9;
  background-color: #f9f9f9;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  font-size: 15px;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.15rem;
  height: 1.15rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns=http://www.w3.org/2000/svg viewBox=0 0 16 16 fill=%23181C32%3e%3cpath fill-rule=evenodd d=M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.15rem;
  transition: transform 0.2s ease-in-out;
}

.agrupa {
  display: flex;
  justify-content: space-between;
}

.agrupa .form-control.form-control-solid {
  width: 70%;
}

.agrupa select {
  width: 30%;
}

.btn.btn-secondary.dropdown-toggle {
  background-color: #269abc !important;
  color: #fff;
}

h3.text-center.required {
  margin-bottom: 25px;
}

.oculto {
  display: none !important;
}

.group-custom {
  width: 100%;
}

.group-custom label {
  width: 50%;
  background: #f5f8fa;
  background-color: #f5f8fa;
}

.group-custom label:hover {
  color: #fff !important;
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #fff !important;
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  background-color: #464b6d !important;
}

.parent-box.p-3.text-muted {
  height: 42px;
  overflow: hidden;
  line-height: 24px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}

.el-input__inner {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  color: #5e6278;
  transition: color 0.2s ease, background-color 0.2s ease;
  font-size: 1.1rem;
  font-weight: 500;
  width: 100% !important;
}

#objective_submit:disabled,
#objective_submit[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666;
}

textarea {
  background-color: #f5f8fa !important;
  border-color: #d9d9d9 !important;
  color: #5e6278 !important;
  transition: color 0.2s ease, background-color 0.2s ease !important;
}
</style>
