<template>
  <!--begin::Form-->
  <Form id="kt_cycle_form" class="form" @submit="save">
    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col Name-->
          <div class="col-12">
            <label
              class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            >
              <span class="required">{{ translate("cycleName") }}</span>
              <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="name"
              ></i>
            </label>

            <Field
              v-model="form.name"
              autofocus
              type="text"
              class="form-control form-control-solid"
              placeholder=""
              name="name"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="name" />
              </div>
            </div>
          </div>

          <div class="col-12" style="margin-top: 20px">
            <label class="fs-6 fw-bold form-label mb-2">
              <span class="required">{{ translate("status") }}</span>
            </label>

            <Field
              v-model="form.status"
              name="status"
              class="form-select form-select-solid"
              data-control="select2"
              data-hide-search="true"
              data-placeholder="Status"
              as="select"
            >
              <option value="planning">{{ translate("planning") }}</option>
              <option value="running">{{ translate("running") }}</option>
              <option value="finished">{{ translate("finished") }}</option>
            </Field>
          </div>

          <!--end::Col Name-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col Start At-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2">{{
              translate("startAt")
            }}</label>
            <!--end::Label-->

            <el-date-picker
              v-model="form.startAt"
              type="date"
              :disabled-date="datePickerStartAtOptions"
              :placeholder="translate('startAt')"
            >
            </el-date-picker>

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="expirationYear" />
              </div>
            </div>
          </div>
          <!--end::Col Start At-->

          <!--begin::Col End At-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2">{{
              translate("endAt")
            }}</label>
            <!--end::Label-->

            <el-date-picker
              v-model="form.endAt"
              type="date"
              :disabled-date="datePickerEndAtOptions"
              :placeholder="translate('endAt')"
            >
            </el-date-picker>

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="expirationYear" />
              </div>
            </div>
          </div>
          <!--end::Col End At-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Actions-->
    <div class="text-center mt-3">
      <button
        ref="cancelButtonRef"
        type="reset"
        class="btn btn-light me-3"
        @click.prevent="cancel"
      >
        <span class="indicator-label">
          {{ translate("cancel") }}
        </span>
      </button>

      <button
        :disabled="canSave()"
        ref="submitButtonRef"
        type="submit"
        id="kt_modal_new_card_submit"
        :class="
          canSave() ? 'btn btn-light save-ciclo' : 'btn btn-primary  save-ciclo'
        "
      >
        <span class="indicator-label">
          {{ translate("save") }}
        </span>
        <span class="indicator-progress">
          {{ translate("pleaseWait") }}
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
    <!--end::Actions-->
  </Form>
  <!--end::Form-->
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";

const EMPTY_CYCLE = {
  id: null,
  name: "",
  status: "planning",
  organization: null,
  startAt: "",
  endAt: ""
};

export default defineComponent({
  name: "_app_cycle_form",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  },
  props: {
    cycle: {
      type: Object,
      default: () => EMPTY_CYCLE
    }
  },
  watch: {
    cycle(cycleValue) {
      this.form = {
        id: cycleValue.id,
        name: cycleValue.name,
        status: cycleValue.status,
        organization: cycleValue.organization,
        startAt: cycleValue.start_at,
        endAt: cycleValue.end_at
      };
    }
  },

  data() {
    return {
      form: EMPTY_CYCLE,
      cycleStartAt: "",
      cycleEndAt: "",
      datePickerStartAt: new Date()
    };
  },
  methods: {
    canSave() {
      return (
        this.form.startAt == undefined ||
        this.form.endAt == undefined ||
        this.form.name === "" ||
        this.form.status === ""
      );
    },
    datePickerStartAtOptions() {
      this.datePickerStartAt = this.form.startAt;
      if (new Date(this.form.startAt) > new Date(this.form.endAt))
        this.form.endAt = "";
      return false;
    },
    datePickerEndAtOptions(date) {
      return date < this.datePickerStartAt;
    },
    save() {
      //$("#kt_modal_new_card_submit").attr('disabled','disabled');
      document
        .querySelector(".save-ciclo")
        .setAttribute("disabled", "disabled");
      this.$emit("onSave", this.form);
      /* setTimeout(function() {
        $("#kt_modal_new_card_submit").removeAttr('disabled');
      }, 800); */
      document.querySelector(".save-ciclo").removeAttribute("disabled");
    },
    cancel() {
      this.$emit("onCancel");
    }
  }
});
</script>

<style>
#kt_modal_new_card_submit:disabled,
#kt_modal_new_card_submit[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666;
}
</style>
