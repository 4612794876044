<template>
  <!--begin::Modal - View Tasks-->
  <div
    class="modal fade"
    id="_app_objective-task-manager_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div
          class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15"
          v-if="objective"
        >
          <!--begin::Heading-->
          <div class="text-center mb-13">
            <!--begin::Title-->
            <h1
              class="mb-3"
              :class="{
                'text-danger': removingConfirmation !== null
              }"
            >
              {{
                isTaskFormVisible
                  ? translate("newTask")
                  : removingConfirmation
                  ? translate("removingConfirmationTitle")
                  : translate("taskManager")
              }}
            </h1>
            <!--end::Title-->

            <!--begin::Description-->
            <div class="text-gray-400 fw-bold fs-5">
              <span
                :class="{
                  'text-danger': removingConfirmation !== null
                }"
              >
                {{
                  isTaskFormVisible
                    ? translate("newTaskIntro") // "Envía a quien quieras a través de su correo electrónico"
                    : removingConfirmation
                    ? translate("removingConfirmationDescription")
                    : translate("pickOrCreateTasks")
                }}</span
              >
            </div>
            <!--end::Description-->
            <!--begin::Objective-->
            <div>
              <h4>{{ objective.title }}</h4>
            </div>
            <!--end::Objective-->
          </div>
          <!--end::Heading-->

          <!-- begin:: Removing confirmation -->
          <div v-if="removingConfirmation" class="text-center">
            <h2 v-text="removingConfirmation.taskTitle" class="mb-12" />
            <div class="mt-3 text-center">
              <button
                ref="cancelButtonRef"
                :disabled="isWaiting"
                type="reset"
                class="btn btn-light me-3"
                @click.prevent="stopTaskRemoving"
              >
                <span class="indicator-label">
                  {{ translate("cancel") }}
                </span>
              </button>

              <button
                ref="submitButtonRef"
                :disabled="isWaiting"
                type="submit"
                class="btn btn-primary"
                @click="removingConfirmation.onConfirm()"
              >
                <span class="indicator-label">
                  {{ isWaiting ? translate("removing") : translate("confirm") }}
                </span>
                <span class="indicator-progress">
                  {{ translate("pleaseWait") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
          </div>
          <!-- end:: Removing confirmation -->

          <!--begin::Task List-->
          <div
            v-if="!isTaskFormVisible && !removingConfirmation"
            class="mb-15 mt-10"
          >
            <!--begin::List-->
            <div v-if="tasks.length" class="mh-375px scroll-y me-n7 pe-7">
              <template v-for="(task, index) in tasks" :key="index">
                <!--begin::User-->
                <div
                  v-if="task.status === 0"
                  :class="
                    task.status === 1
                      ? 'd-flex flex-stack py-5 px-5 border-bottom border-gray-300 border-bottom-dashed todo-check'
                      : 'd-flex flex-stack py-5 px-5 border-bottom border-gray-300 border-bottom-dashed'
                  "
                >
                  <!--begin::Details-->
                  <div class="d-flex align-items-center">
                    <!--begin:: check button-->
                    <button
                      v-if="task.status === 0"
                      @click="checkTask(1, task.id)"
                      class="btn btn-sm btn-success btn-icon btn-gris tooltip"
                    >
                      <span class="tooltip-box">{{
                        translate("taskToBeDone")
                      }}</span>
                    </button>

                    <button
                      v-else
                      @click="checkTask(0, task.id)"
                      class="btn btn-sm btn-danger btn-icon"
                    >
                      <!--<i class="bi bi-x" />-->
                      <i class="bi bi-check" />
                    </button>
                    <!--end:: Edit button-->
                    <!--begin::Details-->
                    <div class="ms-6">
                      <!--begin::Name-->
                      <span
                        class="
                          d-flex
                          align-items-center
                          fs-5
                          fw-bolder
                          text-dark text-hover-primary span-task                     
                          "
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="task.title"
                      >
                        {{ task.title }}
                      </span>
                      <!--end::Name-->
                    </div>
                    <!--end::Details-->
                  </div>
                  <!--end::Details-->

                  <!--begin::Actions-->
                  <div class="d-flex">
                    <div class="text-end">
                      <div class="fs-5 fw-bolder text-dark">
                        <!--begin:: Edit button-->
                        <button
                          @click="startTaskForm(task)"
                          class="btn btn-sm btn-dark btn-icon ms-2"
                        >
                          <i class="bi bi-pencil" />
                        </button>
                        <!--end:: Edit button-->

                        <!--begin:: Remove button-->
                        <button
                          @click="startTaskRemoving(task)"
                          class="btn btn-sm btn-danger btn-icon ms-2"
                        >
                          <i class="bi bi-trash" />
                        </button>
                        <!--end:: Remove button-->
                      </div>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::User-->
              </template>
            </div>

            <div v-if="tasks.length" class="mh-375px scroll-y me-n7 pe-7">
              <template v-for="(task, index) in tasks" :key="index">
                <!--begin::User-->
                <div
                  v-if="task.status === 1"
                  :class="
                    task.status === 1
                      ? 'd-flex flex-stack py-5 px-5 border-bottom border-gray-300 border-bottom-dashed todo-check'
                      : 'd-flex flex-stack py-5 px-5 border-bottom border-gray-300 border-bottom-dashed'
                  "
                >
                  <!--begin::Details-->
                  <div class="d-flex align-items-center">
                    <!--begin:: check button-->

                    <button
                      v-if="task.status === 0"
                      @click="checkTask(1, task.id)"
                      class="btn btn-sm btn-success btn-icon"
                    >
                      <i class="bi bi-check" />
                    </button>

                    <button
                      v-else
                      @click="checkTask(0, task.id)"
                      class="btn btn-sm btn-success btn-listo btn-icon tooltip"
                    >
                      <!--<i class="bi bi-x" />-->
                      <i class="bi bi-check" />
                      <span class="tooltip-box">{{
                        translate("taskDone")
                      }}</span>
                    </button>
                    <!--end:: Edit button-->
                    <!--begin::Details-->
                    <div class="ms-6">
                      <!--begin::Name-->
                      <span
                        class="
                          d-flex
                          align-items-center
                          fs-5
                          fw-bolder
                          text-dark text-hover-primary span-task
                        "
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :title="task.title"
                      >
                        {{ task.title }}
                      </span>
                      <!--end::Name-->
                    </div>
                    <!--end::Details-->
                  </div>
                  <!--end::Details-->

                  <!--begin::Actions-->
                  <div class="d-flex">
                    <div class="text-end">
                      <div class="fs-5 fw-bolder text-dark">
                        <!--begin:: Edit button-->
                        <button
                          @click="startTaskForm(task)"
                          class="btn btn-sm btn-dark btn-icon ms-2"
                        >
                          <i class="bi bi-pencil" />
                        </button>
                        <!--end:: Edit button-->

                        <!--begin:: Remove button-->
                        <button
                          @click="startTaskRemoving(task)"
                          class="btn btn-sm btn-danger btn-icon ms-2"
                        >
                          <i class="bi bi-trash" />
                        </button>
                        <!--end:: Remove button-->
                      </div>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::User-->
              </template>
            </div>

            <div v-else-if="!loader" class="p-3 text-center">
              <span class="text-muted">{{ translate("emptyResults") }}</span>
            </div>

            <div v-if="loader" class="p-6 text-center">
              <img src="/media/loader.gif" class="loader" alt="" />
            </div>
            <!--end::List-->
          </div>
          <!--end::User List-->

          <!-- Task Form -->
          <ObjectiveTaskForm
            v-else-if="!removingConfirmation"
            :task="task"
            :objective="currentObjective"
            :isWaiting="isWaiting"
            @onSave="saveTask"
            @onCancel="stopTaskForm"
          />

          <div
            v-if="!isTaskFormVisible && !removingConfirmation"
            class="text-center"
          >
            <!--begin::Button-->
            <button
              @click="startTaskForm(null)"
              class="btn btn-round btn-info ps-4"
            >
              <i class="bi bi-plus me-1" />
              {{ translate("task") }}
            </button>
            <!--end::Button-->
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - View Tasks-->
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";
import ObjectiveTaskForm from "@/components/app/Objectives/ObjectiveTaskForm.vue";

const EMPTY_TASK = {
  title: "",
  description: "",
  date: "",
  startAt: "",
  endAt: "",
  okr: null,
  user: null
};

export default defineComponent({
  name: "_app_objective-task-manager_modal",
  components: { ObjectiveTaskForm },
  data() {
    return {
      isTaskFormVisible: false,
      currentStatusFilter: null,
      currentObjective: null,
      removingConfirmation: null,
      changeRoleUser: null,
      isRemovingConfirmationOpened: false,
      isWaiting: false,
      task: null,
      tasks: [],
      originalTasks: [],
      doneTasks: [],
      searcherForm: {
        text: ""
      },
      loader: false,
      objective: null
    };
  },
  computed: {
    authenticatedUser() {
      return this.$store.getters.currentUser;
    }
  },

  watch: {
    "$store.state.ObjectiveModule.selectedObjective": function() {
      this.isTaskFormVisible = false;
      this.objective = this.$store.getters.getSelectedObjective;
      this.loadTasks();
    }
  },

  methods: {
    checkTask(status, id) {
      const organizationId = this.$route.params.id
        ? this.$route.params.id
        : null;

      if (!organizationId) {
        return this.$notify({
          title: this.translate("important"),
          message: this.translate("noOrganizationHere")
        });
      }

      if (!this.objective || !this.objective.id) {
        return;
      }

      const currentToken = JwtService.getToken();
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${
        process.env.VUE_APP_BACKEND_PORT
      }/tasks/${id}/toggler/${status ? 1 : 0}`;

      fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 400) {
            throw new Error("generalError");
          }
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(() => {
          this.loadTasks();

          this.$emit("fetchObjectives");
        })
        .catch(error => {
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError")
          });
        });
    },

    filterTasks(filter) {
      if (!filter) {
        this.tasks = [...this.originalTasks];
        this.currentStatusFilter = null;
        return;
      }

      const filteredTasks = this.tasks.filter(task => {
        return task.status === filter;
      });

      this.currentStatusFilter = filter;
      this.tasks = [...filteredTasks];
    },

    loadTasks() {
      const organizationId = this.$route.params.id
        ? this.$route.params.id
        : null;

      if (!organizationId) {
        return this.$notify({
          title: this.translate("important"),
          message: this.translate("noOrganizationHere")
        });
      }

      if (!this.objective || !this.objective.id) {
        return;
      }

      const currentToken = JwtService.getToken();
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/tasks/` +
        this.objective.id;

      this.loader = true;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 400) {
            throw new Error("generalError");
          }
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(data => {
          this.loader = false;

          const { result: tasks } = data;

          if (!tasks || !tasks.length) {
            this.tasks = [];
            return;
          }

          this.tasks = [...tasks];
          this.originalTasks = [...tasks];

          setTimeout(function() {
            //$('[data-bs-toggle="tooltip"]').tooltip();
          }, 1000);
        })
        .catch(error => {
          this.isWaiting = false;

          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError")
          });
        });
    },

    startTaskRemoving(task) {
      setTimeout(() => {
        this.task = task; // Visual effect
      }, 150);

      this.isRemovingConfirmationOpened = true;
      this.removingConfirmation = {
        taskTitle: task.title,
        onConfirm: () => {
          this.removeTask(task);
        }
      };
    },

    removeTask(task) {
      const taskId = task.id;
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/tasks/${taskId}`;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }
          return res.json();
        })
        .then(() => {
          this.isWaiting = false;
          this.stopTaskRemoving();
          this.loadTasks();
          this.$notify({
            title: this.translate("done"),
            message: this.translate("taskCancelledSuccessfully")
          });
          this.$emit("fetchObjectives");
        })
        .catch(error => {
          this.stopTaskRemoving();
          this.loadTasks();
          this.$notify({
            title: this.translate("bad"),
            message: this.translate("taskRemovingError"),
            type: "danger"
          });
        });
    },

    lookForTasks() {
      alert("@todo: Go look for: " + this.searcherForm.text);
    },

    saveTask(taskToSave) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/tasks`;
      const urlAction = taskToSave && taskToSave.id ? "PUT" : "POST";
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: urlAction,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(taskToSave)
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }
          return res.json();
        })
        .then(() => {
          if (urlAction === "POST") {
            /* const { result: userData } = data;
            taskToSave.id = userData[0];
            taskToSave.status = 0;
            this.tasks.push(taskToSave); */
          }
          this.loadTasks();
          this.$notify({
            title: this.translate("great"),
            message: this.translate("TaskCreatedSuccessfully"),
            color: "success",
            type: "success"
          });
        })
        .catch(error => {
          this.$notify({
            title: this.translate("bad"),
            message: this.translate("taskCreatedError")
          });
        })
        .finally(() => {
          this.isWaiting = false;
          this.isTaskFormVisible = false;
          this.emitter.emit("fetchObjectives");
          this.loadTasks();
          this.$emit("fetchObjectives");
        });
    },

    startTaskForm(task) {
      this.isTaskFormVisible = true;
      setTimeout(() => {
        this.task = task && task.id ? { ...task } : { ...EMPTY_TASK };
        this.currentObjective = { ...this.objective };
      }, 333);
    },

    stopTaskForm() {
      this.isTaskFormVisible = false;
    },

    stopTaskRemoving() {
      this.task = null;
      this.removingConfirmation = null;
      this.isRemovingConfirmationOpened = false;
    },

    confirmTaskRemoving() {
      if (!this.task || !this.task.id) {
        console.info("[You need task id to continue]");
        return;
      }
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/tasks/` +
        this.task.id;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(() => {
          this.stopTaskRemoving();
          this.loadPeriods();
          this.$notify({
            title: this.translate("great"),
            message: this.translate("TaskDeleted"),
            type: "success"
          });
        })
        .catch(error => {
          this.isWaiting = false;

          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError"),
            type: "danger"
          });
        });
    },

    searchTask() {
      const { text: textToSearch } = this.searcherForm;
      const filteredTasks = this.tasks.filter(task => {
        const taskName = task.name.toLowerCase();
        const textToSearchInTasks = textToSearch.toLowerCase();
        return taskName.includes(textToSearchInTasks);
      });

      if (!textToSearch || textToSearch === "") {
        this.tasks = [...this.originalTasks];
        return;
      }

      this.tasks = [...filteredTasks];
    }
  },
  setup() {
    const { t, te } = useI18n();

    onMounted(() => {
      //$('[data-bs-toggle="tooltip"]').tooltip();
    });

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  }
});
</script>

<style scoped lang="scss">
.todo-check {
  background: #bfdfbf;
}

.todo-check span {
  text-decoration: line-through;
}

.clickeable {
  cursor: pointer;
}

.applied-filter {
  border: solid 1px #c9c9c9;
  border-radius: 9px;
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
  padding: 27px;
}

.task-list {
  border-radius: 6px;
  border: solid 1px #f0f0f0;
}

.loader {
  display: table;
  width: 100%;
  height: 75px;
  object-fit: cover;
}

.span-task {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.btn.btn-sm.btn-success.btn-icon.btn-gris {
  background-color: #ccc !important;
}

.tooltip {
  font-weight: normal;
  color: #3c48e5;
  text-decoration: none;
  position: relative;
}

.tooltip:hover {
  text-decoration: underline;
}

.tooltip:hover .tooltip-box {
  display: inline-block;
}

.tooltip-box {
  display: none;
  position: absolute;
  background: #000;
  line-height: 11px;
  z-index: 500;
  text-align: center;
  color: #fff;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  left: 45px;
  right: 0;
  bottom: 2px;
  width: 100px;
}

.tooltip-box::after {
  content: "";
  display: block;
  border-top: 7px solid #000;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  position: absolute;
  bottom: 12px;
  left: -10px;
  transform: rotate(90deg);
}

.btn-listo .tooltip-box {
  text-decoration: none !important;
  bottom: 8px;
}

.btn-listo .tooltip-box::after {
  bottom: 7px;
}
</style>
