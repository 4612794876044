<template>
  <!--begin::Modal - New Card-->
  <div
    class="modal fade"
    id="_app_shared_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->

          <h1
            class="mb-3 text-center"
            style="display: block; width: 100%; margin-top: 20px;"
          >
            {{
              isVisibleRemove
                ? translate("confirmRemoveShare")
                : translate("sharedInfoTitle")
            }}
          </h1>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            id="btn-cerrar"
            @click="cerrarModal()"
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div
          v-if="!isVisibleRemove"
          class="modal-body scroll-y mx-5 mx-xl-5 my-5"
        >
          <div class="row mb-10">
            <div class="col-md-12 fv-row mb-12">
              <div class="table-responsive">
                <table
                  class="table table-rounded table-striped border gy-7 gs-7"
                  style="text-align: center;"
                >
                  <tbody
                    v-if="
                      selectedObjective.shared &&
                        selectedObjective.shared.length
                    "
                  >
                    <tr>
                      <td>
                        <b>{{ translate("sharedWith") }}</b>
                      </td>
                      <td>
                        <b>{{ translate("sharedStatus") }}</b>
                      </td>
                      <td>
                        <b>{{ translate("sharedTitle") }}</b>
                      </td>
                      <td>
                        <b>{{ translate("sharedDelete") }}</b>
                      </td>
                    </tr>

                    <tr
                      v-for="(item, index) of selectedObjective.shared"
                      :key="index"
                    >
                      <td>{{ item.name }}</td>
                      <td>{{ item.status }}</td>
                      <td>{{ item.okr }}</td>
                      <td>
                        <button
                          @click="
                            descompartir(
                              item.name,
                              item.okr,
                              item.organization,
                              selectedObjective.id
                            )
                          "
                          class="btn btn-sm btn-danger btn-icon ms-2"
                        >
                          <i class="bi bi-trash" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td>
                        <b>{{ translate("ownerShared") }}</b>
                      </td>
                      <td>
                        <b>{{ translate("sharedTitle") }}</b>
                      </td>
                      <td>
                        <b>{{ translate("sharedStatus") }}</b>
                      </td>
                      <td>
                        <b>{{ translate("quitShared") }}</b>
                      </td>
                    </tr>

                    <tr
                      v-for="(item, index) of selectedObjective.sharedWithMe"
                      :key="index"
                    >
                      <td>{{ item.organization_owner }}</td>
                      <td>{{ item.okr }}</td>
                      <td>{{ item.status }}</td>
                      <td>
                        <button
                          @click="
                            renunciarCompartir(
                              item.name,
                              item.okr,
                              item.organization,
                              selectedObjective.id
                            )
                          "
                          class="btn btn-sm btn-danger btn-icon ms-2"
                        >
                          <i class="bi bi-trash" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="row">
            <div class="col-md-12">
              <div class="cont-confirm">
                <div class="message-ok">
                  {{ translate("removeShared") }} {{ nameObjective }}
                  {{ translate("with") }} {{ nameOrganization }}
                </div>
                <div class="mt-3 text-center">
                  <button
                    type="reset"
                    class="btn btn-light me-3"
                    @click.prevent="cancelModal()"
                  >
                    <span class="indicator-label">
                      {{ translate("cancel") }}
                    </span>
                  </button>

                  <button
                    v-if="!isRenuncia"
                    type="submit"
                    class="btn btn-primary"
                    @click="descompartirOk()"
                  >
                    <span class="indicator-label">
                      {{ translate("confirm") }}
                    </span>
                  </button>

                  <button
                    v-else
                    type="submit"
                    class="btn btn-primary"
                    @click="renunciarOk()"
                  >
                    <span class="indicator-label">
                      {{ translate("confirm") }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Card-->
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import useEmitter from "@/core/plugins/emitter.js";
import JwtService from "@/core/services/JwtService";

export default {
  name: "_app_shared_modal",
  components: {},
  data() {
    return {};
  },
  emits: "on-save",

  setup(_, { emit }) {
    const store = useStore();
    const emitter = useEmitter();
    const confirm = false;
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const isVisibleRemove = ref(false);
    const isRenuncia = ref(false);
    const nameOrganization = ref("");
    const nameObjective = ref("");
    const idOrganization = ref("");
    const idObjective = ref("");

    const cerrarModal = () => {
      isVisibleRemove.value = false;
      isRenuncia.value = false;
      nameOrganization.value = "";
      nameObjective.value = "";
      idOrganization.value = "";
      idObjective.value = "";
    };

    const cancelModal = () => {
      isVisibleRemove.value = false;
      isRenuncia.value = false;
      nameOrganization.value = "";
      nameObjective.value = "";
      idOrganization.value = "";
      idObjective.value = "";
    };

    const descompartir = (name, okr, organization, id) => {
      isVisibleRemove.value = true;
      nameOrganization.value = name;
      nameObjective.value = okr;
      idOrganization.value = organization;
      idObjective.value = id;
    };

    const renunciarCompartir = (name, okr, organization, id) => {
      isVisibleRemove.value = true;
      isRenuncia.value = true;
      nameOrganization.value = name;
      nameObjective.value = okr;
      idOrganization.value = organization;
      idObjective.value = id;
    };

    const descompartirOk = () => {
      const currentToken = JwtService.getToken();
      //const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/quitShare/${okr}/${idorg}`;
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/desShare/${idObjective.value}/${idOrganization.value}`;

      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          return res.json();
        })
        .then(() => {
          document.getElementById("btn-cerrar").click();
          document.getElementById("close_main_modal").click();
          emit("fetchObjectives");
        })
        .catch(error => {
          console.log("[error]", error);
        });
    };

    const renunciarOk = () => {
      const currentToken = JwtService.getToken();
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/quitShare/${idObjective.value}/${idOrganization.value}`;

      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          return res.json();
        })
        .then(() => {
          const modal = document.getElementById("btn-cerrar").click();
          emit("fetchObjectives");
        })
        .catch(error => {
          console.log("[error]", error);
        });
    };

    const desshared = (okr, idorgtoquit) => {
      const currentToken = JwtService.getToken();
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/desShare/${okr}/${idorgtoquit}`;
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          return res.json();
        })
        .catch(error => {
          console.log("[error]", error);
        });
    };

    const selectedObjective = computed(() => {
      const selected = store.getters.getSelectedObjective;
      if (selected != null) {
        return selected;
      }

      return "----";
    });

    return {
      selectedObjective,
      translate,
      confirm,
      descompartir,
      isVisibleRemove,
      nameOrganization,
      nameObjective,
      idOrganization,
      idObjective,
      cerrarModal,
      cancelModal,
      descompartirOk,
      renunciarCompartir,
      renunciarOk,
      isRenuncia
    };
  }
};
</script>

<style>
.btn-descompartir {
  background: #f1416c;
  border: none;
  line-height: 1;
  padding: 10px;
  margin-top: -10px;
  border-radius: 6px;
}

.btn-descompartir i {
  color: #fff;
}

.btn.btn-sm.btn-icon.btn-active-color-primary {
  position: absolute;
  right: 15px;
  top: 15px;
}

.cont-confirm {
  min-height: 20vh;
  padding: 30px;
  text-align: center;
}

.message-ok {
  margin-bottom: 50px;
  font-size: 17px;
  padding: 0 20px;
}
</style>
