<template>
  <!--begin::Accordion-->
  <div class="accordion" id="kt_accordion_report">
    <div class="accordion-item">
      <h2 class="accordion-header" id="kt_accordion_report_header_2">
        <button
          class="accordion-button fs-4 fw-bold collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_accordion_report_body_2"
          aria-expanded="false"
          aria-controls="kt_accordion_report_body_2"
        >
          {{ translate("report") }}
        </button>
      </h2>
      <div
        id="kt_accordion_report_body_2"
        class="accordion-collapse collapse"
        aria-labelledby="kt_accordion_report_header_2"
        data-bs-parent="#kt_accordion_report"
      >
        <div class="accordion-body">
          <div class="container">
            <div class="row">
              <div class="report-title">{{ translate("userInvitations") }}</div>
              <div class="col">
                <div class="table-responsive">
                  <table
                    class="table table-rounded text-center table-striped border gy-7 gs-7"
                  >
                    <thead>
                      <tr
                        class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200"
                      >
                        <th>{{ translate("acceptedInvitation") }}</th>
                        <th>{{ translate("pendingInvitation") }}</th>
                        <th>{{ translate("rejectedInvitation") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ acceptedCount }}</td>
                        <td>{{ pendingCount }}</td>
                        <td>{{ rejectiveCount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="cont-graf-col">
                  <div class="report-title">{{ translate("advanceOKR") }}</div>
                  <div class="cont-graf">
                    <vue3-chart-js
                      :id="barChart.id"
                      ref="barChartRef"
                      :type="barChart.type"
                      :data="barChart.data"
                      :options="barChart.options"
                    ></vue3-chart-js>
                  </div>
                </div>

                <div class="cont-graf-col">
                  <div class="report-title">
                    {{ translate("assignedTasks") }}
                  </div>
                  <div class="cont-graf">
                    <vue3-chart-js
                      :id="barChart2.id"
                      ref="barChart2Ref"
                      :type="barChart2.type"
                      :data="barChart2.data"
                      :options="barChart2.options"
                    ></vue3-chart-js>
                  </div>
                </div>
              </div>
              <!-- <div class="col-6">
                <div class="report-title">
                  {{ translate("updatesObjectives") }}
                </div>
                <div class="cont-graf">
                  <vue3-chart-js
                    :id="doughnutChart.id"
                    ref="chartRef"
                    :type="doughnutChart.type"
                    :data="doughnutChart.data"
                  ></vue3-chart-js>
                </div>
              </div> -->
            </div>
            <div class="row" style="margin-bottom: 25px;">
              <div class="col-12">
                <!--begin::Accordion-->
                <div class="accordion" id="kt_accordion_users">
                  <div class="accordion-item">
                    <h2
                      class="accordion-header"
                      id="kt_accordion_users_header_3"
                    >
                      <button
                        class="accordion-button fs-4 fw-bold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_users_body_3"
                        aria-expanded="false"
                        aria-controls="kt_accordion_users_body_3"
                      >
                        {{ translate("usersInformation") }}
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_users_body_3"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_users_header_3"
                      data-bs-parent="#kt_accordion_users"
                    >
                      <div class="accordion-body">
                        <div class="table-responsive">
                          <table class="table table-striped gy-7 gs-7">
                            <thead>
                              <tr
                                class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                              >
                                <th>{{ translate("name") }}</th>
                                <th>{{ translate("email") }}</th>
                                <th>{{ translate("cellphone") }}</th>
                                <th>{{ translate("role") }}</th>
                                <th>{{ translate("lastLog") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template
                                v-for="(user, index) of users"
                                :key="index"
                              >
                                <tr v-if="user.status == 'accepted'">
                                  <td>{{ user.lastname }}, {{ user.name }}</td>
                                  <td>{{ user.email }}</td>
                                  <td>{{ user.phone }}</td>
                                  <td>{{ user.role }}</td>
                                  <td>{{ user.last_logged }}</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <!--begin::Accordion-->
                <div class="accordion" id="kt_accordion_okrs">
                  <div class="accordion-item">
                    <h2
                      class="accordion-header"
                      id="kt_accordion_okrs_header_3"
                    >
                      <button
                        class="accordion-button fs-4 fw-bold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_okrs_body_3"
                        aria-expanded="false"
                        aria-controls="kt_accordion_okrs_body_3"
                      >
                        {{ translate("objectiveInformation") }}
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_okrs_body_3"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_okrs_header_3"
                      data-bs-parent="#kt_accordion_okrs"
                    >
                      <div class="accordion-body">
                        <div class="table-responsive">
                          <table class="table table-striped gy-7 gs-7">
                            <thead>
                              <tr
                                class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                              >
                                <th>ID</th>
                                <th>{{ translate("title") }}</th>
                                <th>{{ translate("description") }}</th>
                                <th>{{ translate("type") }}</th>
                                <th>{{ translate("user") }}</th>
                                <th>{{ translate("lastAdvanced") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(okr, index) of okrs" :key="index">
                                <td>{{ okr.okr }}</td>
                                <td>{{ okr.title }}</td>
                                <td>{{ okr.description }}</td>
                                <td>{{ okr.type }}</td>
                                <td>{{ okr.user }}</td>
                                <td>{{ okr.last_advanced_registered }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Accordion-->
</template>

<style>
#kt_accordion_report {
  margin-bottom: 6px;
  border-radius: 6px !important;
  margin-top: 12px;
}

#kt_accordion_report .accordion-button {
  background: #fff !important;
  color: #000;
  padding-left: 45px;
  position: relative;
  padding-bottom: 12px;
  padding-top: 12px;
}

#kt_accordion_report .accordion-button:after {
  flex-shrink: 0;
  width: 1.15rem;
  height: 1.15rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.15rem;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  left: 15px;
}

#kt_accordion_report .kt_accordion_report_body_2 {
  background: #fff;
}

.cont-graf {
  padding: 0 25px;
}

.report-title {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;
  display: block;
  margin-bottom: 15px;
}

.cont-graf-col {
  margin-bottom: 25px;
}

#kt_accordion_report #kt_accordion_okrs .accordion-button,
#kt_accordion_report #kt_accordion_users .accordion-button {
  background: #1e1e2d;
}

@media (max-width: 768px) {
  #kt_accordion_report .col-6 {
    width: 100% !important;
  }

  #kt_accordion_report .accordion-body {
    padding: 10px 0;
  }
}
</style>

<script>
import { useI18n } from "vue-i18n";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { ref, onMounted } from "vue";
import JwtService from "@/core/services/JwtService";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

/* eslint-disable */
export default {
    components:{
        Vue3ChartJs,
    },
  setup() {
    const route = useRoute();
    const store = useStore();
    const currentToken = JwtService.getToken();
    const okrs = ref(null);
    const users = ref(null);
    const acceptedCount = ref(0);
    const pendingCount = ref(0);
    const rejectiveCount =  ref(0);
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const ArrayColors = [
              '#41B883',
              '#E46651',
              '#00D8FF',
              '#DD1B16',
              '#00C19A',
              '#25425F',
              '#0579C7',
              '#E6B800',
              '#333333'
    ]

    const chartRef = ref(null)

    const doughnutChart = {
      id: 'doughnut',
      type: 'doughnut',
      data: {
        labels: ['NULL'],
        datasets: [
          {
            backgroundColor: ArrayColors,
            data: [100]
          }
        ]
      }
    }

    const updateChart = (okrTitle, advanceOkrLog) => {
      doughnutChart.data.labels = okrTitle
      doughnutChart.data.datasets = [
        {
          backgroundColor: ArrayColors,
          data: advanceOkrLog
        }
      ]

      chartRef.value.update()
    }

    const barChartRef = ref(null);

    const barChart = {
      id: "barChart",
      type: "bar",
      options: {
        min: 0,
        max: 100,
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: {
              callback: function (value) {
                return `${value}%`;
              },
            },
          },
        },
      },
      data: {
        labels: ["OKR"],
        datasets: [
          {
            label: "Ciclo actual",
            backgroundColor: ArrayColors,
            data: [0],
          }
        ],
      },
    };

    const updateBarChart = (barChartLabels, barChartData) => {
      barChart.data.labels = barChartLabels
      barChart.data.datasets = [
        {
          label: "Ciclo actual",
          backgroundColor: ArrayColors,
          data: barChartData
        }
      ]

      barChartRef.value.update()
    }

    const barChart2Ref = ref(null);

    const barChart2 = {
      id: "barChart2",
      type: "bar",
      options: {
        min: 0,
        max: 100,
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
        },
        scales: {
          y: {
            min: 0,
            max: 20,
            ticks: {
              callback: function (value) {
                return `${value}`;
              },
            },
          },
        },
      },
      data: {
        labels: ["usuario"],
        datasets: [
          {
            label: "Ciclo actual",
            backgroundColor: ArrayColors,
            data: [0],
          }
        ],
      },
    };

    const updateBarChart2 = (barChartLabels, barChartData) => {
      barChart2.data.labels = barChartLabels
      barChart2.data.datasets = [
        {
          label: "Ciclo actual",
          backgroundColor: ArrayColors,
          data: barChartData
        }
      ]

      barChart2Ref.value.update()
    }

    function fetchData() {
        const cycle = store.getters.getCycle;
        const idOrganization = route.params.id;
        const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/report/`+idOrganization+"/"+cycle.id+"/";
        return fetch(url, {
            method: 'get',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": currentToken
            }
        }).then(res => {
            return res.json();
        }).then(json => {
                okrs.value = json.okrs;
                users.value = json.users;
                let okrTitle = [];
                let okrAdvance = [];
                let okrIds = [];
                json.okrs.forEach(element => {
                    okrTitle.push(element.title);
                    okrAdvance.push(element.advance);
                    okrIds.push(element.okr);
                });

                // fetchDataLog(okrTitle, okrIds);

                let usersName = [];
                let usersTask = [];
                json.users.forEach(element =>{
                    if (element.status=="accepted"){
                        usersName.push(element.name);
                        usersTask.push(json.okrs.filter(elto => elto.user == element.user).length);
                        acceptedCount.value = acceptedCount.value + 1;
                    }

                    if (element.status=="pending"){
                        pendingCount.value = pendingCount.value + 1;
                    }
                });

                updateBarChart2(usersName, usersTask);
                updateBarChart(okrTitle, okrAdvance);
        }).catch(err => {
            
        });
    }

    function fetchDataLog(okrTitle, okrIds){
        let advanceOkrLog = [];
        okrIds.forEach(element =>{

            const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okr-logs/` + element;
            const currentToken = JwtService.getToken();

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": currentToken
                }
            })
            .then(res => res.json())
            .then(data => {
                if (data.logs != null) {
                    advanceOkrLog.push(data.logs.length);
                }
            });
        });
        updateChart(okrTitle, advanceOkrLog);

    };

    onMounted(() => {
      fetchData();
    });

    return {
      okrs,
      users,
      doughnutChart,
      updateChart,
      chartRef,
      barChart,
      barChartRef,
      barChart2,
      barChart2Ref,
      acceptedCount,
      pendingCount,
      rejectiveCount,
      translate
    };
  },
};
</script>
