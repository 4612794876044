<template>
  <!--begin::Form-->
  <Form id="kt_api_key_form" class="form" @submit="save">
    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col Name-->
          <div class="col-12">
            <label
              class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            >
              <span class="required">{{ translate("apiKeyDescription") }}</span>
              <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="description"
              ></i>
            </label>

            <Field
              v-model="form.description"
              autofocus
              type="text"
              class="form-control form-control-solid"
              placeholder=""
              name="description"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="description" />
              </div>
            </div>
          </div>

          <!--end::Col Name-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Actions-->
    <div class="text-center mt-3">
      <button
        ref="cancelButtonRef"
        type="reset"
        class="btn btn-light me-3"
        @click.prevent="cancel"
      >
        <span class="indicator-label">
          {{ translate("cancel") }}
        </span>
      </button>

      <button
        :disabled="canSave()"
        ref="submitButtonRef"
        type="submit"
        id="kt_modal_new_card_submit"
        :class="
          canSave() ? 'btn btn-light save-ciclo' : 'btn btn-primary  save-ciclo'
        "
      >
        <span class="indicator-label">
          {{ translate("save") }}
        </span>
        <span class="indicator-progress">
          {{ translate("pleaseWait") }}
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
    <!--end::Actions-->
  </Form>
  <!--end::Form-->
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";

const EMPTY_API_KEY = {
  id: null,
  description: "",
  api_key: "",
  organization: null
};

export default defineComponent({
  name: "_app_api_key_form",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  },
  props: {
    apiKey: {
      type: Object,
      default: () => EMPTY_API_KEY
    }
  },

  data() {
    return {
      form: { ...EMPTY_API_KEY }
    };
  },
  methods: {
    canSave() {
      return this.form.description === "";
    },
    save() {
      document
        .querySelector(".save-ciclo")
        .setAttribute("disabled", "disabled");
      this.$emit("onSave", this.form);
      document.querySelector(".save-ciclo").removeAttribute("disabled");
    },
    cancel() {
      this.$emit("onCancel");
    }
  }
});
</script>

<style>
#kt_modal_new_card_submit:disabled,
#kt_modal_new_card_submit[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666;
}
</style>
