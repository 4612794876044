<template>
  <!--begin::Form-->
  <Form id="kt_invitation_form" class="form" @submit="save">
    <!--begin::Input group-->
    <div class="d-flex flex-column mb-10">
      <label class="fs-6 fw-bold mb-2">{{ translate("description") }}</label>
      <textarea
        :disabled="isWaiting"
        :placeholder="translate('description')"
        v-model="form.description"
        class="form-control form-control-solid"
        name="description"
      />
    </div>
    <!--end::Input group-->

    <!--begin::Actions-->
    <div class="text-center mt-15">
      <button
        ref="cancelButtonRef"
        :disabled="isWaiting"
        type="reset"
        class="btn btn-light me-3"
        @click.prevent="cancel"
      >
        <span class="indicator-label">
          {{ translate("cancel") }}
        </span>
      </button>

      <button
        ref="submitButtonRef"
        :disabled="isWaiting"
        type="submit"
        id="kt_modal_new_card_submit"
        class="btn btn-primary"
      >
        <span class="indicator-label">
          {{ isWaiting ? translate("sending") : translate("send") }}
        </span>
        <span class="indicator-progress">
          {{ translate("pleaseWait") }}
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
    <!--end::Actions-->
  </Form>
  <!--end::Form-->
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { Form } from "vee-validate";

const EMPTY_NOTE = {
  description: "",
  okr: null,
  user: null
};

export default defineComponent({
  name: "_app_objective-note_form",
  components: {
    Form
  },
  props: {
    note: {
      type: Object,
      default: () => {
        return { ...EMPTY_NOTE };
      }
    },
    objective: {
      type: Object,
      default: () => null
    },
    isWaiting: {
      type: Boolean,
      default: () => false
    }
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  },
  data() {
    return {
      form: { ...EMPTY_NOTE },
      localObjective: null
    };
  },
  computed: {
    authenticatedUser() {
      return this.$store.getters.currentUser;
    }
  },
  watch: {
    note(noteValue) {
      this.form = noteValue ? noteValue : { ...EMPTY_NOTE };
    },
    objective(objectiveValue) {
      this.localObjective = objectiveValue;
    }
  },
  methods: {
    save() {
      this.form.user =
        this.authenticatedUser && this.authenticatedUser.id
          ? this.authenticatedUser.id
          : null;
      this.form.okr =
        this.localObjective && this.localObjective.id
          ? this.localObjective.id
          : null;
      this.$emit("onSave", { ...this.form });
    },
    cancel() {
      this.$emit("onCancel");
    }
  }
});
</script>
