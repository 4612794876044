<template>
  <div class="grid-container skeleton">
    <div class="grid-item-1"></div>
    <div class="grid-item-2"></div>
    <div class="grid-item-3"></div>
  </div>
</template>

<style scoped lang="scss">
.grid-container {
  display: grid;
  padding: 5px 5px 0px 5px;
  grid-template-columns: 5% 3fr 1fr;
  grid-gap: 2px;
  height: 66px; /* ajustar la altura del contenedor */
}
/* Estilos para el loader */
.skeleton {
  background: #f2f2f2;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    #f2f2f2 50%,
    transparent 100%
  );
  animation: loading 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes loading {
  from {
    left: -50%; /* iniciar la animación desde el centro del contenedor */
  }
  to {
    left: 100%; /* terminar la animación al final del contenedor */
  }
}

/* Estilos para los elementos */
.grid-item-1 {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.grid-item-2 {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.grid-item-3 {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
