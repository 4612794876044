
import { useI18n } from "vue-i18n";

import { computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Objective } from "./types";

export default {
  name: "KeyPad",

  props: {
    element: {
      type: Object as () => Objective,
    },
    isTypeUser: {
      type: Boolean,
    },
    isPlanning: {
      type: Boolean,
    },
  },

  setup() {
    const store = useStore();
    const { t, te } = useI18n();

    const authenticatedUser = computed(() => store.getters.currentUser);

    const translate = (text) => {
      return te(text) ? t(text) : text;
    };

    const newSubObjective = (element: Objective) => {
      store.dispatch(Actions.PREV_OBJECTIVE, element);
      store.dispatch(Actions.ADD_SUBOBJECTIVE, element);
    };

    return {
      translate,
      newSubObjective,
      authenticatedUser,
    };
  },
};
