
import { ref, Ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { Objective } from "../../types";

interface Change {
  advance: number;
  lastname: string;
  logged_at: string;
  name: string;
  okr: number;
}

export default {
  setup() {
    const store = useStore();
    const { t, te, locale } = useI18n();
    const translate = (text: string) => (te(text) ? t(text) : text);
    const form: Ref<Objective> = ref(
      store.getters.getSelectedObjectiveCamelCase
    );

    const changes: Ref<Change[]> = ref(store.getters.getLogs);

    const createdByName = computed(() => {
      return form.value?.owner?.name;
    });
    const createdDate = computed(() => {
      return form.value?.createdAt;
    });
    const lastEditedByName = computed(() => {
      return form.value?.user.name;
    });

    watch(
      () => store.getters.getLogs,
      function(currentValue) {
        changes.value = currentValue;
      }
    );

    watch(
      () => store.getters.getSelectedObjectiveCamelCase,
      function(currentValue) {
        store.dispatch(Actions.FETCH_LOGS, currentValue.id);
        form.value = currentValue;
      }
    );

    const formatDate = (date: string) => {
      const parsedDate = dayjs(date);
      const lang = locale.value;

      const formatString =
        lang === "en" ? "dddd, D MMMM YYYY h:mm A" : "dddd D MMMM YYYY H:mm";
      return parsedDate.locale(lang).format(formatString);
    };

    return {
      changes,
      translate,
      formatDate,
      createdByName,
      createdDate,
      lastEditedByName
    };
  }
};
