<template>
  <!--begin::Modal - New Card-->
  <div
    class="modal fade"
    id="_app_objective_removing_confirmed_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h3>{{ translate("objectiveRemoving") }}</h3>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            id="objective_removing_confirmer_modal_closer"
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-15 my-5 text-center">
          <h2>{{ translate("youAreAboutOfRemovingOneObjective") }}</h2>

          <h5 class="my-9">{{ translate("pleaseConfirm") }}</h5>

          <div class="actions text-center">
            <button
              type="button"
              id="objective_removing_confirm_button"
              class="btn btn-danger"
              @click="confirmRemoving"
            >
              <span class="indicator-label">
                {{ translate("confirm") }}
              </span>
            </button>
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Card-->
</template>

<script>
/* eslint-disable */
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "_app_objective_removing_confirmed_modal",

  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const translate = (text) => {
      return te(text) ? t(text) : text;
    };

    const confirmRemoving = () => {

      store.dispatch(
        Actions.REMOVE_OBJECTIVE,
        store.getters.getSelectedObjective
      );
      const modalCloser = document.getElementById(
        "objective_removing_confirmer_modal_closer"
      );
      modalCloser.click();
    };

    return {
      translate,
      confirmRemoving,
    };
  },
});
</script>
