<template>
  <div class="main-container">
    <div class="percentage-container">
      <div class="title-container">
        <b>{{ translate("progress") }}</b>
      </div>
      <div class="btn-group" role="group" aria-label="Absolute / Relative">
        <button
          type="button"
          class="btn btn-sm btn-controls"
          :class="{
            'btn-dark active': percentage,
            'btn-secondary': !percentage
          }"
          :style="{
            background: percentage ? '#ffcc00 !important' : '#fff !important'
          }"
          @click="changePercentage(true)"
        >
          <small>{{ translate("absolute") }}</small>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-controls"
          :class="{
            'btn-dark active': !percentage,
            'btn-secondary': percentage
          }"
          :style="{
            background: !percentage ? '#0238fa !important' : '#fff !important'
          }"
          @click="changePercentage(false)"
        >
          <small>{{ translate("relative") }}</small>
        </button>
      </div>
    </div>
    <div class="filter-container">
      <select
        name="status"
        data-control="select2"
        data-hide-search="true"
        @change="changeUser($event)"
        class="form-select form-select-white form-select-sm fw-bolder btn-controls"
      >
        <option selected :value="0">{{ translate("allUsers") }}</option>
        <template v-for="member in members" :key="member.id">
          <option :value="member.user">
            {{ member.name }} {{ member.lastname }}
          </option>
        </template>
      </select>
      <button
        class="btn btn-secondary btn-icon btn-sm ms-2 mt-0 btn-controls"
        type="button"
        title="Contraer"
        @click="switchExpand()"
      >
        <i v-if="expand" class="bi bi-arrows-collapse" />
        <i v-else class="bi bi-arrows-expand" />
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "Controls",

  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const percentage = computed(() => store.getters.getPercentage);
    const expand = computed(() => store.getters.getExpand);
    const members = computed(() => store.getters.getMembers);

    const changePercentage = type => {
      localStorage.setItem("activePercentage", JSON.stringify(type));
      store.dispatch(Actions.CHANGE_PERCENTAGE, type);
    };

    const changeUser = event => {
      localStorage.removeItem("activeAccordionGroup");
      store.dispatch(Actions.CHANGE_EXPAND, true);
      store.dispatch(
        Actions.CHANGE_SELECTED_USER,
        parseInt(event.target.value)
      );
    };

    const switchExpand = async () => {
      localStorage.removeItem("activeAccordionGroup");
      store.dispatch(Actions.CHANGE_EXPAND);
    };

    return {
      translate,
      expand,
      changeUser,
      members,
      changePercentage,
      percentage,
      switchExpand
    };
  }
});
</script>

<style lang="scss">
.main-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 5px;
}

.percentage-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.filter-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
}

.btn-controls {
  font-size: 12px;
}

@media screen and (min-width: 830px) {
  .main-container {
    grid-template-columns: 1fr 150px;
  }
  .percentage-container {
    flex-direction: row;
  }
}

.title-container {
  color: #fff;
  margin-right: 1rem;
}
</style>
