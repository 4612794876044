
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

const EXPLANATION_TYPES = {
  _TYPE_1: 1,
  _TYPE_2: 2,
  _TYPE_3: 3,
  _TYPE_4: 4
};

export default defineComponent({
  name: "_app_cycle-manager_modal",
  components: {},
  data() {
    return {
      explanationText: "",
      explanationTypes: EXPLANATION_TYPES,
      selectedExplanationType: 0,
      helpItems: [
        {
          title: "OKR",
          description: "OKRMeans"
        },
        {
          title: "OMI",
          description: "OMIMeans"
        },
        {
          title: "O",
          description: "OMeans"
        },
        {
          title: "KR",
          description: "KRMeans"
        }
      ]
    };
  },
  methods: {
    startExplanation(explanationType) {
      let text = "";

      switch (explanationType) {
        case EXPLANATION_TYPES._TYPE_1:
          text = this.translate("explanation1");
          break;
        case EXPLANATION_TYPES._TYPE_2:
          text = this.translate("explanation2");
          break;
        case EXPLANATION_TYPES._TYPE_3:
          text = this.translate("explanation3");
          break;
        case EXPLANATION_TYPES._TYPE_4:
          text = this.translate("explanation4");
          break;
        default:
          break;
      }

      this.explanationText = text;
      this.selectedExplanationType = explanationType;
    },
    endExplanation() {
      this.explanationText = "";
      this.selectedExplanationType = 0;
    },
    isExplanationTypeSelected(explanationType) {
      return (
        this.selectedExplanationType &&
        this.selectedExplanationType === explanationType
      );
    }
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  }
});
