<template>
  <!--begin::Modal - New Card-->
  <div
    class="modal fade"
    id="_app_objective_info_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h3>{{ translate("informationOKR") }}</h3>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-5 my-5">
          <div class="row mb-10">
            <div class="col-md-12 fv-row mb-12">
              <div class="table-responsive">
                <table
                  class="table table-rounded table-striped border gy-7 gs-7"
                >
                  <tbody>
                    <tr>
                      <td><b>OKR</b></td>
                      <td>{{ selectedObjective.okr }}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{{ translate("title") }}</b>
                      </td>
                      <td>{{ selectedObjective.title }}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{{ translate("period") }}</b>
                      </td>
                      <td>{{ selectedObjective.period }}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{{ translate("code") }}</b>
                      </td>
                      <td>{{ selectedObjective.code }}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{{ translate("type") }}</b>
                      </td>
                      <td v-if="selectedObjective.type === 'objective'">
                        {{ translate("typeObjective") }}
                      </td>
                      <td v-else>{{ translate("typeKeyResult") }}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{{ translate("advance") }}</b>
                      </td>
                      <td>
                        {{
                          percentage
                            ? parseInt(selectedObjective.advance)
                            : parseInt(selectedObjective.relativeAdvance)
                        }}
                        %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="table-responsive">
                <table
                  class="table table-rounded table-striped border gy-7 gs-7"
                >
                  <thead>
                    <tr>
                      <th>
                        <b>{{ translate("description") }}</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="description">
                        {{ selectedObjective.description }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="table-responsive">
                <table
                  class="table table-rounded table-striped border gy-7 gs-7"
                >
                  <tbody>
                    <tr>
                      <td>
                        <b>{{ translate("unitObjective") }}</b>
                      </td>
                      <td>{{ selectedObjective.unit }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div id="edit-val" class="table-responsive">
                <table
                  class="table table-rounded table-striped border gy-7 gs-7"
                >
                  <thead>
                    <tr>
                      <th class="text-center">
                        <b>{{ translate("startValue") }}</b>
                      </th>
                      <th class="text-center">
                        <b>{{ translate("objectiveValue") }}</b>
                      </th>
                      <th class="text-center">
                        <b>{{ translate("valueNow") }}</b>
                      </th>
                      <th class="text-center">
                        <b>{{ translate("action") }}</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="edit_start_value"
                          id="edit_start_value"
                          class="form-control form-control-solid"
                          style="background-color: var(--bs-table-bg);"
                          data-rules="numeric:1"
                          disabled
                          v-model="selectedObjective.start_value"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="edit_target_value"
                          id="edit_target_value"
                          class="form-control form-control-solid"
                          style="background-color: var(--bs-table-bg);"
                          data-rules="numeric:1"
                          disabled
                          v-model="selectedObjective.target_value"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="edit_current_value"
                          id="edit_current_value"
                          class="form-control form-control-solid"
                          data-rules="numeric:1"
                          v-model="selectedObjective.current_value"
                        />
                      </td>
                      <td>
                        <button
                          class="btn btn-success btn-sm ms-4"
                          @click="submit2"
                        >
                          {{ translate("save") }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div id="edit-note" class="table-responsive">
                <div class="modal-body scroll-y">
                  <!--begin::Heading-->
                  <div class="text-center">
                    <!--begin::Title-->
                    <h1
                      class="mb-3"
                      :class="{
                        'text-danger': removingConfirmation !== null
                      }"
                    >
                      {{
                        isNoteFormVisible
                          ? isEditing
                            ? translate("editNote")
                            : translate("newNote")
                          : removingConfirmation
                          ? translate("removingConfirmationTitle")
                          : translate("note") + "s"
                      }}
                    </h1>
                    <!--end::Title-->
                  </div>
                  <!--end::Heading-->

                  <!-- begin:: Removing confirmation -->
                  <div v-if="removingConfirmation" class="text-center">
                    <h2
                      v-text="removingConfirmation.noteDescription"
                      class="mb-12"
                    />
                    <div class="mt-3 text-center">
                      <button
                        ref="cancelButtonRef"
                        :disabled="isWaiting"
                        type="reset"
                        class="btn btn-light me-3"
                        @click.prevent="stopNoteRemoving"
                      >
                        <span class="indicator-label">
                          {{ translate("cancel") }}
                        </span>
                      </button>

                      <button
                        ref="submitButtonRef"
                        :disabled="isWaiting"
                        type="submit"
                        class="btn btn-primary"
                        @click="removingConfirmation.onConfirm()"
                      >
                        <span class="indicator-label">
                          {{
                            isWaiting
                              ? translate("removing")
                              : translate("confirm")
                          }}
                        </span>
                        <span class="indicator-progress">
                          {{ translate("pleaseWait") }}
                          <span
                            class="spinner-border spinner-border-sm align-middle ms-2"
                          ></span>
                        </span>
                      </button>
                    </div>
                  </div>
                  <!-- end:: Removing confirmation -->

                  <!--begin::note List-->
                  <div
                    v-if="!isNoteFormVisible && !removingConfirmation"
                    class="mb-15 mt-10"
                  >
                    <!--begin::List-->
                    <div
                      v-if="notes.length"
                      class="mh-375px scroll-y"
                      style="margin: 0 0 0 -25px !important;"
                    >
                      <template v-for="(note, index) in notes" :key="index">
                        <!--begin::User-->
                        <div
                          :class="
                            'd-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed'
                          "
                        >
                          <!--begin::Details-->
                          <div class="d-flex align-items-center">
                            <!--begin::Details-->
                            <div class="ms-6">
                              <!--begin::User & Date-->
                              <span
                                class="
                          d-flex
                          align-items-center
                          fs-5
                          fw-bolder
                          text-dark text-hover-primary"
                              >
                                {{
                                  note.edited_at
                                    ? translate("lastEditedOn") +
                                      ": " +
                                      new Date(note.edited_at).toLocaleString()
                                    : translate("createdOn") +
                                      ": " +
                                      new Date(note.created_at).toLocaleString()
                                }}
                                {{ authenticatedUser.name }}
                                {{ authenticatedUser.lastname }}
                              </span>
                              <!--end::User & Date-->
                              <!--begin::Description-->
                              <div>
                                <span
                                  class="
                          d-flex
                          align-items-center
                          fs-5
                          text-dark text-hover-primary"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  :title="note.description"
                                  style="white-space: pre-line"
                                >
                                  {{ note.description }}
                                </span>
                                <!--end::Description-->
                              </div>
                              <!--begin::Actions-->
                              <div
                                v-if="authenticatedUser.id === note.user"
                                class="d-flex"
                              >
                                <div>
                                  <!--begin:: Edit button-->
                                  <a
                                    @click="startNoteForm(note)"
                                    style="text-decoration: underline;"
                                    >{{ translate("edit") }}</a
                                  >
                                  <!--end:: Edit button-->
                                  <!--begin:: Remove button-->
                                  <a
                                    @click="startNoteRemoving(note)"
                                    style="text-decoration: underline;margin-left: 10px;"
                                    >{{ translate("remove") }}</a
                                  >
                                  <!--end:: Remove button-->
                                </div>
                              </div>
                              <!--end::Actions-->
                            </div>
                            <!--end::Details-->
                          </div>
                          <!--end::Details-->
                        </div>
                        <!--end::User-->
                      </template>
                    </div>

                    <div v-else-if="!loader" class="p-3 text-center">
                      <span class="text-muted">{{
                        translate("emptyResults")
                      }}</span>
                    </div>

                    <div v-if="loader" class="p-6 text-center">
                      <img src="/media/loader.gif" class="loader" alt="" />
                    </div>
                    <!--end::List-->
                  </div>
                  <!--end::User List-->

                  <!-- Note Form -->
                  <ObjectiveNoteForm
                    v-else-if="!removingConfirmation"
                    :note="note"
                    :objective="currentObjective"
                    :isWaiting="isWaiting"
                    @onSave="saveNote"
                    @onCancel="stopNoteForm"
                  />

                  <div
                    v-if="!isNoteFormVisible && !removingConfirmation"
                    class="text-center"
                  >
                    <!--begin::Button-->
                    <button
                      @click="startNoteForm(null)"
                      class="btn btn-round btn-info ps-4"
                    >
                      <i class="bi bi-plus me-1" />
                      {{ translate("note") }}
                    </button>
                    <!--end::Button-->
                  </div>
                </div>
              </div>

              <!--begin::Accordion-->
              <div class="accordion accordion-icon-toggle" id="kt_accordion_2">
                <!--begin::Item-->
                <div class="mb-5">
                  <!--begin::Accordion-->

                  <div class="accordion" id="kt_accordion_1">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="kt_accordion_1_header_2">
                        <button
                          class="accordion-button fs-4 fw-bold collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_1_body_2"
                          aria-expanded="false"
                          aria-controls="kt_accordion_1_body_2"
                        >
                          {{ translate("advanceOKR") }}
                        </button>
                      </h2>
                      <div v-if="selectedObjective && selectedObjective.id">
                        <ObjetiveTableLogsOkr
                          :objectiveId="selectedObjective.id"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end::Accordion-->
                </div>
                <!--end::Item-->
              </div>
              <!--end::Accordion-->

              <button
                data-bs-toggle="modal"
                data-bs-target="#_app_objective-task-manager_modal"
                @click="openTaskManagerModal2(selectedObjective)"
                class="btn btn-sm btn-primary"
                style="margin-left: 33%;"
              >
                {{ translate("taskManager") }}
              </button>
            </div>
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Card-->
</template>

<script>
import { useI18n } from "vue-i18n";
import ObjetiveTableLogsOkr from "./ObjetiveTableLogsOkr.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import useEmitter from "@/core/plugins/emitter.js";
import JwtService from "@/core/services/JwtService";
import ObjectiveNoteForm from "@/components/app/Objectives/ObjectiveNoteForm.vue";

const EMPTY_NOTE = {
  description: "",
  okr: null,
  user: null
};

export default {
  name: "_app_objective_info_modal",
  components: {
    ObjetiveTableLogsOkr,
    ObjectiveNoteForm
  },
  data() {
    return {
      isEditing: false,
      isNoteFormVisible: false,
      currentStatusFilter: null,
      currentObjective: null,
      removingConfirmation: null,
      changeRoleUser: null,
      isRemovingConfirmationOpened: false,
      isWaiting: false,
      note: null,
      notes: [],
      loader: false,
      objective: null
    };
  },
  emits: "on-save",
  computed: {
    authenticatedUser() {
      return this.$store.getters.currentUser;
    }
  },

  watch: {
    "$store.state.ObjectiveModule.selectedObjective": function() {
      this.isNoteFormVisible = false;
      this.objective = this.$store.getters.getSelectedObjective;
      this.loadNotes();
    }
  },

  methods: {
    loadNotes() {
      const organizationId = this.$route.params.id
        ? this.$route.params.id
        : null;

      if (!organizationId) {
        return this.$notify({
          title: this.translate("important"),
          message: this.translate("noOrganizationHere")
        });
      }

      if (!this.objective || !this.objective.id) {
        return;
      }

      const currentToken = JwtService.getToken();
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/notes/` +
        this.objective.id;

      this.loader = true;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 400) {
            throw new Error("generalError");
          }
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(data => {
          this.loader = false;

          const { result: notes } = data;

          if (!notes || !notes.length) {
            this.notes = [];
            return;
          }

          this.notes = [...notes];

          setTimeout(function() {
            //$('[data-bs-toggle="tooltip"]').tooltip();
          }, 1000);
        })
        .catch(error => {
          this.isWaiting = false;
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError")
          });
        });
    },

    startNoteRemoving(note) {
      setTimeout(() => {
        this.note = note; // Visual effect
      }, 150);

      this.isRemovingConfirmationOpened = true;
      this.removingConfirmation = {
        noteDescription: note.description,
        onConfirm: () => {
          this.removeNote(note);
        }
      };
    },

    removeNote(note) {
      const noteId = note.id;
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/notes/${noteId}`;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }
          return res.json();
        })
        .then(() => {
          this.isWaiting = false;
          this.stopNoteRemoving();
          this.loadNotes();
          this.$notify({
            title: this.translate("done"),
            message: this.translate("noteDeletedSuccessfully")
          });
          this.$emit("fetchObjectives");
        })
        .catch(error => {
          this.stopNoteRemoving();
          this.loadNotes();
          this.$notify({
            title: this.translate("bad"),
            message: this.translate("taskRemovingError"),
            type: "danger"
          });
        });
    },

    saveNote(noteToSave) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/notes`;
      const urlAction = noteToSave && noteToSave.id ? "PUT" : "POST";
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: urlAction,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(noteToSave)
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }
          return res.json();
        })
        .then(() => {
          if (urlAction === "POST") {
            /* const { result: userData } = data;
            noteToSave.id = userData[0];
            noteToSave.status = 0;
            this.notes.push(noteToSave); */
          }
          this.loadNotes();
          this.$notify({
            title: this.translate("great"),
            message: this.translate("NoteCreatedSuccessfully"),
            color: "success",
            type: "success"
          });
        })
        .catch(error => {
          this.$notify({
            title: this.translate("bad"),
            message: this.translate("noteCreatedError")
          });
        })
        .finally(() => {
          this.isWaiting = false;
          this.isNoteFormVisible = false;
          this.emitter.emit("fetchObjectives");
          this.loadNotes();
          this.$emit("fetchObjectives");
        });
    },

    startNoteForm(note) {
      if (note) this.isEditing = true;
      this.isNoteFormVisible = true;
      setTimeout(() => {
        this.note =
          note && note.id
            ? { ...note }
            : {
                ...EMPTY_NOTE
              };
        this.currentObjective = { ...this.objective };
      }, 333);
    },

    stopNoteForm() {
      this.isNoteFormVisible = false;
      this.isEditing = false;
    },

    stopNoteRemoving() {
      this.note = null;
      this.removingConfirmation = null;
      this.isRemovingConfirmationOpened = false;
    },

    confirmNoteRemoving() {
      if (!this.note || !this.note.id) {
        console.info("[You need note id to continue]");
        return;
      }
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/notes/` +
        this.note.id;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(() => {
          this.stopNoteRemoving();
          this.loadPeriods();
          this.$notify({
            title: this.translate("great"),
            message: this.translate("noteDeletedSuccessfully"),
            type: "success"
          });
        })
        .catch(error => {
          this.isWaiting = false;
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError"),
            type: "danger"
          });
        });
    }
  },
  setup(_, { emit }) {
    const store = useStore();
    const emitter = useEmitter();
    // const percentage = computed(() => store.getters.getPercentage);
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };
    const selectedObjective = computed(() => {
      const selected = store.getters.getSelectedObjective;
      if (selected != null) {
        return selected;
      }

      return "----";
    });

    const openTaskManagerModal2 = selectedObjective => {
      const modalElement = document.getElementById("_app_objective_info_modal");
      if (modalElement) {
        modalElement.click();
      }

      emitter.emit("open-task-manager-modal", selectedObjective);
    };

    const submit2 = () => {
      const update = {
        id: selectedObjective.value.id,
        currentValue: parseFloat(selectedObjective.value.current_value).toFixed(
          1
        ),
        startValue: parseFloat(selectedObjective.value.start_value).toFixed(1),
        targetValue: parseFloat(selectedObjective.value.target_value).toFixed(
          1
        ),
        type: selectedObjective.value.type
      };

      emit("on-save", update);
    };

    return {
      selectedObjective,
      translate,
      submit2,
      openTaskManagerModal2
    };
  }
};
</script>

<style>
.table.gy-7 th,
.table.gy-7 td {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.description {
  white-space: pre-line;
}

#kt_accordion_1_header_2 .accordion-button:after {
  flex-shrink: 0;
  width: 1.15rem;
  height: 1.15rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-size: 1.15rem;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  right: 15px;
}

.accordion.deep-accordion {
  background-color: #f9f9f9;
}

.accordion.deep-accordion > .dragArea {
  background-color: #f9f9f9;
}

#edit-val .form-control.form-control-solid {
  background-color: #fff;
  border-color: #cecece;
  text-align: center;
}

#edit-val input[type="number"]::-webkit-inner-spin-button,
#edit-val input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

a {
  cursor: pointer;
}
</style>
