import { Objective } from '../components/app/Objectives/types';

type OKR = {
    count: number;
    empty: number;
    editedAt: string;
};


const mapToObjective = (data: any): Objective => {
    return {
        ...data,
        editedAt: data.editedAt ? data.editedAt : data.edited_at,
        subObjectives: data.subObjectives.map(mapToObjective)
    };
};

const objectiveQuantity = (obj: Objective[], okr: OKR): void => {
    obj.forEach(element => {
        okr.editedAt = new Date(element.editedAt) > new Date(okr.editedAt) ? element.editedAt : okr.editedAt;

        if (element.type === "objective") {
            okr.count++;
            if (element.subObjectives && element.subObjectives.length) {
                objectiveQuantity(element.subObjectives, okr);
            } else {
                okr.empty++;
            }
        }
    });
};

export const obtenerPlanificado = (receivedObjectives: any[]): number => {

    const objectives = receivedObjectives.map(mapToObjective);

    const okr: OKR = {
        count: 0,
        empty: 0,
        editedAt: "1922-06-20T03:00:00.000Z"
    };

    objectiveQuantity(objectives, okr);

    return okr.count === 0 ? 0 : Math.min(100, Math.round((1 - okr.empty / okr.count) * 100));
};