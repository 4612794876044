<template>
  <div style="display: flex">
    <div>
      <button
        type="button"
        :class="[
          expand || activeAccordionGroup
            ? 'accordion-button show'
            : 'accordion-button collapsed',
        ]"
        :style="{
          visibility: element && element.subObjectives.length ? '' : 'hidden',
        }"
        data-bs-toggle="collapse"
        :id="'idpadre' + element.id"
        :data-bs-target="'#collapse' + element.id"
        aria-expanded="false"
        :aria-controls="'collapse' + element.id"
        @click="saveCookies('collapse' + element.id)"
      />
    </div>
    <div>
      <div :style="{ display: 'flex' }">
        <div>
          <span
            class="badge rounded-pill"
            :class="
              element.relativeAdvance <= 40
                ? 'bg-danger'
                : element.relativeAdvance < 70
                ? 'bg-warning'
                : 'bg-success'
            "
            :style="{width: '3.1rem', background: isBlue(element) ? '#597dfe !important' : '' }"
          >
            {{
              element.parent || element.type === "key_result"
                ? element.type === "key_result"
                  ? "KR"
                  : "O"
                : "OMI"
            }}
          </span>
          <div
            v-if="element.task"
            class="my-badge-container"
          >
            <span
              class="badge rounded-pill my-badge"
            >
              {{ element.task }}
            </span>
          </div>

          <span
            v-if="element.shared.length && !isTypeUser"
            class="btn-info-compartido"
            data-bs-toggle="modal"
            data-bs-target="#_app_shared_modal"
            @click="setSelectedObjective(element)"
          >
            <i class="bi bi-people-fill"></i>
          </span>

          <span
            v-if="element.sharedWithMe.length && !isTypeUser"
            class="btn-info-compartido-with-me"
            data-bs-toggle="modal"
            data-bs-target="#_app_shared_modal"
            @click="setSelectedObjective(element)"
          >
            <i class="bi bi-people-fill"></i>
          </span>
        </div>
        <span
          class="element-title"
          id="kt_objective_toggle"
          @click="setSelectedObjective(element)"
        >
          <span
            :id="'title-' + element.id"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="element.title"
          >
            {{ element.title }}
          </span>
        </span>
      </div>
      <h6 class="m-0 mt-3 d-block accordion-title-details">
        <small class="text-thin" :id="'user-' + element.id"
          ><i class="bi bi-person-fill me-2"></i
          >{{
            element && element.owner
              ? element.user.name + " " + element.user.lastname
              : "-"
          }}</small
        >
      </h6>
    </div>
  </div>
  <div style="display: flex; margin-left: 2px" class="percentage-container">
    <PercentSection :element="normalizedElement" />
  </div>
</template>

<script lang="js">
import { useI18n } from "vue-i18n";
import useEmitter from "@/core/plugins/emitter.js";
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import PercentSection from "./PercentageSection"

export default {
  name: "ObjDescription",

  components: {
    PercentSection
  },

  props: {
    element: {
      type: Object,
    },
    isTypeUser: {
      type: Boolean,
    },
    expand: {
      type: Boolean,
    },
    SelectedUser: {
      SelectedUser: Boolean,
    },
    activeAccordionGroup: {
      activeAccordionGroup: Boolean,
    }
  },

  setup(props) {
    const emitter = useEmitter();
    const store = useStore();
    const { t, te } = useI18n();

    const selecTedUser = computed(() => store.getters.getSelectedUser);
    const authenticatedUser = computed(() => store.getters.currentUser);
    const changedObjectElement = computed(
      () => store.getters.getChangedObjectElement
    );

    const normalizedElement = computed(() => {
    return {
      ...props.element,
      currentValue: props.element.current_value || props.element.currentValue,
      targetValue: props.element.target_value || props.element.targetValue
    };
  });

    const translate = (text) => {
      return te(text) ? t(text) : text;
    };

    const setSelectedObjective = (objective) => {
      store.dispatch(Actions.CHANGE_SELECTED_OBJECTIVE, objective);
    };

    const openTaskManagerModal = (objective) => {
      emitter.emit("open-task-manager-modal", objective);
    };

    const openObjectiveSharerModal = (objective) => {
      emitter.emit("open-objective-sharer-modal", objective);
    };

    const isBlue = (element) => {
      if (element.type === "key_result" &&
        element.frequency === "single" &&
        new Date(element.differentiatedStart).getTime() > new Date().getTime()) {
        return true;
      }
      return false;
    };

    const saveCookies = (id) => {
      const openAccordion = JSON.parse(
        localStorage.getItem("activeAccordionGroup")
      );
      if (openAccordion) {
        openAccordion.includes(id)
          ? openAccordion.splice(openAccordion.indexOf(id), 1)
          : openAccordion.push(id);
        localStorage.setItem(
          "activeAccordionGroup",
          JSON.stringify(openAccordion)
        );
      } else {
        localStorage.setItem("activeAccordionGroup", JSON.stringify([id]));
      }
    }

    function highlightElement(property) {
      const element = document.getElementById(`${property}-${changedObjectElement.value.id}`);
      if (element) {
        element.classList.add("highlight-property");
        setTimeout(() => {
          element.classList.remove("highlight-property");
        }, 20000);
      }
    }

    watch(
      () => store.getters.getChangedObjectBool,
      () => {
        const changedObject = changedObjectElement.value;
        if (changedObject) {
          changedObject.diff.forEach(element => {
            if (element.key === 'title') highlightElement("title")
            if (element.key === 'user') highlightElement("user")
            if (element.key === 'advance') highlightElement("advance")
            if (element.key === 'current_value') highlightElement("current_value")
          });
        }

      }
    );

    return {
      translate,
      isBlue,
      selecTedUser,
      authenticatedUser,
      setSelectedObjective,
      openTaskManagerModal,
      openObjectiveSharerModal,
      saveCookies,
      normalizedElement
    };
  },
};
</script>

<style scoped lang="scss">
.highlight-property {
  animation-name: highlight;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes highlight {
  from {
    background-color: rgb(68, 255, 0);
  }

  to {
    background-color: transparent;
  }
}

.my-badge-container {
  position: relative;
}

.my-badge {
  font-size: 8px;
  left: 2.8rem;
  top: -2.2rem;
  margin-left: 3px;
  position: absolute;
  background-color: #f1416c;
  color: white;
}

.btn.btn-icon.btn-task span.badge.rounded-pill.bg-danger {
  position: absolute;
  right: -10px;
  font-size: 9px;
  color: #fff;
  border-radius: 10px;
}

.btn.btn-icon img.icon-disabled {
  width: 30px;
}

.accordion-button {
  border: none;
  width: 57px;
  background: white;

  &::after {
    transform: rotate(-90deg);
  }

  &:not(.collapsed) {
    background: white;
    box-shadow: none;

    &::after {
      transform: initial;
    }
  }
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.15rem;
  height: 1.15rem;
  margin-left: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23181C32'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.15rem;
  transition: transform 0.2s ease-in-out;
}

.element-title {
  margin-left: 20px;
  &:hover {
    color: #597dfe;
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .percentage-container {
    flex-direction: column;
  }

  .percentage-2 {
    padding: 0 !important;
  }
}
</style>
