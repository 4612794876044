<template>
  <!--begin::Form-->
  <Form id="kt_invitation_form" class="form" @submit="save">
    <!--begin::Input group-->
    <div class="row mb-12">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col Name-->
          <div class="col-12">
            <label
              class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            >
              <span class="required">{{ translate("title") }}</span>
              <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="Name"
              ></i>
            </label>

            <Field
              v-model="form.title"
              :disabled="isWaiting"
              autofocus
              type="text"
              class="form-control form-control-solid"
              placeholder=""
              name="title"
            />

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="title" />
              </div>
            </div>
          </div>
          <!--end::Col Name-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="d-flex flex-column mb-10">
      <label class="fs-6 fw-bold mb-2">{{ translate("description") }}</label>
      <textarea
        :disabled="isWaiting"
        :placeholder="translate('description')"
        v-model="form.description"
        class="form-control form-control-solid"
        name="description"
      />
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row mb-15">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col Start At-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="fs-6 fw-bold form-label mb-2">{{
              translate("startAt")
            }}</label>
            <!--end::Label-->

            <el-date-picker
              v-model="form.startAt"
              type="date"
              :disabled-date="datePickerStartAtOptions"
              :placeholder="translate('startAt')"
            >
            </el-date-picker>

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="expirationYear" />
              </div>
            </div>
          </div>
          <!--end::Col Start At-->

          <!--begin::Col End At-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="fs-6 fw-bold form-label mb-2">{{
              translate("endAt")
            }}</label>
            <!--end::Label-->

            <el-date-picker
              v-model="form.endAt"
              type="date"
              :disabled-date="datePickerEndAtOptions"
              :placeholder="translate('endAt')"
            >
            </el-date-picker>

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="expirationYear" />
              </div>
            </div>
          </div>
          <!--end::Col End At-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Actions-->
    <div class="text-center mt-15">
      <button
        ref="cancelButtonRef"
        :disabled="isWaiting"
        type="reset"
        class="btn btn-light me-3"
        @click.prevent="cancel"
      >
        <span class="indicator-label">
          {{ translate("cancel") }}
        </span>
      </button>

      <button
        ref="submitButtonRef"
        :disabled="isWaiting"
        type="submit"
        id="kt_modal_new_card_submit"
        class="btn btn-primary"
      >
        <span class="indicator-label">
          {{ isWaiting ? translate("sending") : translate("send") }}
        </span>
        <span class="indicator-progress">
          {{ translate("pleaseWait") }}
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
    <!--end::Actions-->
  </Form>
  <!--end::Form-->
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";

const EMPTY_TASK = {
  title: "",
  description: "",
  date: "",
  startAt: "",
  endAt: "",
  okr: null,
  user: null
};

export default defineComponent({
  name: "_app_objective-task_form",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  props: {
    task: {
      type: Object,
      default: () => {
        return { ...EMPTY_TASK };
      }
    },
    objective: {
      type: Object,
      default: () => null
    },
    isWaiting: {
      type: Boolean,
      default: () => false
    }
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  },
  data() {
    return {
      form: { ...EMPTY_TASK },
      localObjective: null,
      datePickerStartAt: new Date()
    };
  },
  computed: {
    authenticatedUser() {
      return this.$store.getters.currentUser;
    }
  },
  watch: {
    task(taskValue) {
      this.form = taskValue ? taskValue : { ...EMPTY_TASK };

      if (this.form.start_at) {
        this.form.startAt = this.form.start_at;
      }
      if (this.form.end_at) {
        this.form.endAt = this.form.end_at;
      }
    },
    objective(objectiveValue) {
      this.localObjective = objectiveValue;
    }
  },
  methods: {
    save() {
      this.form.user =
        this.authenticatedUser && this.authenticatedUser.id
          ? this.authenticatedUser.id
          : null;
      this.form.okr =
        this.localObjective && this.localObjective.id
          ? this.localObjective.id
          : null;
      this.$emit("onSave", { ...this.form });
    },
    datePickerStartAtOptions() {
      this.datePickerStartAt = this.form.startAt;
      if (new Date(this.form.startAt) > new Date(this.form.endAt))
        this.form.endAt = "";
      return false;
    },
    datePickerEndAtOptions(date) {
      return date < this.datePickerStartAt;
    },
    cancel() {
      this.$emit("onCancel");
    }
  }
});
</script>
