<template>
  <!--begin::Modal - View Members-->
  <div
    class="modal fade"
    id="_app_objective-sharer_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
          <!--begin::Heading-->
          <div class="text-center mb-13">
            <!--begin::Title-->
            <h1
              class="mb-3"
              :class="{
                'text-danger': cancellationConfirmation !== null
              }"
            >
              {{ translate("objectiveShare") }}
            </h1>
            <!--end::Title-->

            <!--begin::Description-->
            <div class="text-gray-400 fw-bold fs-5">
              <span
                :class="{
                  'text-danger': cancellationConfirmation !== null
                }"
              >
                {{
                  isWaiting
                    ? translate("pleaseWait")
                    : translate("objectiveInvitationIntro")
                }}</span
              >
            </div>
            <!--end::Description-->
          </div>
          <!--end::Heading-->

          <!-- begin:: Sending objective confirmation -->
          <div v-if="sendingObjectiveConfirmation" class="text-center">
            <h2 v-html="sendingObjectiveConfirmation.title" class="mb-9" />

            <p
              class="sending-message mb-12"
              v-html="sendingObjectiveConfirmation.message"
            />

            <div class="mt-3 text-center">
              <button
                ref="cancelButtonRef"
                :disabled="isWaiting"
                type="reset"
                class="btn btn-light me-3"
                @click.prevent="clearSelectedOrganization"
              >
                <span class="indicator-label">
                  {{ translate("cancel") }}
                </span>
              </button>

              <button
                ref="submitButtonRef"
                :disabled="isWaiting"
                type="submit"
                class="btn btn-primary"
                @click="sendingObjectiveConfirmation.onClick()"
              >
                <span class="indicator-label">
                  {{
                    isWaiting ? translate("cancelling") : translate("confirm")
                  }}
                </span>
                <span class="indicator-progress">
                  {{ translate("pleaseWait") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
          </div>
          <!-- end:: Sending objective confirmation -->

          <!-- begin:: Cancellation confirmation -->
          <div v-if="cancellationConfirmation" class="text-center">
            <h2 v-text="cancellationConfirmation.invitedName" class="mb-12" />
            <div class="mt-3 text-center">
              <button
                ref="cancelButtonRef"
                :disabled="isWaiting"
                type="reset"
                class="btn btn-light me-3"
                @click.prevent="stopInvitationCancellation"
              >
                <span class="indicator-label">
                  {{ translate("cancel") }}
                </span>
              </button>

              <button
                ref="submitButtonRef"
                :disabled="isWaiting"
                type="submit"
                class="btn btn-primary"
                @click="cancellationConfirmation.onClick()"
              >
                <span class="indicator-label">
                  {{
                    isWaiting ? translate("cancelling") : translate("confirm")
                  }}
                </span>
                <span class="indicator-progress">
                  {{ translate("pleaseWait") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
          </div>
          <!-- end:: Cancellation confirmation -->

          <!-- begin:: Organizations searcher -->
          <div
            v-if="!sendingObjectiveConfirmation && !cancellationConfirmation"
            class="invitations-searcher mb-12"
          >
            <form @submit.prevent="searchOrganizations" action="/members">
              <div class="input-group">
                <button
                  v-if="organizations && organizations.length"
                  class="btn btn-dark"
                  type="button"
                  id="close-organizations-searcher-button"
                  @click="clearOrganizations"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
                <input
                  v-model="searcherForm.email"
                  :disabled="isWaiting"
                  :placeholder="translate('organizationSearcher')"
                  :aria-label="translate('organizationSearchDescription')"
                  type="text"
                  class="form-control"
                  aria-describedby="invitation-searcher-button"
                />
                <button
                  :disabled="organizations && organizations.length"
                  class="btn btn-dark"
                  type="submit"
                  id="invitation-searcher-button"
                >
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </form>
          </div>
          <!-- end:: Organizations searcher -->

          <!--begin::Organization List Wrapper -->
          <div
            v-if="!sendingObjectiveConfirmation && !cancellationConfirmation"
            class="mb-15"
          >
            <!--begin::List-->
            <div
              v-if="localOrganizations && localOrganizations.length"
              class="scroll-y ps-0 organization-list"
            >
              <template
                v-for="(organization, index) in localOrganizations"
                :key="index"
              >
                <!--begin::Organization list item-->
                <div
                  class="
                    d-flex
                    flex-stack
                    py-5
                    border-bottom border-gray-300 border-bottom-dashed
                  "
                >
                  <!--begin::Details-->
                  <div class="d-flex align-items-center">
                    <div class="ms-3">
                      <!--begin::Name-->
                      <span
                        class="
                          d-flex
                          align-items-center
                          fs-5
                          fw-bolder
                          text-dark
                        "
                      >
                        {{
                          organization && organization.name
                            ? organization.name
                            : "-"
                        }}
                      </span>
                      <!--end::Name-->
                    </div>
                  </div>
                  <!--end::Details-->

                  <!--begin::Stats-->
                  <div class="d-flex">
                    <!--begin::Sales-->
                    <div class="text-end"></div>
                    <!--end::Sales-->
                  </div>
                  <!--end::Stats-->

                  <!--begin::Actions-->
                  <div class="d-flex">
                    <div class="text-end">
                      <span
                        v-if="false"
                        class="badge fs-8 fw-bold ms-2"
                        :class="{
                          'badge-warning':
                            'pending' === invitationStatusOptions._PENDING,
                          'badge-success':
                            '' === invitationStatusOptions._ACCEPTED,
                          'badge-danger':
                            '' === invitationStatusOptions._REJECTED
                        }"
                      >
                        {{ translate("pending") }}
                      </span>

                      <div v-else class="fs-5 fw-bolder text-dark">
                        <!--begin:: Select button-->
                        <button
                          @click="selectOrganization(organization)"
                          class="btn btn-sm btn-dark btn-icon"
                        >
                          <i class="bi bi-arrow-right" />
                        </button>
                        <!--end:: Select button-->
                      </div>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Organization list item-->
              </template>
            </div>

            <!-- Waiting message -->
            <div v-else-if="waitingMessage" class="p-6 text-center text-muted">
              {{ waitingMessage }}
            </div>

            <!-- Empty results -->
            <div v-else-if="false" class="p-6 text-center">
              {{ translate("emptyResults") }}
            </div>
            <!--end::List-->
          </div>
          <!--end::Organization List Wrapper-->

          <!-- begin:: My Objective Share List -->
          <div v-if="false" class="mb-15">
            <pre>
              {{ myShares }}
            </pre>
          </div>
          <!-- end:: My Objective Share List -->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - View Members-->
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

const INVITATION_STATUS_OPTIONS = {
  _PENDING: "pending",
  _ACCEPTED: "accepted",
  _REJECTED: "rejected"
};

export default defineComponent({
  name: "_app_objective-sharer_modal",
  props: {
    organizations: {
      type: Array,
      default: () => []
    },
    myShares: {
      type: Array,
      default: () => []
    },
    waitingMessage: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      currentStatusFilter: null,
      invitationStatusOptions: INVITATION_STATUS_OPTIONS,
      currentObjective: null,
      cancellationConfirmation: null,
      localOriginalOrganizations: [],
      localOrganizations: [],
      selectedOrganization: null,
      sendingObjectiveConfirmation: null,
      doneTasks: [],
      searcherForm: {
        email: ""
      },
      objective: null
    };
  },
  computed: {
    authenticatedUser() {
      return this.$store.getters.currentUser;
    },
    currentStatusFilterText() {
      switch (this.currentStatusFilter) {
        case INVITATION_STATUS_OPTIONS._PENDING:
          return this.$t(INVITATION_STATUS_OPTIONS._PENDING);
        case INVITATION_STATUS_OPTIONS._ACCEPTED:
          return this.$t(INVITATION_STATUS_OPTIONS._ACCEPTED);
        case INVITATION_STATUS_OPTIONS._REJECTED:
          return this.$t(INVITATION_STATUS_OPTIONS._REJECTED);
        default:
          return "";
      }
    },
    currentStatusFilterIntro() {
      switch (this.currentStatusFilter) {
        case INVITATION_STATUS_OPTIONS._PENDING:
          return this.translate("youAreSeeingOnlyPendingStatus");
        case INVITATION_STATUS_OPTIONS._ACCEPTED:
          return this.translate("youAreSeeingOnlyAcceptedStatus");
        case INVITATION_STATUS_OPTIONS._REJECTED:
          return this.translate("youAreSeeingOnlyRejectedStatus");
        default:
          return "";
      }
    },
    isWaiting() {
      return this.waitingMessage !== "";
    }
  },
  watch: {
    "$store.state.ObjectiveModule.selectedObjective": function() {
      const selectedObjective = this.$store.getters.getSelectedObjective;
      this.currentObjective = selectedObjective;
      this.objective = selectedObjective;
    },
    organizations(organizationsValue) {
      this.localOrganizations =
        organizationsValue && organizationsValue.length
          ? organizationsValue
          : [];
    }
  },
  methods: {
    startInvitationCancellation(invitation) {
      this.cancellationConfirmation = {
        invitedName: invitation.name,
        onClick: () => {
          this.cancelInvitation(invitation);
        }
      };
    },
    cancelInvitation(invitation) {
      this.$emit("onCancelInvitation", invitation);
    },
    stopInvitationCancellation() {
      this.cancellationConfirmation = null;
    },
    searchOrganizations() {
      const { email } = this.searcherForm;
      const objectiveId =
        this.objective && this.objective.id ? this.objective.id : null;

      if (!objectiveId) {
        return;
      }

      this.$emit("onSearchOrganizations", {
        email: email,
        objectiveId: objectiveId
      });
    },
    selectOrganization(organization) {
      this.selectedOrganization = organization;
      this.sendingObjectiveConfirmation = {
        title: this.translate("areYouSure"),
        message:
          this.translate("youAreAboutOfSendThisInvitation") +
          " <b>" +
          organization.name +
          "</b>",
        onClick: () => {
          this.$emit("onSelectOrganization", {
            organizationId: organization.id,
            objectiveId:
              this.objective && this.objective.id ? this.objective.id : null
          });
          this.clearSelectedOrganization();
          this.$emit("fetchObjectives");
        }
      };
    },
    clearOrganizations() {
      this.$emit("onClearOrganizations");
    },
    clearSelectedOrganization() {
      this.selectedOrganization = null;
      this.sendingObjectiveConfirmation = null;
    }
  },
  setup() {
    const { t, te } = useI18n();

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  }
});
</script>

<style scoped lang="scss">
.clickeable {
  cursor: pointer;
}

.applied-filter {
  border: solid 1px #c9c9c9;
  border-radius: 9px;
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
  padding: 27px;
}

.organization-list {
  border-radius: 6px;
}
</style>
