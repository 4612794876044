<template>
  <!--begin::Modal - View Users-->
  <div
    class="modal fade"
    id="_app_cycle-manager_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 pt-0 pb-15">
          <!--begin::Heading-->
          <div class="text-center mb-9">
            <!--begin::Title-->
            <h1 class="mb-3">{{ translate("cycleManager") }}</h1>
            <!--end::Title-->

            <!--begin::Description-->
            <div class="text-gray-400 fw-bold fs-5">
              {{ translate("manageCycle") }}
            </div>
            <!--end::Description-->
          </div>
          <!--end::Heading-->

          <!-- begin:: Cycle removing confirmation -->
          <div
            v-if="isRemovingConfirmationOpened"
            class="cycle-removing-confirmation"
          >
            <!-- begin:: Cycle removing text -->
            <div class="p-3 text-center cycle-removing-confirmation-text">
              <h2 v-if="cycle" v-text="cycle.name" class="mb-9 text-danger" />
              <h4 v-text="translate('cycleRemovingConfirmation')" />
            </div>
            <!-- end:: Cycle removing text -->

            <!-- begin:: Cycle removing actions -->
            <div class="cycle-removing-confirmation-actions mt-9 text-center">
              <button
                ref="cancelButtonRef"
                type="reset"
                class="btn btn-light me-3"
                @click.prevent="stopCycleRemoving()"
              >
                <span class="indicator-label">
                  {{ translate("cancel") }}
                </span>
              </button>

              <button
                ref="submitButtonRef"
                type="button"
                id="kt_modal_new_card_submit"
                class="btn btn-danger"
                @click="confirmCycleRemoving"
              >
                <span class="indicator-label">
                  {{ translate("confirm") }}
                </span>
                <span class="indicator-progress">
                  {{ translate("pleaseWait") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!-- end:: Cycle removing actions -->
          </div>
          <!-- end:: Cycle removing confirmation -->

          <!-- begin:: Cycle table -->
          <div v-else-if="!isFormOpened">
            <!-- begin:: Cycle -->
            <div class="mb-15">
              <!--begin::List-->
              <div class="mh-375px scroll-y me-n7 pe-7">
                <div class="table-responsive">
                  <table
                    class="table table-rounded table-striped border gy-7 gs-7 text-center"
                  >
                    <thead>
                      <tr>
                        <th>{{ translate("name") }}</th>
                        <th>{{ translate("status") }}</th>
                        <th>{{ translate("startAt") }}</th>
                        <th>{{ translate("endAt") }}</th>
                        <th>{{ translate("action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(cycle, index) in getPeriod" :key="index">
                        <td>
                          <b>
                            <a
                              href="#"
                              @click.prevent="changeCycle(cycle)"
                              data-bs-dismiss="modal"
                              class="d-flex align-items-center fs-5 fw-bolder text-dark text-hover-primary"
                            >
                              {{ cycle.name }}
                            </a>
                          </b>
                        </td>
                        <td>
                          <span v-if="cycle.status === 'finished'">{{
                            translate("finished")
                          }}</span>
                          <span v-if="cycle.status === 'planning'">{{
                            translate("planning")
                          }}</span>
                          <span v-if="cycle.status === 'running'">{{
                            translate("running")
                          }}</span>
                        </td>
                        <td>{{ formatDateCycle(cycle.start_at) }}</td>
                        <td>{{ formatDateCycle(cycle.end_at) }}</td>
                        <td class="btn-action">
                          <!--begin:: Edit button-->
                          <button
                            @click="startCycleForm(cycle)"
                            class="btn btn-sm btn-dark btn-icon"
                          >
                            <i class="bi bi-pencil" />
                          </button>
                          <!--end:: Edit button-->

                          <!--begin:: Remove button-->
                          <button
                            @click="startCycleRemoving(cycle)"
                            class="btn btn-sm btn-danger btn-icon ms-2"
                          >
                            <i class="bi bi-trash" />
                          </button>
                          <!--end:: Remove button-->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!--<template v-for="(cycle, index) in getPeriod" :key="index">
                  <div
                    class="
                      d-flex
                      flex-stack
                      py-5
                      border-bottom border-gray-300 border-bottom-dashed
                    "
                  >
                    <div class="d-flex align-items-center">
                      <div class="ms-6">
                        <a
                          href="#"
                          @click.prevent="changeCycle(cycle)"
                          data-bs-dismiss="modal"
                          class="
                            d-flex
                            align-items-center
                            fs-5
                            fw-bolder
                            text-dark text-hover-primary
                          "
                        >
                          {{ cycle.name }}
                        </a>
                        

                        
                        <div
                          v-if="cycle.description"
                          class="fw-bold text-gray-400"
                        >
                          {{ cycle.description }}
                        </div>
                    
                      </div>
                      
                    </div>
                    
                    <div class="d-flex">
                      <div class="text-end">
                        <div class="fs-5 fw-bolder text-dark">
                        
                          <button
                            @click="startCycleForm(cycle)"
                            class="btn btn-sm btn-dark btn-icon"
                          >
                            <i class="bi bi-pencil" />
                          </button>
                          <button
                            @click="startCycleRemoving(cycle)"
                            class="btn btn-sm btn-danger btn-icon ms-2"
                          >
                            <i class="bi bi-trash" />
                          </button>
                        </div>
                      </div>
                    </div>
            
                  </div>
                </template> -->
              </div>
              <!--end::List-->
            </div>
            <!-- end:: Cycle -->

            <!--begin::Adder Notice-->
            <div class="text-center">
              <!--begin::Button-->
              <button
                @click="startCycleForm"
                class="btn btn-round btn-success ps-4"
              >
                <i class="bi bi-plus me-1" />
                {{ translate("createCycle") }}
              </button>
              <!--end::Button-->
            </div>
            <!--end::Adder Notice-->
          </div>
          <!-- begin:: Cycle table -->

          <div v-else>
            <CycleForm
              :cycle="cycle"
              @onSave="saveCycle"
              @onCancel="stopCycleForm()"
            />
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - View Users-->
</template>

<script>
import { defineComponent } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";

import CycleForm from "@/components/app/Organizations/CycleForm.vue";

export default defineComponent({
  name: "_app_cycle-manager_modal",
  components: { CycleForm },
  props: {
    organization: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isWaiting: false,
      isFormOpened: false,
      isRemovingConfirmationOpened: false,
      cycle: null,
    };
  },
  computed: {
    getPeriod() {
      const periods = this.$store.getters.getAllCycles;
      if (periods) {
        return periods;
      } else {
        return [];
      }
    },

    authenticatedUser() {
      return this.$store.getters.currentUser;
    },
  },

  watch: {
    "$store.state.ObjectiveModule.isModalOpen": function () {
      this.isFormOpened = false;
    },
  },

  methods: {
    changeCycle(Cycle) {
      this.$store.dispatch(Actions.CHANGE_CYCLE, Cycle);
      localStorage.setItem("currentCycle", JSON.stringify(Cycle));
      this.$emit("fetchObjectives");
    },

    startCycleForm(cycle) {
      this.isFormOpened = true;
      const cycleData =
        !cycle || !cycle.id
          ? {
              id: null,
              name: "",
              status: "planning",
              organization: null,
              startAt: "",
              endAt: "",
            }
          : cycle;

      setTimeout(() => {
        this.cycle = cycleData; // Visual effect
      }, 150);
    },

    stopCycleForm() {
      this.cycle = null;
      this.isFormOpened = false;
    },

    startCycleRemoving(cycle) {
      this.isRemovingConfirmationOpened = true;

      setTimeout(() => {
        this.cycle = cycle; // Visual effect
      }, 150);
    },

    stopCycleRemoving() {
      this.cycle = null;
      this.isRemovingConfirmationOpened = false;
    },

    confirmCycleRemoving() {
      if (!this.cycle || !this.cycle.id) {
        console.info("[You need cycle id to continue]");
        return;
      }
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/periods/` +
        this.cycle.id;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken,
        },
      })
        .then((res) => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(() => {
          this.stopCycleRemoving();
          /* this.loadPeriods(); */
          this.$store.dispatch(Actions.FETCH_PERIOD);
          this.$notify({
            title: this.translate("good"),
            message: this.translate("cycleDelete"),
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError"),
            type: "danger",
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    },

    saveCycle(cycle) {
      console.log(`cycle`, cycle);
      this.$store.dispatch(Actions.SAVE_PERIOD, cycle).then(() => {
        this.stopCycleForm();
      });
    },
  },

  setup() {
    const { t, te } = useI18n();
    const formatDate = (date) => {
      return new Date(date).toISOString();
    };

    const checkIn = (fechaInicio, fechaFin) => {
      const fechaInicioMs = new Date(fechaInicio);
      const fechaFinMs = new Date(fechaFin);
      const fechaValidarMs = new Date();

      if (fechaValidarMs >= fechaInicioMs && fechaValidarMs <= fechaFinMs) {
        return "running";
      } else {
        if (fechaValidarMs > fechaFinMs) {
          return "finished";
        } else {
          return "planning";
        }
      }
    };

    const padTo2Digits = (num) => {
      return num.toString().padStart(2, "0");
    };

    const formatDateCycle = (value) => {
      const date = new Date(value);
      if (value) {
        return [
          date.getFullYear(),
          padTo2Digits(date.getMonth() + 1),
          padTo2Digits(date.getDate()),
        ].join("-");
      }
    };

    const translate = (text) => {
      return te(text) ? t(text) : text;
    };

    return {
      translate,
      formatDate,
      checkIn,
      formatDateCycle,
    };
  },
});
</script>

<style>
.btn-action {
  display: flex;
  padding: 9px !important;
  margin: 0;
  justify-content: center;
  align-items: center;
}
</style>
