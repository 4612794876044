<template>
  <div
    id="kt_accordion_1_body_2"
    class="accordion-collapse collapse"
    aria-labelledby="kt_accordion_1_header_2"
    data-bs-parent="#kt_accordion_1"
  >
    <div class="accordion-body" v-if="advances">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr class="fw-bolder fs-6 text-gray-800">
              <th>{{ translate("user") }}</th>
              <th>{{ translate("advance") }}</th>
              <th>{{ translate("date") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(advance, index) of advances" :key="index">
              <td>{{ advance.lastname }}, {{ advance.name }}</td>
              <td>
                {{ advance.advance }}
              </td>
              <td>{{ format_date(advance.logged_at) }} Hs.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import JwtService from "@/core/services/JwtService";
import { useI18n } from "vue-i18n";

export default {
  props: ["objectiveId"],
  setup(props) {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };
    const advances = ref(null);

    const padTo2Digits = num => {
      return num.toString().padStart(2, "0");
    };

    const format_date = value => {
      const date = new Date(value);
      if (value) {
        return (
          [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate())
          ].join("-") +
          " " +
          [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(
            ":"
          )
        );
      }
    };

    function fetchData() {
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okr-logs/` +
        props.objectiveId;
      const currentToken = JwtService.getToken();

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => res.json())
        .then(data => {
          if (data.logs != null) {
            //return data.logs;
            advances.value = data.logs;
          }
        })
        .catch(() => {
          console.log("Error");
        });
    }

    onMounted(() => {
      // fetchData();
      const myModal = document.getElementById("_app_objective_info_modal");

      myModal.addEventListener("shown.bs.modal", function() {
        fetchData();
      });
    });
    return {
      advances,
      translate,
      format_date
    };
  }
};
</script>
