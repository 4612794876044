<template>
  <div class="cont-header">
    <div class="d-flex align-items-center">
      <div class="d-block w-100">
        <div class="d-flex justify-content-between">
          <div class="btn-title">
            <h1 class="fs-3 fw-bold text-white mb-3">
              {{ organization ? organization.name : this.translate("loading") }}
            </h1>
            <!-- begin:: Settings button -->
            <div v-if="!isTypeUser" class="dropdown ms-2">
              <button
                type="button"
                class="btn btn-default btn-controls"
                id="dropdownSettingsButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-placement="bottom"
                style="background: revert; margin-top: 3px !important"
                @click="openModal()"
              >
                {{ translate(`organizationSettings`) }}
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="dropdownSettingsButton"
              >
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#_app_settings_modal"
                    >{{ translate("settings") }}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#_app_member-manager_modal"
                    >{{ translate("members") }}</a
                  >
                </li>
                <li v-if="!isTypeUser">
                  <a
                    class="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#_app_api-key-manager_modal"
                    >{{ translate("API KEY") }}</a
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li v-if="CurrenPeriod">
                  <a target="_blank" class="dropdown-item" :href="getUrl">
                    {{ translate("download") }}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="openUploader"
                    >{{ translate("loadFromFile") }}</a
                  >
                </li>
              </ul>
            </div>
            <!--end:: Settings button-->
          </div>

          <div class="mb-3 avz-progreso">
            <h1 class="fs-5 fw-bold text-white px-4">
              {{
                organization && organization.progress
                  ? translate("absolute") +
                    ": " +
                    parseInt(organization.progress.absolute) +
                    "%"
                  : ""
              }}
            </h1>
            <h1 class="fs-5 fw-bold text-white px-4">
              {{
                organization && organization.progress
                  ? translate("relative") +
                    ": " +
                    parseInt(organization.progress.relative) +
                    "%"
                  : ""
              }}
            </h1>
          </div>
        </div>
        <span class="motive">
          {{
            organization ? organization.motive : this.translate("loading")
          }}</span
        >
      </div>
    </div>
    <!--TOGGLE REPORTS-->
    <OrganizationReport v-if="CurrenPeriod && CurrenPeriod.id" />
    <!--FIN TOGGLE REPORTS-->
  </div>

  <!-- Sección de notificación de ciclo no en curso -->
  <div
    v-if="!hasActualCycle && objectives.length > 0"
    class="alert alert-warning mt-4"
    role="alert"
  >
    <h4 class="alert-heading">{{ translate("cycleNotInSession") }}</h4>
    <p>{{ translate("noCurrentCycleMessage") }}</p>
    <hr />
    <p class="mb-0">{{ translate("createNewCyclePrompt") }}</p>
    <!-- Botón para crear nuevo ciclo -->
    <button
      @click="openModal()"
      type="button"
      class="btn btn-primary mt-3"
      data-bs-toggle="modal"
      data-bs-target="#_app_cycle-manager_modal"
    >
      {{ translate("createNewCycleButton") }}
    </button>
  </div>
  <!-- begin:: Uploader testing -->
  <div v-if="isUploaderVisible" class="uploader-section">
    <h3 class="text-light mb-0 text-left">
      {{ translate("uploaderFor") }}
      <span v-if="organization">{{ organization.name }}</span>
    </h3>
    <Uploader @onClose="closeUploader" />
    <div class="uploader-closer text-center mt-12">
      <!-- begin:: References button -->
      <button
        type="button"
        class="btn btn-dark btn-sm text-light"
        @click="closeUploader"
      >
        <i class="bi bi-x" />
        <span class="text-light">{{ translate("closeUploader") }}</span>
      </button>
      <!-- end:: References button -->
    </div>
  </div>
  <!-- end:: Uploader testing -->
  <div v-if="!isUploaderVisible" class="d-flex flex-wrap mb-6 sticky-container">
    <!-- begin:: Progress bar -->
    <div class="progress-info d-flex flex-grow-1">
      <div class="mt-1 text-light">
        <div class="d-flex bd-highlight align-items-center">
          <!-- Left -->
          <div>
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              @change="changeCycle($event)"
              class="form-select form-select-white form-select-sm fw-bolder btn-controls"
              style="height: 35px"
            >
              <template v-for="Cycle in getCycles" :key="Cycle.id">
                <option
                  :selected="getCurrentCycle.id == Cycle.id"
                  :value="Cycle.id"
                >
                  {{ Cycle.name }} - {{ translate(Cycle.status) }}
                </option>
              </template>
            </select>
          </div>
          <button
            v-if="!isTypeUser"
            class="btn btn-default btn-controls"
            type="button"
            id="cycleManagerStarterButton"
            aria-expanded="false"
            data-bs-toggle="modal"
            data-bs-target="#_app_cycle-manager_modal"
            title="Cambiar ciclo"
            @click="openModal()"
            style="
              background-color: #fff !important;
              margin: -0.5px 7.5px 0 12.5px !important;
              height: 35px;
            "
          >
            {{ translate("AdmCycle") }}
          </button>
          <div>
            <div v-if="isPlanning">
              <div
                style="margin: 0 !important; display: flex; flex-direction: row"
              >
                <b class="ms-2">{{ Planificado }}%</b>
                <span class="ms-1 me-1" />
                <small>
                  {{ translate("Planned") }}
                </small>
                <i
                  v-if="!isTypeUser && !changingPeriodState"
                  class="bi bi-flag ms-2"
                  @click="changeCycleState('running')"
                ></i>
              </div>
            </div>
            <div v-else>
              <div
                class="progress-bar-holder flex-grow-1 bd-highlight pe-6 me-6 adm-period"
              >
                <div
                  style="
                    margin: 0 !important;
                    display: flex;
                    flex-direction: column;
                  "
                >
                  <b>
                    {{
                      organization && organization.progress
                        ? translate("absolute") +
                          ": " +
                          parseInt(organization.progress.absolute) +
                          "%"
                        : ""
                    }}
                  </b>
                  <b>
                    {{
                      organization && organization.progress
                        ? translate("relative") +
                          ": " +
                          parseInt(organization.progress.relative) +
                          "%"
                        : ""
                    }}
                  </b>
                </div>
                <i
                  v-if="!isTypeUser && !changingPeriodState"
                  class="bi bi-flag ms-2"
                  @click="changeCycleState('planning')"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="progress-bar-holder flex-grow-1 bd-highlight pe-6 me-6"
          style="
            padding-top: 5%;
            margin: 0 !important;
            display: flex;
            flex-direction: row;
          "
        >
          <progress-bar
            :progress="getPeriod.percent.toString()"
            color="warning"
            style="margin-top: 6px"
          />
          <b class="ms-2">{{ getPeriod.percent }}%</b>
          <span class="ms-1 me-1" />
          <small>
            {{ translate("elapsed") }}
          </small>
        </div>
      </div>
    </div>
    <div class="mt-1">
      <Controls />
    </div>
    <!-- end:: Progress bar -->
  </div>

  <div
    v-if="
      !isUploaderVisible && objectiveInvitations && objectiveInvitations.length
    "
    class=""
    style="margin-bottom: 35px"
  >
    <div class="accordion" id="kt_accordion_invitation">
      <div class="accordion-item">
        <h2 class="accordion-header" id="kt_accordion_invitation_header_2">
          <button
            class="accordion-button fs-4 fw-bold"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_accordion_invitation_body_2"
            aria-expanded="true"
            aria-controls="kt_accordion_invitation_body_2"
          >
            {{ translate("notification") }}
          </button>
        </h2>
        <div
          id="kt_accordion_invitation_body_2"
          class="accordion-collapse collapse show"
          aria-labelledby="kt_accordion_invitation_header_2"
          data-bs-parent="#kt_accordion_invitation"
        >
          <div class="accordion-body">
            <div class="container">
              <div class="row">
                <div
                  v-for="(objectiveInvitation,
                  objectiveInvitationIndex) in objectiveInvitations"
                  :key="objectiveInvitationIndex"
                  class="mb-3 organization-objectiveInvitation p-5"
                  :class="
                    isRejectingThisObjectiveInvitation(objectiveInvitation)
                      ? 'bg-danger'
                      : `bg-${objectiveInvitation.type}`
                  "
                >
                  <h6 class="mb-0">
                    <span
                      style="text-weight: normal !important"
                      v-text="objectiveInvitation.title"
                    />
                  </h6>

                  <p class="organization-objectiveInvitation-details m-0">
                    <small
                      v-if="
                        !isRejectingThisObjectiveInvitation(objectiveInvitation)
                      "
                      v-text="objectiveInvitation.description"
                    />
                    <small
                      v-else
                      v-text="translate('youAreAboutOfRejectingThisObjective')"
                    />
                  </p>

                  <div
                    class="organization-objectiveInvitation-actions d-inline-block"
                  >
                    <div
                      v-if="
                        !isRejectingThisObjectiveInvitation(objectiveInvitation)
                      "
                    >
                      <button
                        class="btn btn-danger dropdown-toggle btn-sm me-2"
                        style="border: solid 1px #ffffff33"
                        type="button"
                        id="objectiveInvitationAccepterStarterButton"
                        aria-expanded="false"
                        title="Reject objective invitation"
                        @click="
                          startObjectiveInvitationRejecting(objectiveInvitation)
                        "
                      >
                        <i class="bi bi-x" />
                        {{ translate("reject") }}
                      </button>

                      <button
                        class="btn btn-success dropdown-toggle btn-sm"
                        type="button"
                        id="objectiveInvitationRejecterStarterButton"
                        aria-expanded="false"
                        title="Accept objective invitation"
                        data-bs-toggle="modal"
                        data-bs-target="#_app_objective_invitation_form_modal"
                        @click="
                          startObjectiveInvitationAcceptance(
                            objectiveInvitation
                          )
                        "
                      >
                        <i class="bi bi-check-lg" />
                        {{ translate("accept") }}
                      </button>
                    </div>
                    <div v-else>
                      <!-- @todo: Buttons to cancel or confirm rejection -->
                      <button
                        class="btn btn-light btn-icon dropdown-toggle btn-sm me-2"
                        style="border: solid 1px #ffffff33"
                        type="button"
                        id="objectiveInvitationAccepterStarterButton"
                        aria-expanded="false"
                        title="Cancel rejection"
                        @click="cancelObjectiveRejecting"
                      >
                        <i class="bi bi-arrow-left" />
                      </button>

                      <button
                        class="btn btn-dark dropdown-toggle btn-sm"
                        type="button"
                        id="objectiveInvitationRejecterStarterButton"
                        aria-expanded="false"
                        title="Confirm objective rejection"
                        @click="rejectObjectiveInvitation(objectiveInvitation)"
                      >
                        <i class="bi bi-check-lg" />
                        {{ translate("reject") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--</div>-->
  </div>

  <!--begin::Row-->
  <div v-if="!isUploaderVisible" class="row g-6 g-xl-9">
    <!-- begin:: Objective Set -->
    <ObjectiveSet
      :objectiveListSharedByMe="objectiveListSharedByMe"
      @fetchObjectives="loadObjectives()"
      @loadObjectivesSocket="loadObjectivesSocket()"
    />
    <!-- end:: Objective Set -->

    <!-- Modals -->
    <div class="modals-holder">
      <!-- begin:: Settings modal -->
      <SettingsModal
        :organization="organization"
        @onConfirmOrganizationRemoving="confirmOrganizationRemoving"
      />
      <!-- end:: Settings modal -->

      <!-- begin:: Cycle Manager modal -->
      <CycleManagerModal
        :organization="organization"
        @fetchObjectives="loadObjectives()"
      />
      <!-- end:: Cycle Manager modal -->

      <!-- begin:: Group Manager modal -->
      <MemberManagerModal :organization="organization" />
      <!-- end:: Group Manager modal -->

      <!-- begin:: ApiKey Manager modal -->
      <ApiKeyManagerModal :organization="organization" />
      <!-- end:: ApiKey Manager modal -->

      <!-- begin:: References modal -->
      <ReferencesModal />
      <!-- end:: References modal -->

      <!-- begin::  -->
      <ObjectiveInvitationFormModal
        :objective-invitation="objectiveInvitation"
        :waiting-message="waitingMessage"
        @onSend="acceptObjectiveInvitation"
      />
      <!-- end:: Objective Invitation Form Modal -->
    </div>
  </div>

  <div v-if="!isUploaderVisible" class="text-center">
    <!-- begin:: References button -->
    <button
      type="button"
      class="btn btn-dark btn-sm text-light"
      data-bs-toggle="modal"
      data-bs-target="#_app_references_modal"
    >
      <i class="bi bi-book" />
      <span class="text-light">{{ translate("terminology") }}</span>
    </button>
    <!-- end:: References button -->
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import JwtService from "@/core/services/JwtService";

import useEmitter from "@/core/plugins/emitter.js";

import ProgressBar from "@/components/app/ui/ProgressBar.vue";
import ObjectiveSet from "@/components/app/Objectives/ObjectiveSet.vue";
import SettingsModal from "@/components/app/Organizations/SettingsModal.vue";
import CycleManagerModal from "@/components/app/Organizations/CycleManagerModal.vue";
import MemberManagerModal from "@/components/app/Organizations/MemberManagerModal.vue";
import ApiKeyManagerModal from "@/components/app/Organizations/ApiKeyManagerModal.vue";
import ObjectiveInvitationFormModal from "@/components/app/Objectives/ObjectiveInvitationFormModal.vue";
import Controls from "./Controls.vue";
import ReferencesModal from "@/components/app/Organizations/ReferencesModal.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Uploader from "@/components/app/ui/Uploader.vue";
import OrganizationReport from "@/components/app/Organizations/OrganizationReport.vue";
import mautic from "@/core/plugins/mautic";
import { sendMautic } from "@/core/plugins/mautic";
import { obtenerPlanificado } from "../../../utils";

export default defineComponent({
  name: "organization",

  components: {
    ProgressBar,
    ObjectiveSet,
    SettingsModal,
    CycleManagerModal,
    MemberManagerModal,
    ApiKeyManagerModal,
    ObjectiveInvitationFormModal,
    ReferencesModal,
    Uploader,
    OrganizationReport,
    Controls
  },

  mounted() {
    this.$nextTick(function() {
      const activePercentage = JSON.parse(
        localStorage.getItem("activePercentage")
      );
      if (activePercentage) {
        this.$store.dispatch(Actions.CHANGE_PERCENTAGE, activePercentage);
      }
    });
    //mautic start
    mautic.initialize("https://hello.futuregg.com/mtc.js");
    let loginLogs;
    const url =
      `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/logs?userId=` +
      this.$store.getters.currentUser.id;
    const currentToken = JwtService.getToken();

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentToken
      }
    })
      .then(res => res.json())
      .then(data => {
        loginLogs = data.result.logs;
      });
    setTimeout(() => {
      sendMautic(this.$store);
    }, 5000);

    //mautic end

    if (!this.authenticatedUser || !this.authenticatedUser.id) {
      this.$notify({
        title: "You are not authenticated",
        message: "Need credentials to continue",
        type: "success"
      });
      this.$router.push("/sign-in");
      return;
    } else {
      const { id: organizationId } = this.$route.params;
      this.organizationId = organizationId;

      this.$store.dispatch(Actions.SOCKET_JOIN, organizationId);

      this.socketInstance = this.$store.getters.getSocket;
    }

    this.socketInstance.on("CHANGE_DESCRIPTION", () => {
      this.loadOrganization();
    });

    this.emitter.on("after-organization-save", this.afterOrganizationSave);

    this.loadObjectives(false);
    this.loadPeriods();
    this.loadOrganization();
    this.loadObjectiveInvitations();
  },

  unmounted() {
    this.emitter.off("after-organization-save");
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const CurrenPeriod = computed(() => {
      const currentCycle = store.getters.getCycle;
      return currentCycle && currentCycle.id ? currentCycle : null;
    });

    const form = ref(store.getters.getSelectedObjectiveCamelCase);
    const changingPeriodState = computed(
      () => store.getters.getChangingPeriodState
    );

    const objetives = computed(() => store.getters.getObjectives);

    const CurrentOrganization = computed(() => route.params.id);
    const Planificado = computed(() => obtenerPlanificado(objetives.value));

    const getUrl = computed(
      () =>
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/downloadOKRs/${route.params.id}/${CurrenPeriod.value.id}`
    );

    const { t, te } = useI18n();
    const emitter = useEmitter();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const isPlanning = computed(() => {
      const currentCycle = store.getters.getCycle;
      return currentCycle && currentCycle.status
        ? currentCycle.status === "planning"
        : false;
    });

    const hasActualCycle = computed(() => store.getters.getHasActualCycle);

    const objectives = computed(() => store.getters.getObjectives);

    const changeCycleState = status => {
      console.log(`changingPeriodState`, changingPeriodState.value);
      const updatePeriod = { ...CurrenPeriod.value, status };
      store.dispatch(Actions.SAVE_PERIOD, updatePeriod);
    };

    return {
      emitter,
      translate,
      router,
      CurrentOrganization,
      CurrenPeriod,
      getUrl,
      isPlanning,
      form,
      Planificado,
      changeCycleState,
      changingPeriodState,
      hasActualCycle,
      objectives
    };
  },

  data() {
    return {
      socketInstance: null,
      socketRoom: [],
      objectiveListSharedWithMe: [],
      objectiveListSharedByMe: [],
      isRejectingObjectiveInvitation: false,
      isUploaderVisible: false,
      objectiveInvitation: null,
      organization: null,
      organizationId: null,
      isWaiting: false,
      waitingMessage: "",
      isObjectiveInvitationsOpened: false,
      objectiveInvitations: [],
      isNotificationsOpened: false,
      name: "",
      motive: "",
      initial: "",
      notifications: [
        {
          title: "Invitación a OKR",
          description:
            "Fuiste invitado por '[UserName]' a participar en la Organización [OrganizationName]",
          type: "danger"
        },
        {
          title: "Invitación a OKR",
          description:
            "El usuario [UserName] te ha invitado a participar en este OKR",
          type: "success",
          readAt: new Date()
        },
        {
          title: "Invitación a OKR",
          description:
            "Tienes una invitación pendiente a participar en este OKR",
          type: "dark"
        }
      ],
      isTypeUser: false
    };
  },

  computed: {
    authenticatedUser() {
      return this.$store.getters.currentUser;
    },

    getCycles() {
      return this.$store.getters.getAllCycles;
    },

    getCurrentCycle() {
      return this.$store.getters.getCycle;
    },

    getPeriod() {
      const Cycle = this.$store.getters.getCycle;
      if (Cycle && Cycle.name) {
        const { start_at, end_at } = Cycle;
        const startDate = new Date(start_at);
        const endDate = new Date(end_at);
        let percent = Math.round(
          ((new Date() - startDate) / (endDate - startDate)) * 100
        );
        percent = Math.min(100, Math.max(0, percent));
        return {
          period: `${Cycle.name.toUpperCase()} ${
            Cycle.start_at.split("-")[0]
          }-${this.translate(Cycle.status)}`,
          percent: percent
        };
      } else {
        return {
          period: "Crear Periodo",
          percent: "0"
        };
      }
    },

    getMembers() {
      return this.$store.getters.getMembers;
    }
  },

  methods: {
    changeCycle(event) {
      const selectedCycleId = event.target.value;
      const selectedCycle = this.getCycles.find(
        cycle => cycle.id == selectedCycleId
      );
      this.$store.dispatch(Actions.CHANGE_CYCLE, selectedCycle);
      localStorage.setItem("currentCycle", JSON.stringify(selectedCycle));
      this.loadObjectives(false);
    },

    openUploader() {
      this.isUploaderVisible = true;
    },

    closeUploader() {
      this.loadObjectives(false);
      this.isUploaderVisible = false;
    },

    loadPeriods() {
      const organizationId = this.$route.params.id
        ? this.$route.params.id
        : null; // @todo
      if (!organizationId) {
        alert("not organization ID here");
      }

      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/periods/organization/` +
        organizationId;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          this.$store.dispatch(Actions.FETCH_CYCLES, data.result).then(() => {
            this.loadObjectives(false);
          });
        })
        .catch(error => {
          //
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError"),
            type: "danger"
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    },

    afterOrganizationSave() {
      this.$store.dispatch(Actions.SEND_CHANGE_DESCRIPTION);

      this.loadOrganization();
      setTimeout(() => {
        const modalElement = document.getElementById("_app_settings_modal");
        if (modalElement) {
          modalElement.click();
        }
      }, 1000);
    },

    loadOrganization() {
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/` +
        this.organizationId;
      const currentToken = JwtService.getToken();

      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          const { result: organization } = data;
          this.organization = { ...organization[0] };
          this.name = this.organization.name;
          this.motive = this.organization.motive;
          this.initial = this.organization.name
            .split("")
            .reverse()
            .join("")
            .slice(-1);
          if (this.organization.role === "user") {
            this.isTypeUser = true;
          }
        })
        .catch(error => {
          //
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError"),
            type: "danger"
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    },

    loadObjectives(apiCall = true) {
      const organizationId = this.$route.params.id
        ? this.$route.params.id
        : null; // @todo

      const period = this.$store.getters.getCycle;
      // const period = JSON.parse(localStorage.getItem("currentCycle"));
      if (!organizationId) {
        alert("not organization ID here");
      }

      if (period && period.id) {
        const url =
          `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/organization/` +
          organizationId +
          "/" +
          period.id;
        const currentToken = JwtService.getToken();
        this.isWaiting = true;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": currentToken
          }
        })
          .then(res => {
            return res.json();
          })
          .then(data => {
            const { result: objectiveList } = data;
            if (apiCall) {
              //this.loadObjectivesSocket(objectiveList);
              this.$store.dispatch(
                Actions.SEND_CHANGE_OBJECTIVE,
                objectiveList
              );
            }
            this.$store.dispatch(Actions.LOAD_OBJECTIVE, {
              objective: [...objectiveList],
              payload: false
            });
          })
          .catch(error => {
            //
            this.$router.push("/dashboard");
            this.$notify({
              title: this.translate("thatIsBad"),
              message: this.translate("generalError"),
              type: "danger"
            });
          })
          .finally(() => {
            this.isWaiting = false;
          });
      }
    },

    loadObjectiveInvitations() {
      const currentToken = JwtService.getToken();
      const { id: organizationId } = this.$route.params;
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/shared/` +
        organizationId;

      this.isWaiting = true;
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          const { myShares, sharedWithMe } = data;
          this.objectiveInvitations = [];
          this.objectiveListSharedByMe = [...myShares];
          this.objectiveListSharedWithMe = [...sharedWithMe];

          this.objectiveListSharedWithMe.forEach(oInvitation => {
            if (oInvitation.status === "pending") {
              this.objectiveInvitations.push({
                id: oInvitation.id,
                okrId: oInvitation.id,
                organizationId: oInvitation.organization.id,
                status: oInvitation.status,
                title: oInvitation.title,
                description:
                  this.translate("youWereInvitedBy") +
                  ` ${oInvitation.organization.name}`,
                type: "dark"
              });
            }
          });
        })
        .catch(() => {
          this.isWaiting = false;
        });
    },

    confirmOrganizationRemoving() {
      const url =
        `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/` +
        this.organizationId;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          }
          return res.json();
        })
        .then(() => {
          this.$notify({
            title: "Bien",
            type: "info",
            message: this.translate("organizationLeavingSuccessfully")
          });
          document.getElementById("_app_settings_modal").click();
          setTimeout(() => {
            this.$router.push({ name: "dashboard" });
          }, 1000);
        })
        .catch(error => {
          //
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError"),
            type: "danger"
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    },

    startObjectiveInvitationRejecting(objectiveInvitation) {
      this.objectiveInvitation = objectiveInvitation;
      this.isRejectingObjectiveInvitation = true;
    },

    startObjectiveInvitationAcceptance(objectiveInvitation) {
      setTimeout(() => {
        this.objectiveInvitation = objectiveInvitation;
      }, 999);
    },

    acceptObjectiveInvitation(objectiveInvitationToAccept) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/respondinvitation`;
      const currentToken = JwtService.getToken();
      const { id: organizationId } = this.$route.params;
      objectiveInvitationToAccept.organization = organizationId;

      this.isWaiting = true;
      this.waitingMessage = this.translate("pleaseWait");
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(objectiveInvitationToAccept)
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            this.$notify({
              title: this.translate("bad"),
              message: this.translate("authorizationError")
            });

            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            this.$notify({
              title: this.translate("bad"),
              message: this.translate("errorDefault")
            });
            throw new Error("generalError");
          }
          return res.json();
        })
        .then(data => {
          const { result: objectiveInvitationAcceptedData } = data;

          console.info(
            "[Objective was accepted successfully]",
            objectiveInvitationAcceptedData
          );

          this.$notify({
            title: this.translate("thatIsGreat"),
            message: this.translate("invitationAcceptedSuccessfully"),
            type: "success"
          });

          this.loadObjectives(true);
          this.loadObjectiveInvitations();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isWaiting = false;
          this.waitingMessage = "";
          const modalElement = document.getElementById(
            "_app_objective_invitation_form_modal"
          );
          if (modalElement) {
            modalElement.click();
          }
        });
    },

    rejectObjectiveInvitation(objectiveInvitationToReject) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/respondinvitation`;
      const currentToken = JwtService.getToken();
      const currentPeriod = this.$store.getters.getCycle.id;
      const { id: organizationId } = this.$route.params;
      const rejection = {
        okr: objectiveInvitationToReject.okrId,
        organization: organizationId,
        period: currentPeriod,
        answer: "rejected"
      };

      this.isWaiting = true;
      this.waitingMessage = this.translate("pleaseWait");
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(rejection)
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }
          return res.json();
        })
        .then(data => {
          const { result: objectiveInvitationRejectedData } = data;

          console.info(
            "[Objective was rejected successfully]",
            objectiveInvitationRejectedData
          );

          this.isWaiting = false;
          this.$notify({
            title: this.translate("thatIsGreat"),
            message: this.translate("invitationRejectedSuccessfully"),
            type: "info"
          });

          this.loadObjectiveInvitations();
        })
        .catch(error => {
          this.$notify({
            title: this.translate("bad"),
            message: this.translate("objectiveInvitationError")
          });
        })
        .finally(() => {
          this.isWaiting = false;
          this.waitingMessage = "";
          this.cancelObjectiveRejecting();
          const modalElement = document.getElementById(
            "_app_objective_invitation_form_modal"
          );
          if (modalElement) {
            modalElement.click();
          }
        });
    },

    openModal() {
      this.$store.dispatch(Actions.OPEN_CYCLE);
    },

    isRejectingThisObjectiveInvitation(objectiveInvitation) {
      return (
        this.isRejectingObjectiveInvitation &&
        this.objectiveInvitation.id == objectiveInvitation.id
      );
    },

    cancelObjectiveRejecting() {
      this.objectiveInvitation = null;
      this.isRejectingObjectiveInvitation = false;
    }
  }
});
</script>

<style lang="scss">
.customSelect {
  .multiselect-input {
    min-height: 35px !important;
  }

  .multiselect-clear {
    min-height: 35px !important;
    max-height: 2.7rem;
    transform: translateY(-52%);
    top: 52%;
  }

  width: 147px;
  background: white;
  border-radius: 0.475rem;
  line-height: inherit;
  max-height: 2.7rem;
}

.objective-list {
  border: solid 1px orange !important;

  &-item {
    border: solid 1px blue !important;
    padding: 12px !important;
  }
}

.page-subtitle {
  color: #f9f9f975 !important;

  * {
    color: #f9f9f975 !important;
  }
}

.organization-objectiveInvitation-box {
  padding: 21px;
  border: dashed 1px #ffffff90;

  border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
}

.organization-objectiveInvitation {
  color: #fff !important;
  border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  position: relative;
}

.organization-objectiveInvitation .organization-objectiveInvitation-actions {
  position: absolute;
  right: 9px;
  top: 50%;
  transform: translateY(-50%);
}

.bg-tr {
  background: rgb(0, 0, 0);
  background: transparent\9;
  background: rgba(0, 0, 0, 0.27);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#27000000, endColorstr=#27000000);
  zoom: 1;
}

.clickeable {
  cursor: pointer;
}

.first-letter {
  color: transparent;
}

.first-letter:first-letter {
  font-size: 500%;
  color: #fff;
  text-transform: uppercase;
}

.page-header {
  background: #fff;
  padding: 15px 15px 25px 100px;
  position: relative;
  margin-bottom: 20px;
}

.organitatio-name {
  font-size: 29px;
  font-weight: 500;
}

.initial {
  display: table;
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  background: #587cfd;
  height: 92px;
  width: 70px;
  font-size: 50px;
  color: #fff;
  text-align: center;
  line-height: 90px;
}

.organization-objectiveInvitation-box {
  margin-bottom: 30px !important;
}

.cont-header {
  padding: 15px 21px;
  background: #fff;
  margin-bottom: 24px;
  border-radius: 6px !important;
}

.cont-header h1 {
  color: #587cfd !important;
  text-transform: capitalize;
}

.sticky-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  top: 65px;
  background-color: #587cfd !important;
}

.motive {
  margin: 10px 0;
  display: table;
  max-width: 830px;
  text-align: justify;
  line-height: 17px;
  white-space: pre-line;
}

.btn-controls {
  font-size: 12px;
}

.btn-title {
  display: flex;
}

.btn-title h1 {
  margin-left: 10px;
}

h1.fs-3.fw-bold.text-white.mb-3 {
  font-size: 32px !important;
}

@media (max-width: 768px) {
  .organization-objectiveInvitation {
    text-align: center;
  }

  .organization-objectiveInvitation .organization-objectiveInvitation-actions {
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    padding-top: 10px;
    display: block !important;
    text-align: center;
  }

  .breadcrumb.breadcrumb-separatorless.fw-bold {
    display: none;
  }

  .cont-header .d-flex.justify-content-between {
    display: block !important;
  }

  .avz-progreso {
    display: flex;
    justify-content: space-around;
  }

  .btn-title {
    margin-left: -15px;
  }

  h1.fs-3.fw-bold.text-white.mb-3 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  .btn.btn-default.btn-controls {
    font-size: 8px;
  }

  .progress-info.d-flex.flex-grow-1 {
    padding-bottom: 25px;
  }

  .container-porcentage {
    display: flex !important;
    text-align: left;
    padding-top: 0;
    margin-top: -25px;
  }

  .percentage.px-3 {
    position: relative !important;
    top: auto !important;
    right: auto !important;
    font-size: 12px !important;
  }
}

#kt_accordion_invitation {
  margin-bottom: 6px;
  border-radius: 6px !important;
  margin-top: 12px;
}

#kt_accordion_invitation .accordion-button {
  background: #fff !important;
  color: #000;
  padding-left: 45px;
  position: relative;
  padding-bottom: 12px;
  padding-top: 12px;
}

#kt_accordion_invitation .accordion-button:after {
  flex-shrink: 0;
  width: 1.15rem;
  height: 1.15rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.15rem;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  left: 15px;
}

#kt_accordion_invitation .kt_accordion_invitation_body_2 {
  background: #fff;
}

.accordion-header.d-flex {
  border-radius: 10px;
}

.adm-period {
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1401px) {
  .fixed-top-bar {
    position: fixed;
    z-index: 99;
    width: 100%;
    max-width: 1260px;
    background: #597dfe;
    padding: 10px;
    top: 65px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
  .fixed-top-bar {
    position: fixed;
    z-index: 99;
    width: 100%;
    max-width: 900px !important;
    background: #597dfe;
    padding: 10px;
    top: 65px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .fixed-top-bar {
    position: fixed;
    z-index: 99;
    width: 100%;
    max-width: 900px !important;
    background: #597dfe;
    padding: 10px;
    top: 65px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .fixed-top-bar {
    position: fixed;
    z-index: 99;
    width: 100%;
    max-width: 1080px !important;
    background: #597dfe;
    padding: 10px;
    top: 65px;
  }
}
</style>
