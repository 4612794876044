<template>
  <!--begin::Form-->
  <Form
    id="kt_invitation_form"
    class="form"
    @submit="send"
    :validation-schema="memberMail"
  >
    <!--begin::Input group-->
    <div class="row mb-6">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col Name-->
          <div class="col-12">
            <label
              class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            >
              <span class="required">{{ translate("name") }}</span>
              <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="Name"
              ></i>
            </label>

            <Field
              v-model="form.name"
              :disabled="isWaiting"
              autofocus
              type="text"
              class="form-control form-control-solid"
              placeholder=""
              name="name"
            />

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="name" />
              </div>
            </div>
          </div>
          <!--end::Col Name-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row mb-6">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col Name-->
          <div class="col-12">
            <label
              class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            >
              <span class="required">{{ translate("lastName") }}</span>
              <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="lastName"
              ></i>
            </label>

            <Field
              v-model="form.lastname"
              :disabled="isWaiting"
              autofocus
              type="text"
              class="form-control form-control-solid"
              placeholder=""
              name="lastname"
            />

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="lastname" />
              </div>
            </div>
          </div>
          <!--end::Col Name-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row mb-6">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col Name-->
          <div class="col-12">
            <label
              class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            >
              <span>{{ translate("phone") }}</span>
              <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="phone"
              ></i>
            </label>

            <Field
              v-model="form.phone"
              :disabled="isWaiting"
              autofocus
              type="phone"
              class="form-control form-control-solid"
              placeholder=""
              name="phone"
            />
          </div>
          <!--end::Col Name-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row mb-6">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col Name-->
          <div class="col-12">
            <label
              class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            >
              <span class="required">{{ translate("email") }}</span>
              <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="Email"
              ></i>
            </label>

            <Field
              v-model="form.email"
              :disabled="isWaiting"
              autofocus
              type="text"
              class="form-control form-control-solid"
              placeholder=""
              name="email"
            />

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="email" />
              </div>
            </div>
          </div>
          <!--end::Col Name-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="d-flex flex-column mb-10">
      <label class="fs-6 fw-bold mb-2">
        <span class="required">{{ translate("role") }}</span>
      </label>
      <Field
        v-model="form.role"
        :disabled="isWaiting"
        name="role"
        class="form-select form-select-solid"
        data-control="select2"
        data-hide-search="true"
        data-placeholder="Rol"
        as="select"
      >
        <template v-for="role in roles" :key="role.key">
          <option :value="role.key">{{ translate(role.key) }}</option>
        </template>
      </Field>
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage name="role" />
        </div>
      </div>
    </div>
    <!--end::Input group-->
    <!--begin::Input group-->
    <div class="d-flex flex-column mb-10">
      <label class="fs-6 fw-bold mb-2">
        <span class="required">{{ translate("message") }}</span>
      </label>
      <textarea
        :disabled="isWaiting"
        :placeholder="translate('messageIntro')"
        v-model="form.welcomeMessage"
        style="min-height: 120px"
        class="form-control form-control-solid"
        name="welcomeMessage"
      />
    </div>
    <!--end::Input group-->

    <!--begin::Actions-->
    <div class="text-center mt-6">
      <button
        ref="cancelButtonRef"
        :disabled="isWaiting"
        type="reset"
        class="btn btn-light me-3"
        @click.prevent="cancel"
      >
        <span class="indicator-label">
          {{ translate("cancel") }}
        </span>
      </button>

      <button
        ref="submitButtonRef"
        :disabled="isWaiting"
        type="submit"
        id="kt_modal_new_card_submit"
        class="btn btn-primary"
      >
        <span class="indicator-label">
          {{ isWaiting ? translate("sending") : translate("send") }}
        </span>
        <span class="indicator-progress">
          {{ translate("pleaseWait") }}
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
    <!--end::Actions-->
  </Form>
  <!--end::Form-->
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";
import { computed } from "vue";
import { useStore } from "vuex";
import { userHierarchyLevels, departmentAreas } from "./types";
import * as Yup from "yup";
import "yup-phone-lite";

const INVITATION_STATUS_OPTIONS = {
  _PENDING: "pending",
  _ACCEPTED: "accepted",
  _REJECTED: "rejected"
};

const EMPTY_INVITATION = () => ({
  name: "",
  email: "",
  lastname: "",
  phone: "",
  welcomeMessage: "",
  role: "",
  status: INVITATION_STATUS_OPTIONS._PENDING,
  organization: null
});

export default defineComponent({
  name: "_app_invitation_form",

  components: {
    ErrorMessage,
    Field,
    Form
  },

  props: {
    organization: {
      type: Object,
      default: () => null
    },
    invitation: {
      type: Object,
      default: () => {
        return EMPTY_INVITATION();
      }
    },
    isWaiting: {
      type: Boolean,
      default: () => false
    }
  },

  mounted() {
    this.form.welcomeMessage = this.welcomeMessage;
  },

  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const welcomeMessage = computed(() => {
      const authenticatedUser = store.getters.currentUser;
      if (!authenticatedUser) {
        console.info("[ No auth user ]", authenticatedUser);
        return;
      }

      const inviterName = authenticatedUser.name;
      const currentOrganization = store.getters.getOrganization;
      let organizationName;
      if (Array.isArray(currentOrganization)) {
        organizationName = currentOrganization[0].name;
      } else {
        organizationName = currentOrganization.name;
      }
      const messageFinal =
        /*  translate("hello") +
        ", " + */
        inviterName +
        " " +
        translate("messageFinal1") +
        " " +
        organizationName +
        " " +
        translate("messageFinal2");
      return messageFinal;
    });

    const memberMail = Yup.object().shape({
      name: Yup.string().required(
        translate("name") + " " + translate("isRequired")
      ),
      lastname: Yup.string().required(
        translate("lastName") + " " + translate("isRequired")
      ),
      role: Yup.string().required(
        translate("role") + " " + translate("isRequired")
      ),
      email: Yup.string()
        .email()
        .required(translate("email") + " " + translate("isRequired"))
    });

    return {
      translate,
      welcomeMessage,
      memberMail,
      userHierarchyLevels,
      departmentAreas
    };
  },
  data() {
    return {
      form: EMPTY_INVITATION(),
      roles: [
        { key: "admin", name: "Admin" },
        { key: "partner", name: "Partner" },
        { key: "shepherd", name: "Pastor" },
        { key: "user", name: "Usuario" }
      ]
    };
  },

  watch: {
    invitation(newValue) {
      if (newValue.email !== "") {
        this.form = newValue;
      } else {
        this.resetForm();
      }
    },
    organization() {
      this.form = EMPTY_INVITATION();
    }
  },

  methods: {
    send() {
      this.form.organization =
        this.organization && this.organization.id ? this.organization.id : null;
      this.$emit("onSend", { ...this.form });
      this.form = EMPTY_INVITATION();
    },
    resetForm() {
      const invitationTemplate = EMPTY_INVITATION();
      invitationTemplate.welcomeMessage = this.welcomeMessage;
      this.form = invitationTemplate;
    },
    cancel() {
      this.$emit("onCancel");
    }
  }
});
</script>
