<template>
  <!--begin::Modal - Upgrade plan-->
  <div
    class="modal fade"
    id="_app_settings_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header justify-content-end border-0 pb-0">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body pt-0 pb-15 px-5 px-xl-20">
          <!--begin::Heading-->
          <div class="mb-9 text-center">
            <h1
              class="mb-6"
              :class="{
                'text-danger': isOrganizationRemoverOpened
              }"
            >
              {{
                isOrganizationRemoverOpened
                  ? translate("organizationLeaving")
                  : isEditionEnabled
                  ? translate("organizationEdition")
                  : translate("organizationSettings")
              }}
            </h1>

            <div v-if="!isEditionEnabled" class="text-gray-400 fw-bold fs-5">
              <span
                :class="{
                  'text-danger': isOrganizationRemoverOpened
                }"
                >{{
                  isOrganizationRemoverOpened
                    ? translate("organizationSettingsLeaving")
                    : translate("organizationSettingsIntro")
                }}</span
              >
            </div>
          </div>
          <!--end::Heading-->

          <!--begin::Organization info-->
          <div
            class="d-flex flex-column"
            :class="{
              'text-danger': isOrganizationRemoverOpened
            }"
          >
            <!--begin::Row-->
            <div class="row text-center">
              <!--begin::Col-->
              <div
                v-if="!isEditionEnabled"
                class="col-lg-12 mb-10 mb-lg-0 pa-3"
              >
                <!-- begin:: Organization name -->
                <div class="mb-3">
                  <label>{{ translate("organization") }}</label>
                  <h3>
                    {{
                      organization && organization.name
                        ? organization.name
                        : "..."
                    }}
                  </h3>
                </div>
                <!-- end:: Organization name -->

                <!-- begin:: Organization owner -->
                <div class="mb-3">
                  <label>{{ translate("organizationOwner") }}</label>
                  <h3 style="text-transform:capitalize">
                    {{ authenticatedUser ? authenticatedUser.name : "..." }}
                    {{ authenticatedUser ? authenticatedUser.lastname : "..." }}
                  </h3>
                </div>
                <!-- end:: Organization owner -->

                <!-- begin:: Organization owner -->
                <div class="mb-3">
                  <label>{{ translate("organizationCreatedAt") }}</label>
                  <h3>
                    {{
                      organization && organization.created_at
                        ? new Date(organization.created_at).toLocaleDateString(
                            "en-US"
                          )
                        : "..."
                    }}
                  </h3>
                </div>
                <!-- end:: Organization owner -->

                <!-- begin:: Organization owner -->
                <div class="mb-3">
                  <label>{{ translate("organizationUserQuantity") }}</label>
                  <h3>
                    {{
                      organization && organization.employees
                        ? organization.employees
                        : "..."
                    }}
                  </h3>
                </div>
                <!-- end:: Organization owner -->

                <div v-if="!isEditionEnabled" class="mt-12">
                  <!-- begin:: Removing button -->
                  <button
                    v-if="!isOrganizationRemoverOpened && !isEditionEnabled"
                    :disabled="isEditionEnabled"
                    ref="removingButtonRef"
                    type="button"
                    id="remove_organization_button"
                    class="btn btn-danger"
                    @click="openOrganizationRemover()"
                  >
                    <span class="indicator-label">
                      <i class="bi bi-trash me-2" />
                      {{ translate("organizationLeave") }}
                    </span>
                  </button>
                  <!-- end:: Removing button -->

                  <!-- begin:: Cancel removing button -->
                  <button
                    v-else-if="!isEditionEnabled"
                    ref="cancelRemovingButtonRef"
                    type="button"
                    id="cancel_removing_button"
                    class="btn btn-light"
                    @click="finishOrganizationRemover()"
                  >
                    <span class="indicator-label">
                      {{ translate("organizationCancel") }}
                    </span>
                  </button>
                  <!-- end:: Cancel removing button -->

                  <!-- begin:: Edition button -->
                  <button
                    v-if="!isOrganizationRemoverOpened && !isEditionEnabled"
                    :disabled="isEditionEnabled"
                    ref="submitButtonRef"
                    type="button"
                    id="edit_organization_button"
                    class="btn btn-dark ms-3 ps-6"
                    @click="isEditionEnabled = !isEditionEnabled"
                  >
                    <span class="indicator-label">
                      <i class="bi bi-pencil me-2" />
                      {{ translate("edit") }}
                    </span>
                  </button>
                  <!-- end:: Edition button -->

                  <!-- begin:: Remove confirmation button -->
                  <button
                    v-else-if="!isEditionEnabled"
                    ref="submitButtonRef"
                    type="button"
                    id="edit_organization_button"
                    class="btn btn-danger ms-3 ps-6"
                    @click="leave"
                  >
                    <span class="indicator-label">
                      <i class="bi bi-trash me-2" />
                      {{ translate("confirm") }}
                    </span>
                  </button>
                  <!-- end:: Remove confirmation button -->
                </div>
              </div>

              <!--begin::Organization form-->
              <div v-if="isEditionEnabled" class="col-lg-12">
                <!--begin::Form-->
                <OrganizationForm
                  :organization="currentOrganization"
                  :is-edition-enabled="isEditionEnabled"
                  @onStopEdition="isEditionEnabled = !isEditionEnabled"
                  @onSave="updateOrganization"
                />
                <!--end::Form-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <!--end::Plans-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Upgrade plan-->
</template>

<script>
import { defineComponent, ref } from "vue";
import useEmitter from "@/core/plugins/emitter.js";
import { useI18n } from "vue-i18n";
import OrganizationForm from "@/components/app/Organizations/OrganizationForm.vue";
import JwtService from "@/core/services/JwtService";

const EMPTY_ORGANIZATION = {
  id: null,
  name: "",
  industry: "",
  employees: "",
  visibleMembers: 0,
  country: "",
  motive: "",
  startup: false,
  shareOkrs: false,
  createdAt: new Date()
};

export default defineComponent({
  name: "_app_settings_modal",
  components: {
    OrganizationForm
  },
  data() {
    return {
      isWaiting: false,
      isEditionEnabled: false,
      isOrganizationRemoverOpened: false,
      currentOrganization: EMPTY_ORGANIZATION
    };
  },
  computed: {
    authenticatedUser() {
      return this.$store.getters.currentUser;
    }
  },
  props: {
    organization: {
      type: Object,
      default: () => EMPTY_ORGANIZATION
    }
  },
  watch: {
    isEditionEnabled(isEditionEnabledValue) {
      if (isEditionEnabledValue) {
        setTimeout(() => {
          this.currentOrganization = { ...this.organization };
        }, 33);
      }
    }
  },
  methods: {
    updateOrganization(organization) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations`;
      const currentToken = JwtService.getToken();
      const organizationToSave = {
        ...organization,
        owner: this.authenticatedUser.id,
        motive: organization.motive
      };

      this.isWaiting = true;
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(organizationToSave)
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }

          return res.json();
        })
        .then(data => {
          this.emitter.emit("after-organization-save", data);
          this.isEditionEnabled = false;
          this.$notify({
            title: "Excelente",
            message: "La organización se ha guardado exitosamente",
            type: "success"
          });
        })
        .catch(error => {
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError")
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    },
    openOrganizationRemover() {
      this.isOrganizationRemoverOpened = true;
    },
    finishOrganizationRemover() {
      this.isOrganizationRemoverOpened = false;
    },
    leave() {
      this.$emit("onConfirmOrganizationRemoving");
    }
  },
  setup() {
    const emitter = useEmitter();
    const current = (ref < "month") | ("annual" > "month");
    const selected = ref("Startup");
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const plans = [
      {
        title: "Startup",
        subTitle: "Best for startups",
        description: "Optimal for 10+ team size and new startup",
        priceMonth: "39",
        priceAnnual: "399",
        default: true,
        custom: false,
        features: [
          {
            title: "Up to 10 Active Users",
            supported: true
          },
          {
            title: "Up to 30 Project Integrations",
            supported: true
          },
          {
            title: "Analytics Module",
            supported: true
          },
          {
            title: "Finance Module",
            supported: false
          },
          {
            title: "Accounting Module",
            supported: false
          },
          {
            title: "Network Platform",
            supported: false
          },
          {
            title: "Unlimited Cloud Space",
            supported: false
          }
        ]
      },

      {
        title: "Advanced",
        subTitle: "Best for 100+ team size",
        description: "Optimal for 100+ team size and grown company",
        priceMonth: "339",
        priceAnnual: "3399",
        default: false,
        custom: false,
        features: [
          {
            title: "Up to 10 Active Users",
            supported: true
          },
          {
            title: "Up to 30 Project Integrations",
            supported: true
          },
          {
            title: "Analytics Module",
            supported: true
          },
          {
            title: "Finance Module",
            supported: true
          },
          {
            title: "Accounting Module",
            supported: true
          },
          {
            title: "Network Platform",
            supported: false
          },
          {
            title: "Unlimited Cloud Space",
            supported: false
          }
        ]
      },

      {
        title: "Enterprise",
        subTitle: "Best value for 1000+ team",
        description: "Optimal for 1000+ team and enterpise",
        priceMonth: "999",
        priceAnnual: "9999",
        label: "Most popular",
        default: false,
        custom: false,
        features: [
          {
            title: "Up to 10 Active Users",
            supported: true
          },
          {
            title: "Up to 30 Project Integrations",
            supported: true
          },
          {
            title: "Analytics Module",
            supported: true
          },
          {
            title: "Finance Module",
            supported: true
          },
          {
            title: "Accounting Module",
            supported: true
          },
          {
            title: "Network Platform",
            supported: true
          },
          {
            title: "Unlimited Cloud Space",
            supported: true
          }
        ]
      },

      {
        title: "Custom",
        subTitle: "Requet a custom license",
        default: false,
        custom: true
      }
    ];

    return {
      plans,
      current,
      selected,
      translate,
      emitter
    };
  }
});
</script>
