
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  industries,
  countries,
  quantityOptions,
  organizationsType
} from "./types";
import * as Yup from "yup";

const EMPTY_ORGANIZATION = {
  id: null,
  name: "",
  industry: "",
  employees: "",
  visibleMembers: 0,
  country: "",
  motive: "",
  organizationsType: "",
  hierarchy: "",
  area: "",
  shareOkrs: false,
  createdAt: new Date()
};

export default defineComponent({
  name: "_app_organization_form",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const submitOrganization = Yup.object().shape({
      nameOnCard: Yup.string().required(
        translate("organization") + " " + translate("isRequired")
      ),
      industry: Yup.string().required(
        translate("industry") + " " + translate("isRequired")
      ),
      employees: Yup.string().required(
        translate("employees") + " " + translate("isRequired")
      ),
      country: Yup.string().required(
        translate("country") + " " + translate("isRequired")
      )
    });

    return {
      translate,
      submitOrganization,
      industries,
      countries,
      quantityOptions,
      organizationsType
    };
  },
  props: {
    organization: {
      type: Object,
      default: () => EMPTY_ORGANIZATION
    },
    isEditionEnabled: {
      type: Boolean,
      default: () => false
    },
    isCancelButtonVisible: {
      type: Boolean,
      default: () => true
    }
  },
  watch: {
    organization(organization) {
      this.form = { ...organization };
    }
  },
  data() {
    return {
      form: EMPTY_ORGANIZATION
    };
  },
  methods: {
    stopEdition() {
      this.$emit("onStopEdition");
    },
    save() {
      this.$emit("onSave", this.form);
      setTimeout(() => {
        const modalElement = document.getElementById(
          "_app_organization_new_form_modal"
        );

        if (modalElement) {
          modalElement.click();
        }
      }, 1000);
    }
  }
});
