<template>
  <div class="uploader-box">
    <!--begin::Block-->
    <div class="pt-3 mb-6 text-left">
      <span class="text-light"
        >{{ translate("clickToPickOrDragAndDropYourXLSFile") }}
      </span>
    </div>
    <!--end::Block-->

    <div class="rounded border p-10" :class="{ 'border-danger': hasError }">
      <el-upload
        class="upload-demo"
        drag
        :action="uploaderUrl"
        :headers="uploaderHeaders"
        :on-success="uploadSuccess"
        :on-error="uploadError"
        :multiple="false"
        :limit="1"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          <span>{{ translate("dropFileHere") }}</span>
          <b class="mx-2">{{ translate("or") }}</b>
          <em>{{ translate("clickToUpload") }}</em>
        </div>
        <template #tip>
          <div class="el-upload__tip text-center">
            <span class="text-light"
              >{{ translate("XLSFilesWithASizeLessThan500kb") }}
            </span>
          </div>
        </template>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "uploader",
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  },
  data() {
    return {
      hasError: false
    };
  },
  methods: {
    uploadSuccess(data) {
      if (data.ok) {
        let msj = "";
        data.ok.forEach((element, index) => {
          msj += ", " + index;
        });
        this.$notify({
          title: this.translate("uploaderSuccessTitle"),
          message:
            "Las siguientes rows no se pudieron cargar correctamente [" +
            msj +
            "]",
          type: "success"
        });
      } else {
        this.$notify({
          title: this.translate("uploaderSuccessTitle"),
          message: this.translate("uploaderSuccessMessage"),
          type: "success"
        });
      }
      this.$emit("onClose");
    },
    uploadError(error) {
      console.error("[error]", error);
      this.hasError = true;
      this.$notify({
        title: this.translate("uploaderErrorTitleError"),
        message: this.translate("uploaderErrorMessageError"),
        type: "danger"
      });
    }
  },
  computed: {
    uploaderUrl() {
      const { id: organizationId } = this.$route.params;
      return `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/okrs/uploaderOKRs/${organizationId}`;
    },
    uploaderHeaders() {
      const currentToken = JwtService.getToken();
      return {
        "x-access-token": currentToken
      };
    }
  }
});
</script>

<style>
.upload-demo .el-upload {
  display: block !important;
  text-align: center !important;
}
.upload-demo .el-upload .el-upload-dragger {
  display: block !important;
  width: 100%;
}
</style>
