<template>
  <!--begin::Modal - View Members-->
  <div class="modal fade" id="_app_member-manager_modal" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
          <!--begin::Heading-->
          <div class="text-center mb-13">
            <!--begin::Title-->
            <h1 class="mb-3" :class="{
              'text-danger': cancellationConfirmation !== null
            }">
              {{
                isInvitationFormVisible
                ? translate("newInvitation")
                : cancellationConfirmation
                  ? translate("cancellationConfirmationTitle")
                  : translate("memberManager")
              }}
            </h1>
            <!--end::Title-->

            <!--begin::Description-->
            <div v-if="!changeRoleUser" class="text-gray-400 fw-bold fs-5">
              <span :class="{
                'text-danger': cancellationConfirmation !== null
              }">
                {{
                  isInvitationFormVisible
                  ? translate("newInvitationIntro") // "Envía a quien quieras a través de su correo electrónico"
                  : cancellationConfirmation
                    ? translate("cancellationConfirmationDescription")
                    : translate("pickOrCreateMembers")
                }}</span>
            </div>
            <!--end::Description-->
          </div>
          <!--end::Heading-->

          <!-- begin:: Cancellation confirmation -->
          <div v-if="cancellationConfirmation" class="text-center">
            <h2 v-text="cancellationConfirmation.invitedName" class="mb-12" />
            <div class="mt-3 text-center">
              <button ref="cancelButtonRef" :disabled="isWaiting" type="reset" class="btn btn-light me-3"
                @click.prevent="stopInvitationCancellation">
                <span class="indicator-label">
                  {{ translate("cancel") }}
                </span>
              </button>

              <button ref="submitButtonRef" :disabled="isWaiting" type="submit" class="btn btn-primary"
                @click="cancellationConfirmation.onClick()">
                <span class="indicator-label">
                  {{
                    isWaiting ? translate("cancelling") : translate("confirm")
                  }}
                </span>
                <span class="indicator-progress">
                  {{ translate("pleaseWait") }}
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </div>
          <!-- end:: Cancellation confirmation -->

          <!-- begin:: Resend confirmation -->
          <div v-if="resendConfirmation" class="text-center">
            <h2 v-text="resendConfirmation.invitedName" class="mb-12" />
            <div class="mt-3 text-center">
              <button ref="cancelButtonRef" :disabled="isWaiting" type="reset" class="btn btn-light me-3"
                @click.prevent="stopInvitationResend">
                <span class="indicator-label">
                  {{ translate("cancel") }}
                </span>
              </button>

              <button ref="submitButtonRef" :disabled="isWaiting" type="submit" class="btn btn-primary"
                @click="resendConfirmation.onClick()">
                <span class="indicator-label">
                  {{
                    isWaiting ? translate("cancelling") : translate("confirm")
                  }}
                </span>
                <span class="indicator-progress">
                  {{ translate("pleaseWait") }}
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </div>
          <!-- end:: Resend confirmation -->
          <!--begin::Member List-->
          <div v-if="!isInvitationFormVisible &&
            !cancellationConfirmation &&
            !resendConfirmation
            " class="mb-15">
            <!-- Searcher -->
            <div class="invitations-searcher mb-12">
              <form @submit.prevent="lookForMembers" action="/members">
                <div class="input-group">
                  <button class="btn btn-outline-secondary dropdown-toggle btn-dark" type="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    Todos
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a @click.prevent="
                        filterMembers(invitationStatusOptions._PENDING)
                        " class="dropdown-item clickeable text-warning">{{ translate("onlyPending") }}</a>
                    </li>
                    <li>
                      <a @click.prevent="
                        filterMembers(invitationStatusOptions._ACCEPTED)
                        " class="dropdown-item clickeable text-success">{{ translate("onlyAccepted") }}</a>
                    </li>
                    <li>
                      <a @click.prevent="
                        filterMembers(invitationStatusOptions._REJECTED)
                        " class="dropdown-item clickeable text-danger">{{ translate("onlyRejected") }}</a>
                    </li>
                    <li>
                      <a @click.prevent="filterMembers(null)" class="dropdown-item clickeable">{{ translate("all") }}</a>
                    </li>
                  </ul>

                  <input v-model="searcherForm.text" :placeholder="translate('invitationSearch')"
                    :aria-label="translate('invitationSearchDescription')" type="text" class="form-control"
                    aria-describedby="invitation-searcher-button" @input="searchMember" />
                  <button class="btn btn-dark" type="submit" id="invitation-searcher-button">
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </form>
            </div>

            <!-- begin:: Applied filter -->
            <div v-if="currentStatusFilter" class="applied-filter mb-9" :class="{
              'text-danger border-danger':
                currentStatusFilter === invitationStatusOptions._REJECTED,
              'text-success border-success':
                currentStatusFilter === invitationStatusOptions._ACCEPTED,
              'text-warning border-warning':
                currentStatusFilter === invitationStatusOptions._PENDING
            }">
              <h4 class="text-center mb-6 text-muted" v-text="currentStatusFilterIntro" />
              <h2 class="text-center mb-9" v-text="currentStatusFilterText" />

              <div class="text-center">
                <button @click="filterMembers(null)" class="btn btn-round ps-4 btn-light outline" :class="{
                  'text-danger border-danger':
                    currentStatusFilter === invitationStatusOptions._REJECTED,
                  'text-success border-success':
                    currentStatusFilter === invitationStatusOptions._ACCEPTED,
                  'text-warning border-warning':
                    currentStatusFilter === invitationStatusOptions._PENDING
                }">
                  <i class="bi bi-x-lg me-1" :class="{
                    'text-danger':
                      currentStatusFilter ===
                      invitationStatusOptions._REJECTED,
                    'text-success':
                      currentStatusFilter ===
                      invitationStatusOptions._ACCEPTED,
                    'text-warning':
                      currentStatusFilter === invitationStatusOptions._PENDING
                  }" />
                  {{ translate("close") }}
                </button>
              </div>
            </div>
            <!-- end:: Applied filter -->

            <!--begin::List-->
            <div v-if="members && members.length" class="mh-375px scroll-y invitation-list">
              <template v-for="(user, index) in members" :key="index">
                <!--begin::User-->
                <div class="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                  <!--begin::Details-->
                  <div class="d-flex align-items-center">
                    <!--begin::Details-->
                    <div class="ms-6">
                      <!--begin::Name-->

                      <div class="inline-txt">
                        <a :href="`#/member/${user.user}`"
                          class="d-flex align-items-center fs-5 fw-bolder text-dark text-hover-primary text-capitalize">
                          {{ user.name }} {{ user.lastname }}
                        </a>

                        <span v-if="isOwner && user.user !== currentUser.id" @click="changeOwner(user.user)">
                          Hacer Owner
                        </span>
                      </div>

                      <!--begin::Role-->

                      <div v-if="!isAdmin" class="fw-bold text-gray-400">
                        {{ user.role }}
                      </div>
                      <!--end::Role-->

                      <div v-else :id="`editTournamentAccordionWrapper-${user.user}`" class="accordion accordion-flush">
                        <div class="accordion-item">
                          <h2 class="accordion-header" :id="`editTournamentAccordionHeading-${user.user}`">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              :data-bs-target="`#editTournamentAccordion-${user.user}`
                                " aria-expanded="false" aria-controls="editTournamentAccordion">
                              {{ user.role }}
                            </button>
                          </h2>

                          <div class="edit-role-user">
                            <div :id="`editTournamentAccordion-${user.user}`" class="accordion-collapse collapse"
                              :aria-labelledby="`editTournamentAccordionHeadingWrapper-${user.user}`
                                ">
                              <div class="accordion-body">
                                <div v-if="user.role !== 'admin'" class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                    value="admin" @click="changeRole(user.user, 'admin')" />
                                  <label class="form-check-label" for="inlineRadio1">Admin</label>
                                </div>
                                <div v-if="user.role !== 'user'" class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                    value="user" @click="changeRole(user.user, 'user')" />
                                  <label class="form-check-label" for="inlineRadio2">{{ translate("user") }}</label>
                                </div>

                                <div v-if="user.role !== 'shepherd'" class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                    value="shepherd" @click="changeRole(user.user, 'shepherd')" />
                                  <label class="form-check-label" for="inlineRadio2">{{ translate("shepherd") }}</label>
                                </div>

                                <div v-if="user.role !== 'partner'" class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4"
                                    value="partner" @click="changeRole(user.user, 'partner')" />
                                  <label class="form-check-label" for="inlineRadio2">{{ translate("partner") }}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="currentOrganization != null" class="accordion accordion-flush">
                        <div class="accordion-item">
                          <h2 class="accordion-header" :id="`editPositionAndAreaHeading-${user.user}`">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              :data-bs-target="`#editPositionAndArea-${user.user}`" aria-expanded="false"
                              aria-controls="editPositionAndArea">
                              {{ $t("hierarchy") + ': ' + $t(`userHierarchyLevel.${user.user_hierarchy_level}`)}}
                              <br>
                              {{ $t("area") + ': ' + $t(`userDepartment.${user.user_department}`)}}
                            </button>
                          </h2>

                          <div class="edit-role-user">
                            <div :id="`editPositionAndArea-${user.user}`" class="accordion-collapse collapse"
                              :aria-labelledby="`editPositionAndAreaHeadingWrapper-${user.user}`
                                ">
                              <div class="accordion-body">
                                <DepartmentHierarchy :organization="user" :updateUsers="true" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--end::Name-->

                      <!--begin::Email-->
                      <div class="fw-bold text-gray-400">{{ user.email }}</div>
                      <!--end::Email-->

                      <!--begin::Email-->
                      <div v-if="isAdmin" class="fw-bold text-danger">
                        <span class="clickeable" @click="startInvitationCancellation(user)">
                          {{
                            user.status === "accepted"
                            ? translate("removeMember")
                            : translate("cancelInvitation")
                          }}</span>
                        <span v-if="isAdmin &&
                            user.status === invitationStatusOptions._PENDING || user.status === invitationStatusOptions._REJECTED
                            " class="clickeable" @click="startInvitationForm(user)" style="margin-left: 10px">
                          {{ translate("resendInvitation") }}</span>
                      </div>
                      <!--end::Email-->
                    </div>
                    <!--end::Details-->
                  </div>
                  <!--end::Details-->

                  <!--begin::Stats-->
                  <div class="d-flex">
                    <div class="fs-5 fw-bolder text-dark">
                      <small>{{ user ? getUserRole(user.role) : "" }}</small>
                      <small>{{ getUserRole(user.role) }}</small>
                    </div>
                    <!--begin::Sales-->
                    <div class="text-end">
                      <span class="badge fs-8 fw-bold ms-2" :class="{
                        'badge-warning':
                          user.status === invitationStatusOptions._PENDING,
                        'badge-success':
                          user.status === invitationStatusOptions._ACCEPTED,
                        'badge-danger':
                          user.status === invitationStatusOptions._REJECTED
                      }">
                        {{ getStatusText(user.status) }}
                      </span>
                      <button v-if="user.status === invitationStatusOptions._PENDING" @click="copyToClipboard(user.link)"
                        class="badge badge-secondary fw-bold ms-2" style="margin-right: 10px">
                        <i class="bi bi-files" />
                      </button>
                    </div>
                    <!--end::Sales-->
                  </div>
                  <!--end::Stats-->
                </div>
                <!--end::User-->
              </template>
            </div>

            <div v-else class="p-6 text-center">
              {{ translate("emptyResults") }}
              <div class="mt-6 text-muted">
                {{ translate("invitePeopleNow") }}
              </div>
            </div>
            <!--end::List-->
          </div>
          <!--end::User List-->

          <!-- Invitation Form -->
          <InvitationForm v-else-if="!cancellationConfirmation" :organization="currentOrganization"
            :invitation="invitation" :isWaiting="isWaiting" @onSend="sendInvitation" @onCancel="stopInvitationForm" />

          <div v-if="isAdmin &&
            !isInvitationFormVisible &&
            !cancellationConfirmation &&
            !resendConfirmation
            " class="text-center">
            <!--begin::Button-->
            <button @click="startInvitationForm(null)" class="btn btn-round btn-info ps-4">
              <i class="bi bi-plus me-1" />
              {{ translate("invitation") }}
            </button>
            <!--end::Button-->
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - View Members-->
</template>

<script>
import { defineComponent, computed, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import JwtService from "@/core/services/JwtService";
import InvitationForm from "@/components/app/Organizations/InvitationForm.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import DepartmentHierarchy from "./DepartmentHierarchy.vue";

const INVITATION_STATUS_OPTIONS = {
  _PENDING: "pending",
  _ACCEPTED: "accepted",
  _REJECTED: "rejected"
};

const INVITATION_ROLES = {
  _ADMIN: "admin",
  _PARTNER: "partner",
  _SHEPHERD: "shepherd",
  _USER: "user"
};

const EMPTY_INVITATION = {
  name: "",
  email: "",
  phone: "",
  welcomeMessage: "",
  role: "",
  status: INVITATION_STATUS_OPTIONS._PENDING,
  organization: null
};

export default defineComponent({
  name: "_app_member-manager_modal",
  components: { InvitationForm, DepartmentHierarchy },

  props: {
    organization: {
      type: Object,
      default: () => null
    }
  },

  data() {
    return {
      isInvitationFormVisible: false,
      invitationStatusOptions: INVITATION_STATUS_OPTIONS,
      currentStatusFilter: null,
      isWaiting: false,
      invitation: null,
      cancellationConfirmation: null,
      members: [],
      originalMembers: [],
      searcherForm: {
        text: ""
      }
    };
  },

  computed: {
    authenticatedUser() {
      return this.$store.getters.currentUser;
    },

    currentStatusFilterText() {
      switch (this.currentStatusFilter) {
        case INVITATION_STATUS_OPTIONS._PENDING:
          return this.$t(INVITATION_STATUS_OPTIONS._PENDING);
        case INVITATION_STATUS_OPTIONS._ACCEPTED:
          return this.$t(INVITATION_STATUS_OPTIONS._ACCEPTED);
        case INVITATION_STATUS_OPTIONS._REJECTED:
          return this.$t(INVITATION_STATUS_OPTIONS._REJECTED);
        default:
          return "";
      }
    },

    currentStatusFilterIntro() {
      switch (this.currentStatusFilter) {
        case INVITATION_STATUS_OPTIONS._PENDING:
          return this.translate("youAreSeeingOnlyPendingStatus");
        case INVITATION_STATUS_OPTIONS._ACCEPTED:
          return this.translate("youAreSeeingOnlyAcceptedStatus");
        case INVITATION_STATUS_OPTIONS._REJECTED:
          return this.translate("youAreSeeingOnlyRejectedStatus");
        default:
          return "";
      }
    }
  },

  mounted() {
    const store = useStore();
    this.loadMembers();
    watch(
      () => store.getters.getMembers,
      (newMembers, oldMembers) => {
        this.members = newMembers;
        this.originalMembers = newMembers;
      }
    );
  },

  methods: {
    copyToClipboard(link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.$notify({
            title: this.translate("good"),
            message: this.translate("Link key copied to clipboard"),
            type: "success"
          });
        })
        .catch(err => {
          console.error("Failed to copy value: ", err);
        });
    },

    changeOwner(user) {
      Swal.fire({
        title: this.translate("saveTheChanges"),
        showCancelButton: true,
        confirmButtonText: this.translate("save")
      }).then(result => {
        if (result.isConfirmed) {
          const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/changeOwner`;
          const currentToken = JwtService.getToken();
          const idOrganization = this.$route.params.id;

          const body = {
            user: user,
            organization: idOrganization
          };

          fetch(url, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": currentToken
            },
            body: JSON.stringify(body)
          })
            .then(res => {
              return res.json();
            })
            .then(() => {
              this.loadMembers();
            })
            .catch(error => {
              console.log("[error]", error);
            });
          Swal.fire("Saved!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    },

    changeRole(id, role) {
      Swal.fire({
        title: this.translate("saveTheChanges"),
        showCancelButton: true,
        confirmButtonText: this.translate("save")
      }).then(result => {
        if (result.isConfirmed) {
          const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/users/update-role`;
          const currentToken = JwtService.getToken();
          const idOrganization = this.$route.params.id;

          const body = {
            user: id,
            organization: idOrganization,
            role: role
          };

          fetch(url, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": currentToken
            },
            body: JSON.stringify(body)
          })
            .then(res => {
              return res.json();
            })
            .then(() => {
              this.loadMembers();
            })
            .catch(error => {
              console.log("[error]", error);
            });
          Swal.fire("Saved!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    },

    getUserRole(role) {
      switch (role) {
        case INVITATION_ROLES._ADMIN:
          this.translate("admin");
          break;
        case INVITATION_ROLES._PARTNER:
          this.translate("partner");
          break;
        case INVITATION_ROLES._SHEPHERD:
          this.translate("shepherd");
          break;
        case INVITATION_ROLES._USER:
          this.translate("user");
          break;
        default:
          this.translate("user");
          break;
      }
    },

    filterMembers(filter) {
      if (!filter) {
        this.members = [...this.originalMembers];
        this.currentStatusFilter = null;
        return;
      }

      const filteredMembers = this.members.filter(member => {
        return member.status === filter;
      });

      this.currentStatusFilter = filter;
      this.members = [...filteredMembers];
    },

    loadMembers() {
      this.isWaiting = true;
      const organization = this.$route.params;
      this.$store.dispatch(Actions.FETCH_MEMBERS, organization).then(data => {
        this.members = data;
        this.originalMembers = data;
      });
      this.isWaiting = false;
    },

    startInvitationCancellation(invitation) {
      this.cancellationConfirmation = {
        invitedName: invitation.name,
        onClick: () => {
          this.cancelInvitation(invitation);
        }
      };
    },

    startInvitationResend(invitation) {
      this.resendConfirmation = {
        invitedName: invitation.name,
        onClick: () => {
          this.stopInvitationResend();
          this.startInvitationForm(invitation);
        }
      };
    },

    cancelInvitation(invitation) {
      const memberId = invitation.user;
      const organizationId = this.$route.params.id;
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/member/${memberId}/${organizationId}`;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      return fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }
          return res.json();
        })
        .then(() => {
          this.isWaiting = false;
          const memberIndex = this.members.findIndex(
            u => u.email === invitation.email
          );
          if (memberIndex > -1) {
            this.members.splice(memberIndex, 1);
          }
          this.isWaiting = false;
          this.stopInvitationCancellation();
          this.loadMembers();
          this.$notify({
            title: this.translate("done"),
            message: this.translate("invitationCancelledSuccessfully")
          });
        })
        .catch(error => {
          console.log("[error]", error);
          this.stopInvitationCancellation();
          this.loadMembers();
          this.$notify({
            title: this.translate("bad"),
            message: this.translate("invitationCancellationError"),
            type: "danger"
          });
        });
    },

    stopInvitationCancellation() {
      this.cancellationConfirmation = null;
    },

    lookForMembers() {
      alert("@todo: Go look for: " + this.searcherForm.text);
    },

    sendInvitation(invitationToSend) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/member`;
      const currentToken = JwtService.getToken();

      if (invitationToSend.organization == null) {
        invitationToSend.organization = this.$route.params.id;
      }

      invitationToSend.messages = {
        hi: this.translate("invitationMessages.hi"),
        notRespond: this.translate("invitationMessages.notRespond"),
        ifNotExist: this.translate("invitationMessages.ifNotExist"),
        ifExist: this.translate("invitationMessages.ifExist"),
        click: this.translate("invitationMessages.click"),
        subject: this.translate("invitationMessages.subject"),
        welcome: invitationToSend.welcomeMessage
      };
      delete invitationToSend["welcomeMessage"];

      this.isWaiting = true;
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(invitationToSend)
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            setTimeout(() => {
              window.location.href = "/";
            }, 999);
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }
          return res.json();
        })
        .then(() => {
          // const { result: userData } = data;
          this.members.unshift(invitationToSend);
          this.$notify({
            title: this.translate("great"),
            message: this.translate("memberInvitedSuccessfully"),
            type: "success"
          });
          this.loadMembers();
        })
        .catch(error => {
          console.log("[error]", error);
          this.$notify({
            title: this.translate("bad"),
            message: this.translate("memberInvitedError")
          });
        })
        .finally(() => {
          this.isWaiting = false;
          this.isInvitationFormVisible = false;
        });
    },

    startInvitationForm(invitation) {
      this.isInvitationFormVisible = true;
      setTimeout(() => {
        this.invitation = invitation
          ? {
            ...{
              name: invitation.name,
              lastname: invitation.lastname,
              phone: invitation.cellphone,
              email: invitation.email,
              role: invitation.role,
              area: invitation.user_department,
              hierarchy: invitation.user_hierarchy_level,
              status: invitation.status,
              welcomeMessage: this.welcomeMessage
            }
          }
          : { ...EMPTY_INVITATION };
        this.currentOrganization = { ...this.organization };
      }, 333);
    },

    stopInvitationForm() {
      this.isInvitationFormVisible = false;
    },

    getStatusText(textKey) {
      switch (textKey) {
        case INVITATION_STATUS_OPTIONS._PENDING:
          return this.translate("pending");
        case INVITATION_STATUS_OPTIONS._ACCEPTED:
          return this.translate("accepted");
        case INVITATION_STATUS_OPTIONS._REJECTED:
          return this.translate("rejected");
        default:
          return this.translate("pending");
      }
    },

    searchMember() {
      const { text: textToSearch } = this.searcherForm;
      const filteredMembers = this.members.filter(member => {
        const memberName = member.name.toLowerCase();
        const textToSearchInMembers = textToSearch.toLowerCase();
        return memberName.includes(textToSearchInMembers);
      });

      if (!textToSearch || textToSearch === "") {
        this.members = [...this.originalMembers];
        return;
      }

      this.members = [...filteredMembers];
    }
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const { t, te } = useI18n();

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const welcomeMessage = computed(() => {
      const authenticatedUser = store.getters.currentUser;
      if (!authenticatedUser) {
        console.info("[ No auth user ]", authenticatedUser);
        return;
      }

      const inviterName = authenticatedUser.name;
      const currentOrganization = store.getters.getOrganization;
      let organizationName;
      if (Array.isArray(currentOrganization)) {
        organizationName = currentOrganization[0].name;
      } else {
        organizationName = currentOrganization.name;
      }
      const messageFinal =
        /*  translate("hello") +
        ", " + */
        inviterName +
        " " +
        translate("messageFinal1") +
        " " +
        organizationName +
        " " +
        translate("messageFinal2");
      return messageFinal;
    });

    onMounted(() => {
      const { id } = route.params;
      const organization = store.getters.getOrganization;
      if (organization == null && id) {
        store.dispatch(Actions.LOAD_ORGANIZATION, {
          organization: null,
          id: id
        });
      }
    });

    const currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const currentOrganization = computed(() => {
      return store.getters.getOrganization;
    });

    const isOwner = computed(() => {
      const currentUser = store.getters.currentUser;

      const currentOrganization = store.getters.getOrganization;
      if (Array.isArray(currentOrganization)) {
        if (
          currentOrganization[0] &&
          currentOrganization[0].owner_id === currentUser.id
        ) {
          return true;
        }
      } else {
        if (
          currentOrganization &&
          currentOrganization.owner_id === currentUser.id
        ) {
          return true;
        }
      }

      return false;
    });

    const isAdmin = computed(() => {
      const currentOrganization = store.getters.getOrganization;

      if (Array.isArray(currentOrganization)) {
        if (
          currentOrganization[0] &&
          currentOrganization[0].role != null &&
          currentOrganization[0].role !== "user"
        ) {
          return true;
        }
      } else {
        if (
          currentOrganization &&
          currentOrganization.role != null &&
          currentOrganization.role !== "user"
        ) {
          return true;
        }
      }

      return false;
    });

    return {
      translate,
      isAdmin,
      isOwner,
      currentUser,
      currentOrganization,
      welcomeMessage
    };
  }
});
</script>

<style scoped lang="scss">
.clickeable {
  cursor: pointer;
}

.applied-filter {
  border: solid 1px #c9c9c9;
  border-radius: 9px;
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
  padding: 27px;
}

.invitation-list {
  border-radius: 6px;
}

.form-select {
  padding: 0.5rem 3rem 0.5rem 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #b7b7b7;
  border: none;
}

.accordion-button {
  padding-left: 0;
  font-weight: 600;
  color: #bbb;
  text-transform: capitalize;
  padding-top: 10px;
  padding-bottom: 10px;
}

.inline-txt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.inline-txt span {
  margin-left: 10px;
  padding-top: 2px;
  color: #d93b61;
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize;
}
</style>
